import './DetailInfo.css';
import pluralize from 'pluralize';
import React, { useState, useRef, useEffect } from "react";
import { useDrag, useDrop } from 'react-dnd';

const DraggableResponsibility = ({ input, index, moveItem, handleDragEnd, handleEdit, handleDelete, hoveredIndex, editingIndex, 
    editingValues, handleLocalChange, handleReviseChange, setHoveredIndex, setEditingIndex
}) => {
    const ref = useRef(null);

    const [{ isDragging }, drag] = useDrag({
        type: 'responsibility',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (monitor.didDrop()) {
                handleDragEnd();
            }
        },
        canDrag: () => editingIndex === null
    });

    const [{ isOver }, drop] = useDrop({
        accept: 'responsibility',
        hover: (item, monitor) => {
            if (!ref.current || editingIndex !== null) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            moveItem(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
        collect: (monitor) => ({
            isOver: monitor.isOver()
        })
    });

    drag(drop(ref));

    return (
        <div 
            ref={ref}
            className={`detail-info-description-text-block-container ${isDragging ? 'opacity-50' : ''}`} 
            onMouseEnter={() => setHoveredIndex(index)} 
            onMouseLeave={() => setHoveredIndex(null)}
            style={{ cursor: editingIndex === null ? 'move' : 'text' }}
        >
            {editingIndex === index ? (
                <textarea
                    className="detail-info-description-input"
                    value={editingValues[index] || (typeof input === 'string' ? input : '')}
                    onChange={(e) => handleLocalChange(e, index)}
                    onBlur={() => {
                        setEditingIndex(null);
                        handleReviseChange(index, editingValues[index]);
                    }}
                    autoFocus
                />
            ) : (
                <div className="detail-info-description-input-revise" 
                    style={{ 
                        color: hoveredIndex === index ? '#AAAAAA' : 'inherit',
                        position: 'relative',
                        transition: 'all 0.2s ease',
                        paddingLeft: hoveredIndex === index ? '28px' : '8px',
                        border: isOver ? '1px solid #381E72' : '1px solid #ddd',
                        backgroundColor: isOver ? '#F8F5FF' : 'white',
                    }}
                >
                    {hoveredIndex === index && editingIndex === null && (
                        <img 
                            src="/img/drag-icon.png" 
                            alt="drag" 
                            style={{
                                position: 'absolute',
                                left: '8px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '17px',
                                height: '24px',
                                opacity: 1,
                                transition: 'opacity 0.2s ease',
                            }}
                        />
                    )}
                    {input}
                </div>
            )}

            {(hoveredIndex === index && editingIndex !== index) && (
                <div className="detail-info-description-action-buttons-container">
                    <div className="detail_info_description_edit"
                        onMouseEnter={(e) => {
                            e.currentTarget.querySelector('.detail_info_description_edit_normal_img').style.opacity = '0';
                            e.currentTarget.querySelector('.detail_info_description_edit_hover_img').style.opacity = '1';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.querySelector('.detail_info_description_edit_normal_img').style.opacity = '1';
                            e.currentTarget.querySelector('.detail_info_description_edit_hover_img').style.opacity = '0';
                        }}
                        onClick={() => handleEdit(index)}
                    >
                        <img src="/img/detail_info_description_edit_gray.png" alt="normal" className="detail_info_description_edit_normal_img" width="20" height="20" style={{ position: 'absolute', opacity: 1, transition: 'opacity 0.2s'}} />
                        <img src="/img/detail_info_description_edit_purple.png" alt="hover" className="detail_info_description_edit_hover_img" width="20" height="20" style={{ position: 'absolute', opacity: 0, transition: 'opacity 0.2s'}} />
                    </div>
                    <div className="detail_info_description_delete"
                        onMouseEnter={(e) => {
                            e.currentTarget.querySelector('.detail_info_description_delete_normal_img').style.opacity = '0';
                            e.currentTarget.querySelector('.detail_info_description_delete_hover_img').style.opacity = '1';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.querySelector('.detail_info_description_delete_normal_img').style.opacity = '1';
                            e.currentTarget.querySelector('.detail_info_description_delete_hover_img').style.opacity = '0';
                        }}
                        onClick={() => handleDelete(index)}
                    >
                        <img src="/img/detail_info_description_delete_gray.png" alt="normal" className="detail_info_description_delete_normal_img" width="16" height="16" style={{ position: 'absolute', opacity: 1, transition: 'opacity 0.2s'}} />
                        <img src="/img/detail_info_description_delete_purple.png" alt="hover" className="detail_info_description_delete_hover_img" width="16" height="16" style={{ position: 'absolute', opacity: 0, transition: 'opacity 0.2s'}} />
                    </div>
                </div>
            )}
        </div>
    );
};

const AISuggestionsDropdown = ({ isOpen, suggestions, onClose, onAdd, buttonRef }) => {
    const [selectedSuggestions, setSelectedSuggestions] = useState({});
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Ignore clicks on the button itself
            if (buttonRef?.current && buttonRef.current.contains(event.target)) {
                return;
            }
            
            // Close only if clicking outside both the dropdown and the button
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose, buttonRef]);

    const handleCheckboxChange = (index) => {
        setSelectedSuggestions(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    const handleAdd = () => {
        const selectedItems = Object.entries(selectedSuggestions)
            .filter(([_, isSelected]) => isSelected)
            .map(([index]) => suggestions[parseInt(index)]);
        onAdd(selectedItems);
        onClose();
        setSelectedSuggestions({});
    };

    if (!isOpen) return null;

    return (
        <div ref={dropdownRef} className="ai-suggestions-dropdown">
            <div className="ai-suggestions-header">
                AI Suggestions
            </div>
            
            <div className="ai-suggestions-body">
                {suggestions.map((suggestion, index) => (
                    <div 
                        key={index} 
                        className={`ai-suggestion-item ${selectedSuggestions[index] ? 'selected' : ''}`}
                        onClick={() => handleCheckboxChange(index)}
                    >
                        <input
                            type="checkbox"
                            className="ai-suggestion-checkbox"
                            checked={selectedSuggestions[index] || false}
                            onChange={() => handleCheckboxChange(index)}
                            onClick={(e) => e.stopPropagation()}
                        />
                        <span className="ai-suggestion-text">
                            {suggestion}
                        </span>
                    </div>
                ))}
            </div>
            
            <div className="ai-suggestions-footer">
                <button className="ai-suggestions-add-button" onClick={handleAdd}>
                    Add
                </button>
            </div>
        </div>
    );
};

const DetailInfo = ({expIndex, data, handleExperienceChange, activeKeyword, containsKeyword, 
    setSelectedExperience, setIsResubotOpen, handleExperienceDelete, isFieldEmpty, isMissingInfo
}) => {
    // Add local state for responsibilities
    const [localResponsibilities, setLocalResponsibilities] = useState([]);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editingValues, setEditingValues] = useState({});
    const debounceTimeout = useRef(null);
    const currentExperiences = data["original_description"];
    const [isAISuggestionsOpen, setIsAISuggestionsOpen] = useState(false);
    const [aiSuggestions] = useState(data?.ai_bulletpoint);
    const aiButtonRef = useRef(null); 
    const [copied, setCopied] = useState(false);

    // Initialize and sync local state
    useEffect(() => {
        if (data?.Experience?.Responsibilities) {
            setLocalResponsibilities(data.Experience.Responsibilities);
        }
    }, [data?.Experience?.Responsibilities]);

    const handleInputChange = (field, value) => {
        const updatedExperienceDetails = { ...data?.Experience, [field]: value };
        handleExperienceChange(updatedExperienceDetails, expIndex, data?.type);
    };

    const handleLocalChange = (e, index) => {
        const newValue = e.target.value;
        setEditingValues(prev => ({
            ...prev,
            [index]: newValue
        }));
        
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        
        debounceTimeout.current = setTimeout(() => {
            handleReviseChange(index, newValue);
        }, 500);
    };

    const handleReviseChange = (index, value) => {
        const newResponsibilities = [...localResponsibilities];
        newResponsibilities[index] = value;
        setLocalResponsibilities(newResponsibilities);
        
        const updatedExperienceDetails = { ...data?.Experience, Responsibilities: newResponsibilities };
        handleExperienceChange(updatedExperienceDetails, expIndex, data?.type);
    };

    const handleEdit = (index) => {
        setEditingIndex(index);
        setEditingValues(prev => ({
            ...prev,
            [index]: localResponsibilities[index]
        }));
    };

    const handleDelete = (index) => {
        const newResponsibilities = localResponsibilities.filter((_, i) => i !== index);
        setLocalResponsibilities(newResponsibilities);
        
        const updatedExperienceDetails = { ...data?.Experience, Responsibilities: newResponsibilities };
        handleExperienceChange(updatedExperienceDetails, expIndex, data?.type);
    };

    const handleAddNew = () => {
        const newResponsibilities = [...localResponsibilities, ""];
        setLocalResponsibilities(newResponsibilities);
        
        const updatedExperienceDetails = {
            ...data.Experience,
            Responsibilities: newResponsibilities
        };
        handleExperienceChange(updatedExperienceDetails, expIndex, data?.type);
    };

    const moveItem = (dragIndex, hoverIndex) => {
        setLocalResponsibilities(prev => {
            const items = [...prev];
            const [draggedItem] = items.splice(dragIndex, 1);
            items.splice(hoverIndex, 0, draggedItem);
            return items;
        });
    };

    const handleDragEnd = () => {
        const updatedExperienceDetails = {
            ...data.Experience,
            Responsibilities: localResponsibilities
        };
        handleExperienceChange(updatedExperienceDetails, expIndex, data?.type);
    };

    const handleAddAISuggestions = (selectedSuggestions) => {
        const newResponsibilities = [...localResponsibilities, ...selectedSuggestions];
        setLocalResponsibilities(newResponsibilities);
        
        const updatedExperienceDetails = {
            ...data.Experience,
            Responsibilities: newResponsibilities
        };
        handleExperienceChange(updatedExperienceDetails, expIndex, data?.type);
    };
        
    const highlightText = (text) => {
        if (!activeKeyword || !text) return text;
    
        // 标准化处理函数
        const normalize = (text) => {
            if (!text) return '';
            return text.toLowerCase()
                      .replace(/[-](?=\w)/g, ' ')       // 将连字符替换为空格（只处理后面跟着字母的情况）
                      .replace(/\.(?=[A-Za-z])/g, ' ')  // 将点号替换为空格（只在后面跟着字母时）
                      .replace(/[,!?\.]$/g, '')         // 移除句尾的标点符号（包括句号）
                      .replace(/[,!?]/g, '')            // 移除其他标点符号
                      .trim();
        };
        
        // 准备关键词
        const normalizedKeyword = normalize(activeKeyword);
        
        const checkText = (text) => {
            const normalizedText = normalize(text);
            
            // 如果是多词组关键词
            if (normalizedKeyword.includes(' ')) {
                // Escape all regex special characters in the entire keyword first
                const escapedKeyword = normalizedKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                
                // Then split by spaces and join with the delimiter pattern
                const regexPattern = escapedKeyword
                    .split(' ')
                    .join('(?:[-. ]|(?= ))');
            
                const keywordRegex = new RegExp(`\\b${regexPattern}\\b`, 'i');
                return keywordRegex.test(normalizedText);
            }
            
            // 单个词的情况
            const textWords = normalizedText.split(/\s+/);
            return textWords.some(word => 
                normalize(word) === normalizedKeyword || 
                normalize(pluralize.singular(word)) === normalize(pluralize.singular(normalizedKeyword))
            );
        };
    
        // 开始处理高亮逻辑
        const parts = [];
        // 如果是多词组关键词
        if (normalizedKeyword.includes(' ')) {
            const escapedKeyword = normalizedKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const regexPattern = escapedKeyword
                .split(' ')
                .join('(?:[-. ]|(?= ))');
            
            const keywordRegex = new RegExp(`(\\b${regexPattern}\\b)`, 'gi');
            const splitParts = text.split(keywordRegex);
            
            return (
                <span>
                    {splitParts.map((part, i) => {
                        if (checkText(part)) {
                            return <span key={i} className="highlight-keyword">{part}</span>;
                        }
                        return part;
                    })}
                </span>
            );
        } else {
            // 单个词的情况
            // const words = text.split(/(\s+)/); // 保留分隔符
            const words = text.split(/(\s+)|([,!?])/);
            return (
                <span>
                    {words.filter(Boolean).map((word, i) => {
                        // 检查单词或其单数形式是否匹配
                        if (
                            normalize(word) === normalizedKeyword || 
                            normalize(pluralize.singular(word)) === normalize(pluralize.singular(normalizedKeyword))
                        ) {
                            return <span key={i} className="highlight-keyword">{word}</span>;
                        }
                        return word;
                    })}
                </span>
            );
        }
    };

    const handleResubotClick = () => {
        setSelectedExperience(expIndex);
        setIsResubotOpen(true);
    };

    const handleCopyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            // Optional: Add some user feedback like a toast notification
        } catch (err) {
            console.error('Failed to copy text:', err);
        }
    };

    useEffect(() => {
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, []);

    return (
        <div className={`detail-info ${containsKeyword ? 'contains-keyword' : ''} ${isMissingInfo ? 'missing-info' : ''}`}>
            <div className="detail-info-header">
                <div className="detail-info-title">
                    <span className='editor-subsection-title'>{data["type"] === "work" ? `Experience ${expIndex + 1}` : `Project ${expIndex + 1}`}</span>
                    {containsKeyword && (
                        <span className="keyword-indicator">
                            Matching keyword
                        </span>
                    )}
                    <div className="detail-info-match-score">
                        <span>Match Score</span>
                        <span className="detail-info-score">{data["relevancy_score"]}</span>
                    </div>
                </div>
                <button className="delete-button" onClick={() => handleExperienceDelete(expIndex)}>Delete</button>
            </div>

            <div className="detail-info-form">
                <div className="detail-info-form-row">
                    <div className="detail-info-form-group">
                        <label>Role<span className="starColor">*</span></label>
                        <input 
                            type="text" 
                            value={data?.Experience?.Title || ''} 
                            onChange={(e) => handleInputChange('Title', e.target.value)}
                            placeholder="Enter role" 
                        />
                        {(!data?.Experience?.Title ||isFieldEmpty(data?.Experience?.Title)) && (
                            <div className="hint-text">Hint: this content is required.</div>
                        )}
                    </div>
                    <div className="detail-info-form-group">
                        <label>Company/Institution<span className="starColor">*</span></label>
                        <input 
                            type="text" 
                            value={data?.Experience?.Company || ''} 
                            onChange={(e) => handleInputChange('Company', e.target.value)}
                            placeholder="Enter Company" 
                        />
                        {(!data?.Experience?.Company ||isFieldEmpty(data?.Experience?.Company)) && (
                            <div className="hint-text">Hint: this content is required.</div>
                        )}
                    </div>
                </div>
                <div className="detail-info-form-row">
                    <div className="detail-info-form-group">
                        <label>Duration<span className="starColor">*</span></label>
                        <input 
                            type="text" 
                            value={data?.Experience?.Duration || ''} 
                            onChange={(e) => handleInputChange('Duration', e.target.value)}
                            placeholder="Enter duration" 
                        />
                        {(!data?.Experience?.Duration ||isFieldEmpty(data?.Experience?.Duration)) && (
                            <div className="hint-text">Hint: this content is required.</div>
                        )}
                    </div>
                    <div className="detail-info-form-group">
                        <label>Location</label>
                        <input 
                            type="text" 
                            value={data?.Experience?.Location || ''} 
                            onChange={(e) => handleInputChange('Location', e.target.value)}
                            placeholder="Enter location" 
                        />
                    </div>
                </div>

                <div className="detail-info-description-section">
                    <div className="detail-info-description-column">
                        <h3>Current</h3>
                        <div className="detail-info-description-box">
                            {currentExperiences.map((input, index) => (
                                <div key={index} className="detail-info-description-input" style={{ backgroundColor: '#F9FBFC', color: '#AAAAAA' }}>
                                    {/* {input} */}
                                    {highlightText(input)}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="detail-info-description-column">
                        <div className="detail-info-revised-header">
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <h3>Revised</h3>
                                <div 
                                    className="tooltip-container" 
                                    onClick={async () => {
                                        await handleCopyToClipboard((data.Experience.Responsibilities || []).join('\n'));
                                        // Update the copied state for this experience
                                        setCopied(true);
                                        setTimeout(() => {
                                            setCopied(false);
                                        }, 1500);
                                    }}
                                >
                                    <img
                                        className="detailinfo-img"
                                        alt="Copy"
                                        src="/img/copy.png"
                                    />
                                    <span className={`tooltip ${copied ? 'show-copied' : ''}`}>
                                        {copied ? 'Copied!' : 'Copy bullet points'}
                                    </span>
                                </div>
                            </div>
                            <button className="detail-info-ai-tune-btn" onClick={handleResubotClick}>
                                Fine-tune with AI
                                <img 
                                    src="/img/detail-info-resubot.png" 
                                    alt="resubot icon" 
                                    width="16px" 
                                    height="16px"
                                />
                            </button>
                        </div>
                        <div className="detail-info-description-box">
                            {localResponsibilities.map((input, index) => (
                                <DraggableResponsibility
                                    key={index}
                                    // input={input}
                                    input={editingIndex === index ? input : highlightText(input)}
                                    index={index}
                                    moveItem={moveItem}
                                    handleDragEnd={handleDragEnd}
                                    handleEdit={handleEdit}
                                    handleDelete={handleDelete}
                                    hoveredIndex={hoveredIndex}
                                    editingIndex={editingIndex}
                                    editingValues={editingValues}
                                    handleLocalChange={handleLocalChange}
                                    handleReviseChange={handleReviseChange}
                                    setHoveredIndex={setHoveredIndex}
                                    setEditingIndex={setEditingIndex}
                                />
                            ))}
                            <div className="detail-info-description-revise-modify">
                                <div className="detail-info-button-wrapper">
                                    <button className="detail-info-add-new-btn" onClick={handleAddNew}>
                                        <img src="/img/add.png" alt="add" style={{ width: '14px' }} />
                                        <span>Add new</span>
                                    </button>
                                </div>
                                <div className="detail-info-button-wrapper">
                                    <button 
                                        ref={aiButtonRef}  // Add this ref
                                        className="detail-info-add-new-btn"
                                        onClick={() => setIsAISuggestionsOpen(!isAISuggestionsOpen)}  // Revert back to simple toggle
                                    >
                                        <img src="/img/ai-generation-button-purple.png" alt="AI" style={{ width: '18px' }} />
                                        <span>AI Suggested Bulletpoints</span>
                                    </button>
                                    <AISuggestionsDropdown
                                        isOpen={isAISuggestionsOpen}
                                        suggestions={aiSuggestions}
                                        onClose={() => setIsAISuggestionsOpen(false)}
                                        onAdd={handleAddAISuggestions}
                                        buttonRef={aiButtonRef}  // Pass the ref
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailInfo;