import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NewRevision from "./screens/NewRevision/NewRevision";
import { HomeNew } from "./screens/HomeNew";
import { UploadNew } from "./screens/UploadNewResume/UploadNew";
import { UploadNewText } from "./screens/UploadNewResume/UploadNewText";
import { Loading } from "./screens/Loading";
import { Contact } from "./screens/Contact";
import AboutUs from "./screens/AboutUs/AboutUs";
import { ClResults } from "./screens/ClResults";
import Dashboard from "./screens/Dashboard/Dashboard";
import { Plan } from "./screens/Plan";
import { PaymentPage } from "./screens/Payment";
import { PaymentSuccess } from "./screens/Success";
import { UnverifiedEmail } from "./screens/Email_verification";
import { UnsubscribeEmail } from "./screens/Email_verification";
import { New_suggested_edits } from "./screens/New_suggested_edits";
import { Term_of_service } from "./screens/Term_of_service";
import { Privacy_policy } from "./screens/Privacy_policy";
import { Loading_upload } from "./screens/Loading";
import DashboardEditor from "./screens/DashboardEditor/DashboardEditor";
import { DashboardRevision } from "./screens/DashboardRevision/DashboardRevision";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "bootstrap/dist/css/bootstrap.min.css";

const router = createBrowserRouter([
  {
    path: "/ResumeResults",
    element: <NewRevision />,
  },
  {
    path: "/*",
    element: <HomeNew />,
  },
  {
    path: "/uploadResume",
    element: <UploadNew />,
  },
  {
    path: "/uploadResumeText",
    element: <UploadNewText />,
  },
  {
    path: "/Loading",
    element: <Loading />,
  },
  {
    path: "/Loading_upload",
    element: <Loading_upload />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/aboutus",
    element: <AboutUs />,
  },
  {
    path: "/ClResults",
    element: <ClResults />,
  },
  {
    path: "/new_resume_revision",
    element: <New_suggested_edits />,
  },
  {
    path: "/Dashboard",
    element: <Dashboard />,
  },
  {
    path: "/DashboardRevision",
    element: <DashboardRevision />,
  },
  /*============================== temporary comment out for deployment 06/09===================================*/
  {
    path: "/plan",
    element: <Plan />,
  },
  {
    path: "/payment/:planId",
    element: <PaymentPage />,
  },
  {
    path: "/success",
    element: <PaymentSuccess />,
  },
  /*============================== temporary comment out for deployment 06/09===================================*/
  {
    path: "/unverified",
    element: <UnverifiedEmail />,
  },
  {
    path: "/term_of_service",
    element: <Term_of_service />,
  },
  {
    path: "/privacy_policy",
    element: <Privacy_policy />,
  },
  {
    path: "/new_suggested_edits",
    element: <New_suggested_edits />,
  },
  {
    path: "/dashboardEditor",
    element: <DashboardEditor />,
  },
  {
    path: "/unsubsribeEmail",
    element: <UnsubscribeEmail />,
  },
  {
    path: "/newRevision",
    element: <NewRevision />,
  },
]);

export const App = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <RouterProvider router={router} />
    </DndProvider>
  );
};
