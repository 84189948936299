import React, { useEffect } from 'react';


const Education = ({ education, onEducationChange, handleEducationDelete, handleAddEducation, isFieldEmpty, checkEducationFields, 
setIsEducationFieldsFilled }) => {
    const handleInputChange = (index, field, value) => {
        const updatedEducation = [...education];
        updatedEducation[index] = { ...updatedEducation[index], [field]: value };
        onEducationChange(updatedEducation);
    };

    useEffect(() => {
        setIsEducationFieldsFilled(checkEducationFields(education));
    }, [education, checkEducationFields, setIsEducationFieldsFilled]);

    return (
        <>
        <div className="education-section-container">
            {(education || []).map((edu, index) => (
                <>
                <div className="edu-content" key={index}>
                    <div className="dashboard-education-header">
                        <div className="editor-subsection-title">
                            Education {index + 1}
                        </div>
                        <button onClick={() => handleEducationDelete(index)} className="delete-button">
                            Delete
                        </button>
                    </div>
                    <div className="education-item">
                        <div className="detail-item">
                                <label className="form-label">University/College Name<span className="starColor">*</span></label>
                            <input 
                                type="text" 
                                className="form-input" 
                                value={edu.university || ''} 
                                onChange={(e) => handleInputChange(index, 'university', e.target.value)} 
                            />
                                {(!edu.university || isFieldEmpty(edu.university)) && (
                                <div className="hint-text">Hint: University/College name is required.</div>
                            )}
                            
                        </div>
                        <div className="detail-item">
                                <label className="form-label">Degree<span className="starColor">*</span></label>
                            <input 
                                type="text" 
                                className="form-input" 
                                value={edu.degrees || ''} 
                                onChange={(e) => handleInputChange(index, 'degrees', e.target.value)} 
                            />
                                {(!edu.degrees ||isFieldEmpty(edu.degrees)) && (
                                <div className="hint-text">Hint: degree is required.</div>
                            )}
                        </div>
                        <div className="detail-item">
                                <label className="form-label">Major</label>
                            <input 
                                type="text" 
                                className="form-input" 
                                value={edu.majors || ''} 
                                onChange={(e) => handleInputChange(index, 'majors', e.target.value)} 
                            />
                        </div>
                        <div className="detail-item">
                            <label className="form-label">
                                Program Start Month
                            </label>
                            <select
                                className="form-select"
                                value={String(edu.university_start_month || '')}  // This will convert null, undefined, or an int to a string
                                onChange={(e) => handleInputChange(index, 'university_start_month', parseInt(e.target.value))}
                            >
                                <option value="">Month Not Available</option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                        </div>
                        <div className="detail-item">
                                <label className="form-label">Program Start Year<span className="starColor">*</span></label>
                            <input 
                                type="text" 
                                className="form-input" 
                                value={edu.university_start_year || ''} 
                                onChange={(e) => handleInputChange(index, 'university_start_year', e.target.value)} 
                            />
                                {(!edu.university_start_year ||isFieldEmpty(edu.university_start_year)) && (
                                <div className="hint-text">Hint: program start year is required.</div>
                            )}
                        </div>
                        <div className="detail-item">
                            <label className="form-label">
                                Program End Month
                            </label>
                            <select
                                className="form-select"
                                value={String(edu.graduation_month || '')}  // This will convert null, undefined, or an int to a string
                                onChange={(e) => handleInputChange(index, 'graduation_month', parseInt(e.target.value))}
                            >
                                <option value="">Month Not Available</option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                        </div>
                        <div className="detail-item">
                                <label className="form-label">Program End Year<span className="starColor">*</span></label>
                            <input 
                                type="text" 
                                className="form-input" 
                                value={edu.graduation_year || ''} 
                                onChange={(e) => handleInputChange(index, 'graduation_year', e.target.value)} 
                            />
                                {(!edu.graduation_year ||isFieldEmpty(edu.graduation_year)) && (
                                <div className="hint-text">Hint: program end year is required.</div>
                            )}
                        </div>
                        <div className="detail-item">
                            <label className="form-label">GPA</label>
                            <input 
                                type="text" 
                                className="form-input" 
                                value={edu.GPA || ''} 
                                onChange={(e) => handleInputChange(index, 'GPA', e.target.value)} 
                            />
                        </div>
                    </div>
                </div>
                </>
            ))}
        </div>
        <div className="button-container">
            <button className="add-new-button" onClick={handleAddEducation}>
                Add Education
            </button>
        </div>
        </>
    );
};

export default Education;
