import React, { useState } from 'react';
import CompanyInputBar from '../../components/CompanyInputBar/CompanyInputBar';
import { useAuth0 } from "@auth0/auth0-react";
import { Loading_partial } from '../Loading/Loading_partial';

const FollowUpGenerator = ({ data, job_description, company_analysis, original_data, isFieldEmpty, jobPosition, 
    companyName, companyDomain, handleCompanyChange, handleDomainChange, handleJobPositionChange, jobDescriptionText, 
    followUpEmail, setFollowUpEmail, emailSubject, setEmailSubject, application_id, followUpEmailLoading, setFollowUpEmailLoading, 
    showFollowUpEmail, setShowFollowUpEmail, handleDeleteItem}) => {

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const [selectedStyleOption, setSelectedStyleOption] = useState('Email');
    const [cultureValue, setCultureValue] = useState('');
    const [personalStory, setPersonalStory] = useState('');
    const [recipientNameTitle, setRecipientNameTitle] = useState('Hiring Manager');
    


    const areButtonDisable = () => {
        return !companyName || !jobPosition;
    };

    const determineEducationStatus = (currentResumeValue) => {
        // Month mapping
        const monthToNum = {
            "january": 1, "february": 2, "march": 3, "april": 4, "may": 5, "june": 6,
            "july": 7, "august": 8, "september": 9, "october": 10, "november": 11, "december": 12
        };
    
        // Get the current year and month number
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonthNum = currentDate.getMonth() + 1; // getMonth returns 0-based index, so +1
    
        // Initialize education status to N/A
        let educationStatus = '';
    
        // Check if there is education data
        let currentEducation = currentResumeValue?.education?.[0] || null;
    
        if (currentEducation) {
            // Extract graduation year and month
            let gradYear = currentEducation?.graduation_year ?? -1;
            let gradMonth = currentEducation?.graduation_month ?? -1;
    
            // Convert gradYear to a number if it's a string
            if (typeof gradYear === 'string') {
                gradYear = parseInt(gradYear, 10);
            }
    
            // Convert gradMonth to a number if it's a string (also handle month names)
            if (typeof gradMonth === 'string') {
                if (isNaN(gradMonth)) {
                    // If it's not a number, try to map it as a month name
                    gradMonth = monthToNum[gradMonth.toLowerCase()] || -1;
                } else {
                    gradMonth = parseInt(gradMonth, 10);
                }
            }
    
            // Determine education status
            if (gradYear === -1 || gradMonth === -1) {
                educationStatus = '';
            } else if (currentYear < gradYear || (currentYear === gradYear && currentMonthNum <= gradMonth)) {
                educationStatus = 'in_progress';
            } else {
                educationStatus = 'graduated';
            }
        }
    
        return educationStatus;
    };

    const extractData = (data) => {
        const workExperience = (data.rendering_data.work || []).map((work) => {
          const { Experience } = work;
          return {
            company: Experience?.Company || 'N/A',
            title: Experience?.Title || 'N/A',
            duration: Experience?.Duration || 'N/A',
            location: Experience?.Location || 'N/A',
            responsibilities: Experience?.Responsibilities || [],
          };
        });
      
        const projectExperience = (data.rendering_data.project || []).map((project) => {
          const { Experience } = project;
          return {
            company: Experience?.Company || 'N/A',
            title: Experience?.Title || 'N/A',
            duration: Experience?.Duration || 'N/A',
            location: Experience?.Location || 'N/A',
            responsibilities: Experience?.Responsibilities || [],
          };
        });
      
        return {
          work_experience: workExperience.length > 0 ? workExperience : null,
          project_experience: projectExperience.length > 0 ? projectExperience : null,
        };
    };

    const sendToServer = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        //---------------------------------auhentication code----------------------------------------
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return
        }
        //---------------------------------auhentication code----------------------------------------
        // create a FormData object
        const formData = new FormData();
        // append the required data into the form
        formData.append('application_id', application_id)
        formData.append('first_name', original_data?.personal_info?.first_name || '');
        formData.append('last_name', original_data?.personal_info?.last_name || '');

        formData.append('education', original_data?.education?.[0]?.university || '');
        formData.append('degree', original_data?.education?.[0]?.degrees || '');
        formData.append('major', original_data?.education?.[0]?.majors || '');
        formData.append('education_status', determineEducationStatus(original_data));

        formData.append('company_name', companyName);
        formData.append('position_title', jobPosition);
        formData.append('style', selectedStyleOption);

        formData.append('job_description', job_description);
        const resumeObj = extractData(data);
        resumeObj.skills = original_data.skills;
        formData.append('resume_obj', JSON.stringify(resumeObj));
        formData.append('recipient_name_title', recipientNameTitle);
    
        //start loading
        setFollowUpEmailLoading(true);
        // send a POST request
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        console.log('Send POST request to API')
        fetch('/resumasterapi/cold-email-generator', { //api endpoint
          method: 'POST',
          //---------------------------------auhentication code----------------------------------------
          headers: {
            Authorization: `Bearer ${token}`
          },
          //---------------------------------auhentication code----------------------------------------
          body: formData
        }).then(response => {
          // Check for the 400 status code 
          // console.log('Server Response:', response);
          if (response.status === 400) {
            throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
          }
          if (response.status == 500) {
            throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
          }
          return response.json();
        }).then(data => {
          console.log(data);
          setFollowUpEmail(data.result.content);
          if (selectedStyleOption === 'Email') {
            setEmailSubject(data.result.subject)
          }
          setFollowUpEmailLoading(false);
          setShowFollowUpEmail(true);
        }).catch(error => {
          // handle the error
          console.error(error);
          // If there's an error, ensure loading is turned off
          setFollowUpEmailLoading(false);
          alert(error.message);  // Displaying the error message to the user
        });
    }

    const handleCopyToClipboard = async () => {
        try {
          await navigator.clipboard.writeText(followUpEmail);
        } catch (err) {
          console.error("Error copying text: ", err);
        }
    };

    const handleCopySubjectToClipboard = async () => {
        try {
          await navigator.clipboard.writeText(emailSubject);
        } catch (err) {
          console.error("Error copying text: ", err);
        }
    };

    return (
        <div className="CL-section-main-section">

            {followUpEmailLoading ? (
                <div style={{ textAlign: 'center', backgroundColor: '#FFFFFF'}}>
                    <Loading_partial />
                    <p style={{ marginTop: '10px', fontSize: '16px', fontWeight: 600, marginBottom: '20px'}}>This process will take about 20 seconds while we generate your cover letter...</p>
                </div>
            ) : showFollowUpEmail ? (
                <div className="description" style={{border: 'none'}}>
                    <div className="img-box">
                        <button onClick={() => handleDeleteItem('Follow-Up Builder')} className="delete-button">
                           Delete
                        </button>
                    </div>
                    {selectedStyleOption === "Email" && (
                        <div style={{marginTop: '10px', marginBottom: '30px'}}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                <label className="form-label">Email Subject</label>
                                <img 
                                    className="copy-img"
                                    alt="Copy"
                                    src="/img/coverletter_copy.png"
                                    style={{
                                        width: '24px',
                                        height: '24px',
                                        cursor: 'pointer',
                                        marginRight: '10px'
                                    }}
                                    onClick={() => handleCopySubjectToClipboard()}
                                />
                            </div>
                            <input 
                                type="text" 
                                className={`experience-form-input`}
                                value={emailSubject || ''}
                                onChange={(e) => setEmailSubject(e.target.value)} 
                            />
                        </div>
                    )}
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                        <label className="form-label">Content</label>
                        <img 
                            className="copy-img"
                            alt="Copy"
                            src="/img/coverletter_copy.png"
                            style={{
                                width: '24px',
                                height: '24px',
                                cursor: 'pointer',
                                marginRight: '10px'
                            }}
                            onClick={() => handleCopyToClipboard()}
                        />
                    </div>
                    
                    <div className="detail-info-description-text-block-container">
                        {
                            <div className="summary-content ">
                                <textarea
                                    className="coverletter-textarea"
                                    value={followUpEmail}
                                    onChange={(e) => setFollowUpEmail(e.target.value)}
                                    autoFocus
                                />
                            </div>
                        }
                    </div>
                </div>
            ) : (
                <div className="description">
                    <label className="form-label">Target Company<span className="starColor">*</span></label>
                    <CompanyInputBar
                                    initialCompanyName={companyName} // Pass initial company name
                                    initialCompanyDomain={companyDomain} // Pass initial company domain (logo URL)
                                    onCompanyChange={handleCompanyChange} // Update company name state
                                    onDomainChange={handleDomainChange} // Update company domain (logo) state
                    />
                    {(!companyName ||isFieldEmpty(companyName)) && (
                        <div className="hint-text">Hint: this content is required.</div>
                    )}

                    <label className="form-label" style={{marginTop: '20px'}}>Target Position<span className="starColor">*</span></label>
                    <input 
                        type="text" 
                        className={`experience-form-input ${jobPosition.trim() === '' ? 'input-error' : ''}`}
                        value={jobPosition || ''} 
                        onChange={(e) => handleJobPositionChange(e.target.value)} 
                    />
                    {(!jobPosition ||isFieldEmpty(jobPosition)) && (
                        <div className="hint-text">Hint: this content is required.</div>
                    )}

                    <label className="form-label" style={{marginTop: '20px'}}>Recipient Name or Title</label>
                    <input 
                        type="text" 
                        className={`experience-form-input`}
                        value={recipientNameTitle || ''}
                        onChange={(e) => setRecipientNameTitle(e.target.value)} 
                    />

                    <label className="form-label" style={{marginTop: '20px'}}>Follow-Up Writing Style</label>
                    <div className="dashboard-revision-div" style={{paddingLeft: '0%'}}>
                      <div className='option-box'>
                          <div className={`option-button ${selectedStyleOption === "Email" ? 'selected' : ''}`} onClick={() => setSelectedStyleOption("Email")}>Email</div>
                          <div className={`option-button ${selectedStyleOption === "Message" ? 'selected' : ''}`} onClick={() => setSelectedStyleOption("Message")}>Message</div>
                      </div>
                    </div>

                    <button className='coverletter-generate-button' disabled={areButtonDisable()} onClick={sendToServer} style={{marginTop: '20px'}}>
                        <img src="/img/ai-generation-button.png" alt="AI Icon" style={{width: '24px', height: '24px', marginRight: '8px', verticalAlign: 'middle'}}/>
                        Generate Follow-up
                    </button>
                    <div className="dashboard-logo-api" style={{ marginTop: '20px' }}>
                      <a href="https://logo.dev" alt="Logo API">Logos provided by Logo.dev</a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FollowUpGenerator;