import './Navibar.css';
import pluralize from 'pluralize';
import React, { useState, useRef, useEffect, useMemo, useCallback  } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Navibar = ({ baseMenuItems, revisionItem, handleAddItem, handleDeleteItem, experienceData, setExperienceData, 
skillData, setSkillData, jdKeywords, onKeywordClick, activeKeyword, openCard, handleCardClick, writtingMode, handleCardOpen, 
navibarMode, handleNavibarModeChange, isEducationFieldsFilled, isExperienceFieldsFilled, isProjectFieldFilled,
isPersonalInfoFilled, selectedTemplate, setSelectedTemplate, setIsModal2Open, areRequiredFieldsFilled, isNavibarExpanded,
handleClickStartNew}) => {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    const workResponsibilities = useMemo(() => 
        experienceData?.rendering_data?.work.flatMap(work => 
            work.Experience.Responsibilities
        ) || []
    , [experienceData]);
    
    const projectResponsibilities = useMemo(() => 
        experienceData?.rendering_data?.project.flatMap(project => 
            project.Experience.Responsibilities
        ) || []
    , [experienceData]);
    
    const skills = useMemo(() => 
        skillData?.skills || []
    , [skillData]);

    
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedKeyword, setSelectedKeyword] = useState(null);
    const dropdownRef = useRef(null);
    const [showOptions, setShowOptions] = useState(false);
    const [selectedSection, setSelectedSection] = useState('');
    const [showSecondDropdown, setShowSecondDropdown] = useState(false);
    const [selectedExperience, setSelectedExperience] = useState('');
    const [selectedExperienceDeatil, setSelectedExperienceDeatil] = useState(null);
    const [generateNewContent, setGenerateNewContent] = useState('');
    const [rewriteContent, setRewriteContent] = useState('');
    const [selectedResponsibility, setSelectedResponsibility] = useState('');
    const [selectedResponsibilityCopy, setSelectedResponsibilityCopy] = useState('');

    const getResponsibilitiesList = () => {
        const sectionKey = selectedSection.toLowerCase() === 'experience' ? 'work' : selectedSection.toLowerCase();
        const items = experienceData?.rendering_data?.[sectionKey] || [];
        const selectedItem = items.find(item => 
            `${item.Experience.Title} at ${item.Experience.Company}` === selectedExperience
        );
        return selectedItem?.Experience?.Responsibilities || [];
    };

    const addToResponsibilitiesList = (newContent) => {
        const sectionKey = selectedSection.toLowerCase() === 'experience' ? 'work' : selectedSection.toLowerCase();
        
        // 创建 experienceData 的深拷贝
        const newExperienceData = JSON.parse(JSON.stringify(experienceData));
        
        // 找到要更新的项目的索引
        const itemIndex = newExperienceData.rendering_data[sectionKey].findIndex(item => 
            `${item.Experience.Title} at ${item.Experience.Company}` === selectedExperience
        );
    
        if (itemIndex !== -1) {
            // 将新内容添加到 Responsibilities 数组末尾
            newExperienceData.rendering_data[sectionKey][itemIndex].Experience.Responsibilities.push(newContent);
            
            // 更新状态
            setExperienceData(newExperienceData);
        }
    };

    const addToSkillsList = () => {
        const newSkillData = JSON.parse(JSON.stringify(skillData));
        
        newSkillData.skills.push(selectedKeyword);
        
        setSkillData(newSkillData);
    };

    const updateResponsibilitiesList = (newContent) => {
        const sectionKey = selectedSection.toLowerCase() === 'experience' ? 'work' : selectedSection.toLowerCase();
        
        // 创建 experienceData 的深拷贝
        const newExperienceData = JSON.parse(JSON.stringify(experienceData));
        
        // 找到要更新的项目的索引
        const itemIndex = newExperienceData.rendering_data[sectionKey].findIndex(item => 
            `${item.Experience.Title} at ${item.Experience.Company}` === selectedExperience
        );
    
        if (itemIndex !== -1) {
            // 找到要替换的具体responsibility的索引
            const responsibilityIndex = newExperienceData.rendering_data[sectionKey][itemIndex]
                .Experience.Responsibilities.findIndex(resp => resp === selectedResponsibilityCopy);
                
            if (responsibilityIndex !== -1) {
                // 替换对应的内容
                newExperienceData.rendering_data[sectionKey][itemIndex]
                    .Experience.Responsibilities[responsibilityIndex] = newContent;
                
                // 更新状态
                setExperienceData(newExperienceData);
            }
        }
    };

    const getExperienceOptions = () => {
        if (!selectedSection) return [];
        
        // 如果选择的是work，要把experience转换成work
        const sectionKey = selectedSection.toLowerCase() === 'experience' ? 'work' : selectedSection.toLowerCase();
        return experienceData?.rendering_data?.[sectionKey] || [];
    };

    const checkKeywordPresence = useCallback((keyword) => {
        // 标准化处理函数
        const normalize = (text) => {
            if (!text) return '';
            return text.toLowerCase()
                      .replace(/[-](?=\w)/g, ' ')       // 将连字符替换为空格（只处理后面跟着字母的情况）
                      .replace(/\.(?=[A-Za-z])/g, ' ')  // 将点号替换为空格（只在后面跟着字母时）
                      .replace(/[,!?\.]$/g, '')         // 移除句尾的标点符号（包括句号）
                      .replace(/[,!?]/g, '')            // 移除其他标点符号
                      .trim();
        };
        
        // 准备关键词
        const normalizedKeyword = normalize(keyword);
        
        const checkText = (text) => {
            const normalizedText = normalize(text);
            
            // 如果是多词组关键词
            if (normalizedKeyword.includes(' ')) {
                // Escape all regex special characters in the entire keyword first
                const escapedKeyword = normalizedKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                
                // Then split by spaces and join with the delimiter pattern
                const regexPattern = escapedKeyword
                    .split(' ')
                    .join('(?:[-. ]|(?= ))');
            
                const keywordRegex = new RegExp(`\\b${regexPattern}\\b`, 'i');
                return keywordRegex.test(normalizedText);
            }
            
            // 单个词的情况
            const textWords = normalizedText.split(/\s+/);
            return textWords.some(word => 
                normalize(word) === normalizedKeyword || 
                normalize(pluralize.singular(word)) === normalize(pluralize.singular(normalizedKeyword))
            );
        };
        
        // 检查所有可能的位置
        return workResponsibilities.some(checkText) || 
               projectResponsibilities.some(checkText) || 
               skills.some(checkText);
    }, [workResponsibilities, projectResponsibilities, skills]);

    useEffect(() => {
        const checkActiveKeywordPresence = () => {
            // 如果没有activeKeyword，不需要检查
            if (!activeKeyword) return;
    
            // 修改normalize函数
            const normalize = (text) => {
                if (!text) return '';
                return text.toLowerCase()
                          .replace(/[-](?=\w)/g, ' ')       // 将连字符替换为空格（只处理后面跟着字母的情况）
                          .replace(/\.(?=[A-Za-z])/g, ' ')  // 将点号替换为空格（只在后面跟着字母时）
                          .replace(/[,!?\.]$/g, '')         // 移除句尾的标点符号（包括句号）
                          .replace(/[,!?]/g, '')            // 移除其他标点符号
                          .trim();
            };
            
            const normalizedKeyword = normalize(activeKeyword);
            
            const checkText = (text) => {
                const normalizedText = normalize(text);
                
                if (normalizedKeyword.includes(' ')) {
                    // Escape all regex special characters in the entire keyword first
                    const escapedKeyword = normalizedKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                    
                    // Then split by spaces and join with the delimiter pattern
                    const regexPattern = escapedKeyword
                        .split(' ')
                        .join('(?:[-. ]|(?= ))');
                
                    const keywordRegex = new RegExp(`\\b${regexPattern}\\b`, 'i');
                    return keywordRegex.test(normalizedText);
                }
                
                const textWords = normalizedText.split(/\s+/);
                return textWords.some(word => 
                    normalize(word) === normalizedKeyword || 
                    normalize(pluralize.singular(word)) === normalize(pluralize.singular(normalizedKeyword))
                );
            };
    
            // 检查是否在任何地方都找不到关键词
            const keywordFound = workResponsibilities.some(checkText) || 
                               projectResponsibilities.some(checkText) || 
                               skills.some(checkText);
    
            // 如果找不到关键词，将activeKeyword设置为null
            if (!keywordFound) {
                onKeywordClick(null);
            }
        };
    
        checkActiveKeywordPresence();
    }, [activeKeyword, workResponsibilities, projectResponsibilities, skills, onKeywordClick]);

    const sortedKeywords = useMemo(() => {
        const notFound = [];
        const found = [];
        
        jdKeywords.forEach(keyword => {
            if (checkKeywordPresence(keyword)) {
                found.push(keyword);
            } else {
                notFound.push(keyword);
            }
        });
        
        return [...notFound, ...found];
    }, [jdKeywords, checkKeywordPresence]);

    const highlightText = (text) => {
        if (!selectedKeyword || !text) return text;
    
        // 标准化处理函数
        const normalize = (text) => {
            if (!text) return '';
            return text.toLowerCase()
                      .replace(/[-](?=\w)/g, ' ')       // 将连字符替换为空格（只处理后面跟着字母的情况）
                      .replace(/\.(?=[A-Za-z])/g, ' ')  // 将点号替换为空格（只在后面跟着字母时）
                      .replace(/[,!?\.]$/g, '')         // 移除句尾的标点符号（包括句号）
                      .replace(/[,!?]/g, '')            // 移除其他标点符号
                      .trim();
        };
        
        // 准备关键词
        const normalizedKeyword = normalize(selectedKeyword);
        
        const checkText = (text) => {
            const normalizedText = normalize(text);
            
            // 如果是多词组关键词
            if (normalizedKeyword.includes(' ')) {
                // Escape all regex special characters in the entire keyword first
                const escapedKeyword = normalizedKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                
                // Then split by spaces and join with the delimiter pattern
                const regexPattern = escapedKeyword
                    .split(' ')
                    .join('(?:[-. ]|(?= ))');
            
                const keywordRegex = new RegExp(`\\b${regexPattern}\\b`, 'i');
                return keywordRegex.test(normalizedText);
            }
            
            // 单个词的情况
            const textWords = normalizedText.split(/\s+/);
            return textWords.some(word => 
                normalize(word) === normalizedKeyword || 
                normalize(pluralize.singular(word)) === normalize(pluralize.singular(normalizedKeyword))
            );
        };
    
        // 开始处理高亮逻辑
        const parts = [];
        // 如果是多词组关键词
        if (normalizedKeyword.includes(' ')) {
            const escapedKeyword = normalizedKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const regexPattern = escapedKeyword
                .split(' ')
                .join('(?:[-. ]|(?= ))');
            
            const keywordRegex = new RegExp(`(\\b${regexPattern}\\b)`, 'gi');
            const splitParts = text.split(keywordRegex);
            
            return (
                <span>
                    {splitParts.map((part, i) => {
                        if (checkText(part)) {
                            return <span key={i} className="highlight-keyword">{part}</span>;
                        }
                        return part;
                    })}
                </span>
            );
        } else {
            // 单个词的情况
            // const words = text.split(/(\s+)/); // 保留分隔符
            const words = text.split(/(\s+)|([,!?])/);
            return (
                <span>
                    {words.filter(Boolean).map((word, i) => {
                        // 检查单词或其单数形式是否匹配
                        if (
                            normalize(word) === normalizedKeyword || 
                            normalize(pluralize.singular(word)) === normalize(pluralize.singular(normalizedKeyword))
                        ) {
                            return <span key={i} className="highlight-keyword">{word}</span>;
                        }
                        return word;
                    })}
                </span>
            );
        }
    }; 

    const cards = baseMenuItems.map((title, index) => ({
        id: index + 1,
        title: title
    }));

    const findCardByTitle = (title) => {
        return { title }; // 直接返回 title 对象即可
    };

    const getTitleFromSection = (section) => {
        switch(section.toLowerCase()) {
            case 'experience':
            case 'work':
                return 'Experience';
            case 'project':
                return 'Project';
            case 'skills':
                return 'Skills';
            default:
                return section;
        }
    };

    const getKeywordStyle = (isFound, keyword) => {
        const baseStyle = {
            cursor: isFound ? 'pointer' : 'default',
        };

        if (activeKeyword === keyword && isFound) {
            return {
                ...baseStyle,
                
                color: '#6750a4',
                borderRadius: '4px',
                transition: 'all 0.2s ease',
            };
        }

        return baseStyle;
    };

    const handleAiWrittingRequestToDB = async (selectedExperienceDeatil, writtingMode, finetuneMode, keyword, bulletpoint) => {
        try {
            const result = await aiWrittingRequestToDB(selectedExperienceDeatil, writtingMode, finetuneMode, keyword, bulletpoint);
            if (finetuneMode === "rewrite") {
                setSelectedResponsibility(result);
            } else {
                setGenerateNewContent(result);
            }
        } catch (error) {
            console.error('Error in aiWrittingRequestToDB:', error);
        }
    };

    const aiWrittingRequestToDB = async (selectedExperienceDeatil, writtingMode, finetuneMode, keyword, bulletpoint) => {
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return
        }
        const token = await getAccessTokenSilently();
        const formData = new FormData();
        formData.append('experience', JSON.stringify(selectedExperienceDeatil));
        formData.append('finetune_mode', finetuneMode);
        formData.append('mode', writtingMode);
        formData.append('keyword', keyword);
        if (finetuneMode === "rewrite") {
            formData.append('bulletpoint', bulletpoint);
        }

        try {
            const response = await fetch('/resumasterapi/finetune_keyword', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                body: formData
            });
        
            if (response.status !== 201) {
                throw new Error('Sorry, there is an error when saving your application.');
            }
            const data = await response.json();
            return data?.bulletpoint; // Indicate success
        } catch (error) {
            console.error(error);
            alert(error.message);
            throw error; // Re-throw to handle it in the calling function
        }
    }

    const resetAllStates = () => {
        setShowDropdown(false);
        setShowOptions(false);
        setSelectedKeyword(null);
        setSelectedSection('');
        setShowSecondDropdown(false);
        setSelectedExperience('');
        setSelectedExperienceDeatil(null);
        setGenerateNewContent('');
        setRewriteContent('');
        setSelectedResponsibility('');
        setSelectedResponsibilityCopy('');
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                resetAllStates();
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleAddClick = (keyword) => {
        setSelectedKeyword(keyword);
        setShowDropdown(true);
    };

    const handleTemplateClick = (index) => {
        setSelectedTemplate(index);
    };

    const openModal2 = () => {
        setIsModal2Open(true);
        document.body.style.overflow = "hidden";
    };

    useEffect(() => {
        setSelectedExperience('');
        setSelectedExperienceDeatil(null);
    }, [selectedSection]);

    useEffect(() => {
        setGenerateNewContent('');
        setRewriteContent('');
        setSelectedResponsibility('');
        setSelectedResponsibilityCopy('');
    }, [selectedExperience]);

    return (
        <div className={`navibar-outer ${isNavibarExpanded ? 'expanded' : ''}`}>
            <div className="navibar-control">
                <div className="navibar-editor" 
                    style={{ fontWeight: '600', color: navibarMode === 'editor' ? 'black' : '#9E9E9E', borderBottom: navibarMode === 'editor' ? '2px solid' : 'none' }} 
                    onClick={() => handleNavibarModeChange('editor')}>
                    <div style={{ display: 'flex', 
                                alignItems: 'center',
                                justifyContent: 'center', position: 'relative', display: 'inline-block', width: '16px', height: '16px', marginRight: '16px' }}>
                        <img src="/img/new_revision_editor.png" alt="editor icon" width="16" height="20" className='editor-preview-icon' style={{ opacity: navibarMode == 'editor' ? 1 : 0}} />
                        <img src="/img/new_revision_editor_gray.png" alt="editor icon" width="16" height="20" className='editor-preview-icon' style={{ opacity: navibarMode == 'editor' ? 0 : 1}} />
                    </div>
                    Editor
                </div>
                <div className="navibar-preview" 
                    style={{ fontWeight: '600', color: navibarMode === 'preview' ? 'black' : '#9E9E9E', borderBottom: navibarMode === 'preview' ? '2px solid' : 'none' }}  
                    onClick={() => handleNavibarModeChange('preview')}>
                    <div style={{ position: 'relative', display: 'inline-block', width: '16px', height: '16px', marginRight: '16px' }}>
                        <img src="/img/new_revision_preview.png" alt="editor icon" width="16" height="20" className='editor-preview-icon' style={{ opacity: navibarMode == 'editor' ? 1 : 0 }}/>
                        <img src="/img/new_revision_preview_black.png" alt="editor icon" width="16" height="20" className='editor-preview-icon' style={{ opacity: navibarMode == 'editor' ? 0 : 1 }}/>
                    </div>
                    Preview
                </div>
            </div>

            {navibarMode === 'editor' && <div className='editor-side-bar-container'>
                <div className="navibar-revision-content">
                    <div className="navibar-revision-content-title">Revision</div>
                    {baseMenuItems.map((item, index) => {
                        const card = cards.find(c => c.title === item);
                        const isWarningState = 
                            (item === "Personal Details" && !isPersonalInfoFilled) ||
                            (item === "Education" && !isEducationFieldsFilled) ||
                            (item === "Experience" && !isExperienceFieldsFilled) ||
                            (item === "Project" && !isProjectFieldFilled);

                        return (
                            <div key={index} className="navibar-revision-menu-item">
                                <div className="navibar-revision-menu-item-container">
                                    <div className="navibar-revision-menu-item-text" 
                                        style={{
                                            color: isWarningState ? '#DC2626' : (openCard === item ? '#6750A4' : ''),  // 直接用 item 比较
                                            fontWeight: isWarningState ? '700' : (openCard === item ? '1000' : '')
                                        }}
                                        onClick={() => handleCardClick(card)}>
                                        {item}
                                        {item === "Personal Details" && !isPersonalInfoFilled && (
                                            <img 
                                                src="/img/warning_icon.png" 
                                                alt="warning" 
                                                className="warning-icon"
                                            />
                                        )}
                                        {item === "Education" && !isEducationFieldsFilled && (
                                            <img 
                                                src="/img/warning_icon.png" 
                                                alt="warning" 
                                                className="warning-icon"
                                            />
                                        )}
                                        {item === "Experience" && !isExperienceFieldsFilled && (
                                            <img 
                                                src="/img/warning_icon.png" 
                                                alt="warning" 
                                                className="warning-icon"
                                            />
                                        )}
                                        {item === "Project" && !isProjectFieldFilled && (
                                            <img 
                                                src="/img/warning_icon.png" 
                                                alt="warning" 
                                                className="warning-icon"
                                            />
                                        )}
                                    </div>
                                    {(item === 'Resume Summary' || item === 'Cover Letter' || item === 'Follow-Up Builder') && 
                                        <div className="navibar-keyword-suffix" onClick={() => handleDeleteItem(item)}>
                                            Delete
                                        </div>
                                    }
                                    {(item === 'Answer Application Questions') && 
                                        <div className="navibar-keyword-suffix"
                                            onClick={() => {
                                                handleDeleteItem(item);
                                                handleClickStartNew();
                                            }}
                                        >
                                            Delete
                                        </div>
                                    }
                                </div>
                            </div>
                        );
                    })}
                    <div class="sidebar-container-line-inside"></div>
                    {!revisionItem.resume_summary && (
                        <div className="navibar-revision-menu-item-add" onClick={() => {
                            handleAddItem('Resume Summary');
                            // 添加这一行来打开 Resume Summary card
                            handleCardOpen({ title: 'Resume Summary' });
                        }}>
                            Resume Summary
                        </div>
                    )}
                    {!revisionItem.cover_letter && (
                        <div className="navibar-revision-menu-item-add" onClick={() => {
                            handleAddItem('Cover Letter');
                            // 添加这一行来打开 Cover Letter card
                            handleCardOpen({ title: 'Cover Letter' });
                        }}>
                            Cover Letter
                        </div>
                    )}
                    {!revisionItem.follow_up_builder && (
                        <div className="navibar-revision-menu-item-add" onClick={() => {
                            handleAddItem('Follow-Up Builder');
                            // 添加这一行来打开 Follow-Up Builder card
                            handleCardOpen({ title: 'Follow-Up Builder' });
                        }}>
                            Follow-Up Builder
                            <img src="/img/navibar-new.png" alt="navibar new icon" width="35" height="20" style={{ marginLeft: '15px' }} />
                        </div>
                    )}
                    {!revisionItem.answer_application_questions && (
                        <div className="navibar-revision-menu-item-add" onClick={() => {
                            handleAddItem('Answer Application Questions');
                            // 添加这一行来打开 Answer Application Questions card
                            handleCardOpen({ title: 'Answer Application Questions' });
                        }}>
                            Answer Application Questions
                            <img src="/img/navibar-new.png" alt="navibar new icon" width="35" height="20" style={{ marginLeft: '15px' }} />
                        </div>
                    )}
                </div>
                <div class="sidebar-container-line"></div>
                <div className="navibar-keyword-content">
                    <div className="navibar-keyword-content-title">Job Description Keywords</div>
                    <div className="navibar-keyword-content-group">
                        {sortedKeywords.map((keyword) => {
                            const isFound = checkKeywordPresence(keyword);
                            
                            return (
                                <div key={keyword} className="navibar-keyword-menu-container" 
                                    onClick={() => isFound && onKeywordClick(keyword)}
                                    style={getKeywordStyle(isFound, keyword)}>
                                    {showDropdown && selectedKeyword === keyword && (
                                        <div className="navibar-keyword-section-dropdown" ref={dropdownRef}>
                                            <div className="navibar-dropdown-title">
                                                {generateNewContent ? `Generate with AI` : 
                                                rewriteContent ? `Rewrite with AI` : 
                                                `Add ${keyword} to`}
                                            </div>
                                            <div className="navibar-dropdown-content">
                                                <div style={{ position: 'relative' }}>
                                                    <div className="navibar-dropdown-subtitle"
                                                        style={{
                                                            color: selectedSection  ? 'black' : '#AAAAAA;',
                                                            fontWeight: selectedSection  ? '600' : 'inherit'
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setShowOptions(prevState => !prevState);
                                                            setShowSecondDropdown(false);
                                                        }}
                                                    >
                                                        {selectedSection || 'Select section'}
                                                        <div className='navibar-icon-trans-control'>
                                                            <img src="/img/navi_detail_down.png" alt="down" width="16" height="16" 
                                                                style={{ position: 'absolute', opacity: showOptions ? 0 : 1, transition: 'opacity 0.2s' }} 
                                                            />
                                                            <img src="/img/navi_detail_up.png" alt="up" width="16" height="16" 
                                                                style={{  position: 'absolute', opacity: showOptions ? 1 : 0, transition: 'opacity 0.2s' }} 
                                                            />
                                                        </div>
                                                    </div>
                                                    {showOptions && (
                                                        <div className="navibar-dropdown-options">
                                                            <div className="navibar-dropdown-option" onClick={(e) => {
                                                                e.stopPropagation();
                                                                setShowOptions(false);
                                                                setSelectedSection('Experience');
                                                            }}>
                                                                <span>Experience</span>
                                                            </div>
                                                            <div className="navibar-dropdown-option" onClick={(e) => {
                                                                e.stopPropagation();
                                                                setShowOptions(false);
                                                                setSelectedSection('Project');
                                                            }}>
                                                                <span>Project</span>
                                                            </div>
                                                            <div className="navibar-dropdown-option" onClick={(e) => {
                                                                e.stopPropagation();
                                                                setShowOptions(false);
                                                                setSelectedSection('Skills');
                                                            }}>
                                                                <span>Skills</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {selectedSection && (
                                                    <>
                                                        {selectedSection.toLowerCase() === 'skills' ? (
                                                            <div className='navibar-keyword-control-bar'>
                                                                <div className='navibar-back-btn'
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setSelectedSection('');
                                                                    }}
                                                                >
                                                                    <img src="/img/navibar-back.png" alt="back" width="9" height="18"/>
                                                                    <span className='navibar-back-word'>Back</span>
                                                                </div>
                                                                <div className='navibar-update-btn'
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        addToSkillsList();
                                                                        onKeywordClick(selectedKeyword);
                                                                        const title = getTitleFromSection(selectedSection);
                                                                        handleCardOpen(findCardByTitle(title));
                                                                        resetAllStates();
                                                                    }}
                                                                >
                                                                    <img src="/img/navibar-add-btn.png" alt="update" width="43" height="27" />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div style={{ position: 'relative', marginTop: '10px' }}>
                                                                    {getExperienceOptions().length > 0 ? (
                                                                        <>
                                                                            <div className="navibar-dropdown-subtitle"
                                                                                style={{
                                                                                    color: selectedExperience ? 'black' : '#666666',
                                                                                    fontWeight: selectedExperience ? '600' : '400',
                                                                                    marginTop: '10px'
                                                                                }}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation();
                                                                                    setShowSecondDropdown(prev => !prev);
                                                                                    setShowOptions(false);
                                                                                }}
                                                                            >
                                                                                {selectedExperience || `Select ${selectedSection}`}
                                                                                <div className='navibar-icon-trans-control'>
                                                                                    <img src="/img/navi_detail_down.png" alt="down" width="16" height="16" 
                                                                                        style={{ position: 'absolute', opacity: showSecondDropdown ? 0 : 1, transition: 'opacity 0.2s' }} 
                                                                                    />
                                                                                    <img src="/img/navi_detail_up.png" alt="up" width="16" height="16" 
                                                                                        style={{ position: 'absolute', opacity: showSecondDropdown ? 1 : 0, transition: 'opacity 0.2s' }} 
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            {showSecondDropdown && (
                                                                                <div className="navibar-dropdown-options">
                                                                                    {getExperienceOptions().map((item, index) => (
                                                                                        <div key={index} className="navibar-dropdown-option" 
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                setSelectedExperience(item.Experience.Title && item.Experience.Company ? 
                                                                                                    `${item.Experience.Title} at ${item.Experience.Company}` : 
                                                                                                    `${selectedSection} ${index + 1}`
                                                                                                );
                                                                                                setSelectedExperienceDeatil(item.Experience);
                                                                                                setShowSecondDropdown(false);
                                                                                            }}
                                                                                        >
                                                                                            <span>
                                                                                                {item.Experience.Title && item.Experience.Company ? 
                                                                                                    `${item.Experience.Title} at ${item.Experience.Company}` : 
                                                                                                    `${selectedSection} ${index + 1}`
                                                                                                }
                                                                                            </span>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <div style={{
                                                                            padding: '12px',
                                                                            color: '#666666',
                                                                            fontSize: '14px',
                                                                            backgroundColor: '#F9FAFB',
                                                                            borderRadius: '8px',
                                                                            border: '1px solid #E5E7EB',
                                                                            marginTop: '10px',
                                                                            textAlign: 'center'
                                                                        }}>
                                                                            No {selectedSection.toLowerCase()} entries available. Please add some {selectedSection.toLowerCase()} entries first.
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                {selectedExperience && !generateNewContent && !rewriteContent && (
                                                                    <div className="navibar-ai-options" style={{ marginTop: '10px' }}>
                                                                        <div className="navibar-ai-option" 
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setGenerateNewContent('Loading...');
                                                                                handleAiWrittingRequestToDB(selectedExperienceDeatil, writtingMode, "generate_new", selectedKeyword, "")
                                                                            }}
                                                                        >
                                                                            <div className='navibar-ai-option-add-icon'>+</div>
                                                                            Generate New with AI
                                                                        </div>
                                                                        <div className="navibar-ai-option"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                const responsibilities = getResponsibilitiesList();
                                                                                setRewriteContent(responsibilities);
                                                                            }}
                                                                        >
                                                                            <div className='navibar-ai-option-add-icon'>+</div>
                                                                            Rewrite with AI
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                {selectedExperience && generateNewContent && !rewriteContent && (
                                                                    <>
                                                                        <div style={{ padding: '12px', border: '1px solid #E5E7EB', borderRadius: '8px', 
                                                                            marginTop: '10px', minHeight: '100px', backgroundColor: '#F9FAFB', lineHeight: '1.4' }}>
                                                                            {/* {generateNewContent} */}
                                                                            {generateNewContent === 'Loading...' ? 
                                                                            <div className="spinner-container">
                                                                                <div className="loading-spinner">
                                                                                </div>
                                                                            </div> :
                                                                            highlightText(generateNewContent)}
                                                                        </div>
                                                                        
                                                                        <div className='navibar-keyword-control-bar'>
                                                                            <div className='navibar-back-btn'
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    setGenerateNewContent('');
                                                                                }}
                                                                            >
                                                                                <img src="/img/navibar-back.png" alt="back" width="9" height="18"/>
                                                                                <span className='navibar-back-word'>Back</span>
                                                                            </div>
                                                                            <div 
                                                                                className={`navibar-update-btn ${generateNewContent === 'Loading...' ? 'disabled' : ''}`}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    if (generateNewContent !== 'Loading...') {  // 只有不在加载状态才执行更新
                                                                                        addToResponsibilitiesList(generateNewContent);
                                                                                        onKeywordClick(selectedKeyword);
                                                                                        const title = getTitleFromSection(selectedSection);
                                                                                        handleCardOpen(findCardByTitle(title));
                                                                                        resetAllStates();
                                                                                    }
                                                                                }}
                                                                                style={{
                                                                                    opacity: generateNewContent === 'Loading...' ? 0.5 : 1,  // 可选：让禁用状态更明显
                                                                                    cursor: generateNewContent === 'Loading...' ? 'not-allowed' : 'pointer'
                                                                                }}
                                                                            >
                                                                                <img src="/img/navibar-update-btn.png" alt="update" width="66" height="27" />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}

                                                                {selectedExperience && rewriteContent && !generateNewContent && !selectedResponsibility && (
                                                                    <>
                                                                        <div style={{ padding: '12px', borderRadius: '8px', marginTop: '10px'}}>
                                                                            {Array.isArray(rewriteContent) && rewriteContent.map((responsibility, index) => (
                                                                                <div key={index}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        setSelectedResponsibility(responsibility);
                                                                                        setSelectedResponsibilityCopy(responsibility);
                                                                                        handleAiWrittingRequestToDB(selectedExperienceDeatil, writtingMode, "rewrite", selectedKeyword, responsibility);
                                                                                    }}
                                                                                    onMouseEnter={(e) => {
                                                                                        e.currentTarget.style.borderColor = 'black';
                                                                                    }}
                                                                                    onMouseLeave={(e) => {
                                                                                        e.currentTarget.style.borderColor = '#E5E7EB';
                                                                                    }}
                                                                                    style={{ padding: '12px', marginBottom: index < rewriteContent.length - 1 ? '12px' : '0', backgroundColor: 'white', 
                                                                                        border: '1px solid #E5E7EB', borderRadius: '4px', fontSize: '14px', lineHeight: '1.5'}}
                                                                                >
                                                                                    {responsibility}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        
                                                                        <div className='navibar-keyword-control-bar'>
                                                                            <div className='navibar-back-btn'
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    setRewriteContent('');
                                                                                }}
                                                                            >
                                                                                <img src="/img/navibar-back.png" alt="back" width="9" height="18"/>
                                                                                <span className='navibar-back-word'>Back</span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}

                                                                {selectedExperience && selectedResponsibility && (
                                                                    <>
                                                                        <div style={{ padding: '12px', border: '1px solid #E5E7EB', borderRadius: '8px', 
                                                                            marginTop: '10px', minHeight: '100px', backgroundColor: '#F9FAFB', lineHeight: '1.4' }}>
                                                                            {/* {selectedResponsibility} */}
                                                                            {selectedResponsibility === selectedResponsibilityCopy ? 
                                                                            <div className="spinner-container">
                                                                                <div className="loading-spinner">
                                                                                </div>
                                                                            </div> :
                                                                            highlightText(selectedResponsibility)}
                                                                        </div>
                                                                        
                                                                        <div className='navibar-keyword-control-bar'>
                                                                            <div className='navibar-back-btn'
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    setSelectedResponsibility('');
                                                                                    setSelectedResponsibilityCopy('');
                                                                                }}
                                                                            >
                                                                                <img src="/img/navibar-back.png" alt="back" width="9" height="18"/>
                                                                                <span className='navibar-back-word'>Back</span>
                                                                            </div>
                                                                            <div 
                                                                                className={`navibar-update-btn ${selectedResponsibility === selectedResponsibilityCopy ? 'disabled' : ''}`}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    if (selectedResponsibility !== selectedResponsibilityCopy) {  // 只有生成了新内容才执行更新
                                                                                        updateResponsibilitiesList(selectedResponsibility);
                                                                                        onKeywordClick(selectedKeyword);
                                                                                        const title = getTitleFromSection(selectedSection);
                                                                                        handleCardOpen(findCardByTitle(title));
                                                                                        resetAllStates();
                                                                                    }
                                                                                }}
                                                                                style={{
                                                                                    opacity: selectedResponsibility === selectedResponsibilityCopy ? 0.5 : 1,
                                                                                    cursor: selectedResponsibility === selectedResponsibilityCopy ? 'not-allowed' : 'pointer'
                                                                                }}
                                                                            >
                                                                                <img src="/img/navibar-update-btn.png" alt="update" width="66" height="27" />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div className={`navibar-keyword-menu-item${isFound ? '' : '-none'}`} style={{ color: keyword === activeKeyword ? '#9333EA' : 'black' }}>
                                        {keyword}
                                    </div>
                                    {isFound ? (
                                        <img src="/img/navibar-checkmark.png" alt="navibar new icon" width="20" height="20" />
                                    ) : (
                                        <div className="keyword-add-container">
                                            <div 
                                                className="navibar-keyword-status"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleAddClick(keyword);
                                                }}
                                                onMouseEnter={(e) => {
                                                    e.currentTarget.querySelector('.normal-img').style.opacity = '0';
                                                    e.currentTarget.querySelector('.hover-img').style.opacity = '1';
                                                    e.currentTarget.querySelector('.navibar-keyword-add-text').style.color = '#6750a4';
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.currentTarget.querySelector('.normal-img').style.opacity = '1';
                                                    e.currentTarget.querySelector('.hover-img').style.opacity = '0';
                                                    e.currentTarget.querySelector('.navibar-keyword-add-text').style.color = '#333';
                                                }}
                                            >
                                                <div className='navibar-icon-trans-control'>
                                                    <img src="/img/navibar-add-gray.png" alt="normal" className="normal-img" width="16" height="16" style={{ position: 'absolute', opacity: 1, transition: 'opacity 0.2s'}} />
                                                    <img src="/img/navibar-add-purple.png" alt="hover" className="hover-img" width="16" height="16" style={{ position: 'absolute', opacity: 0, transition: 'opacity 0.2s'}} />
                                                </div>
                                                <span className="navibar-keyword-add-text" style={{ fontWeight: '600'}}>Add</span>
                                            </div>
                                            
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="navibar-save-container">
                    <button className="navibar-save-btn" onClick={openModal2} disabled={!areRequiredFieldsFilled()}>
                        Save to Applications & Exit
                    </button>
                    {!areRequiredFieldsFilled() && (
                        <p className="hint-message">Some required fields are incomplete. Please review and complete them as indicated.</p>
                    )}
                </div>
            </div>}

            {navibarMode === 'preview' && <div>
                {/* put things here */}
                <div className="templates-section">
                    <h2 className="templates-header">Templates</h2>
                    <div className="template-list">
                        {[1,0,2].map((element, index) => (
                            <div
                            key={element}
                            className={`template-thumbnail ${selectedTemplate === element ? 'selected' : ''}`}
                            onClick={() => handleTemplateClick(element)}
                            >
                            <img src={`/img/template_${element + 1}.png`} alt={`Template ${element + 1}`} />
                            <p>{element === 0 ? 'Modern' : element === 1 ? 'Classic' : 'Innovative'}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="navibar-preview-save-container">
                    <button onClick={openModal2} className="navibar-save-btn" disabled={!areRequiredFieldsFilled()}>
                        Save to Applications & Exit
                    </button>
                    {!areRequiredFieldsFilled() && (
                        <p className="hint-message">Some required fields are incomplete. Please review and complete them as indicated.</p>
                    )}
                </div>
            </div>}
        </div>
    );
};

export default Navibar;