import PropTypes from "prop-types";
import React, { useState } from "react";
import "./style.css";

export const FrameWrapper = ({ className, text = "Java", is_skills_list = false, onDelete }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleDelete = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    if (onDelete) {
      onDelete(text);
    }
  };

  return (
    <div className={`frame-wrapper ${className}`}>
      <div className="java">{text}</div>
      {is_skills_list && (
        <img 
          src={isHovered ? "/img/delete_button_wrapper_black.png" : "/img/delete_button_wrapper.png"}
          alt="Delete"
          className="delete-icon"
          onClick={handleDelete}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      )}
    </div>
  );
};

FrameWrapper.propTypes = {
  text: PropTypes.string,
  is_skills_list: PropTypes.bool,
  className: PropTypes.string,
  onDelete: PropTypes.func,
};