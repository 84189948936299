import React, { useState, useEffect, useRef, useCallback  } from 'react';
import './TemplateToolBar.css'; // Assuming you have a CSS file for styling
import { useDrag, useDrop } from 'react-dnd';

const DraggableSection = ({ section, index, moveItem, selectedTemplate }) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: 'section',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'section',
    hover: (draggedItem, monitor) => {
      if (!ref.current) return;
    
      const dragIndex = draggedItem.index;
      const hoverIndex = index;
    
      if (dragIndex === hoverIndex) return;
    
      // 获取边界矩形
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const itemHeight = hoverBoundingRect.bottom - hoverBoundingRect.top;
      const oneThird = itemHeight / 5;  // 计算1/3高度
      
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    
      // 只在跨越1/3距离时触发移动
      if (dragIndex < hoverIndex && hoverClientY < oneThird) return;
      if (dragIndex > hoverIndex && hoverClientY > itemHeight - oneThird) return;
    
      moveItem(dragIndex, hoverIndex);
      draggedItem.index = hoverIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  drag(drop(ref));
  // let section_name = section.toLowerCase().replaceAll(' ', '_').replaceAll('-', '_');
  // if (selectedTemplate === 1) {
  //   if (section === "Work Experience") {
  //     section_name = "experience";
  //   } else if (section === "Project Experience") {
  //     section_name = "project";
  //   }
  // }
  
  return (
    <div 
      ref={ref} className={`section-item ${isDragging ? 'dragging' : ''} ${isOver ? 'hover' : ''}`}
      style={{  opacity: isDragging ? 0.5 : 1, cursor: 'move', padding: '8px 12px', backgroundColor: isOver ? '#F8F5FF' : 'white',
        border: `1px solid ${isOver ? '#381E72' : '#E5E5EA'}`, borderRadius: '4px', marginBottom: '4px', display: 'flex',
        alignItems: 'center', transition: 'all 0.2s ease', height: '32px',
      }}
    >
      <img 
        src={`/img/tool_bar_changeable_icon.png`} alt="drag"
        style={{ width: '20px', height: '20px', marginRight: '8px', opacity: 0.5, }}
      />
      <span style={{ flex: 1, fontSize: '14px', color: '#1C1B1F', }}> {section} </span>
    </div>
  );
};

export const TemplateToolBar = ({
  fontsize,
  decreaseFontSize,
  increaseFontSize,
  sectionSpacing,
  setSectionSpacing,
  setSectionBetweenLine,
  lineHeightSpacing,
  setLineHeightSpacing,
  showBoldKeywords,
  setShowBoldKeywords,
  ZoomInButton,
  ZoomOutButton,
  Zoom,
  sectionOrder,
  setSectionOrder,
  selectedTemplate
}) => {
  const [isLineHeightDropdownOpen, setLineHeightDropdownOpen] = useState(false);
  const [isSectionSpacingDropdownOpen, setSectionSpacingDropdownOpen] = useState(false);
  const [decreaseFontClicked, setDecreaseFontClicked] = useState(false);
  const [increaseFontClicked, setIncreaseFontClicked] = useState(false);
  const toolBarRef = useRef(null);
  const lineHeightRef = useRef(null);
  const sectionSpacingRef = useRef(null);

  // const [sectionOrder, setSectionOrder] = useState(['Education', 'Work Experience', 'Project Experience', 'Skills', 'Certificates', 'Summary']);
  const [isSectionOrderDropdownOpen, setSectionOrderDropdownOpen] = useState(false);
  const [sections, setSections] = useState(sectionOrder || []);
  const sectionOrderRef = useRef(null);

  const sectionOrderDropdownHandler = () => {
    setSectionOrderDropdownOpen(!isSectionOrderDropdownOpen);
    setLineHeightDropdownOpen(false);
    setSectionSpacingDropdownOpen(false);
  };

  const moveItem = (dragIndex, hoverIndex) => {
    const newSections = [...sections];
    const draggedItem = newSections[dragIndex];
    newSections.splice(dragIndex, 1);
    newSections.splice(hoverIndex, 0, draggedItem);
    setSections(newSections);
    setSectionOrder(newSections);
};

  const lineHeightDropdownHandler = () => {
    setLineHeightDropdownOpen(!isLineHeightDropdownOpen);
    setSectionSpacingDropdownOpen(false);
  };
  
  const sectionSpacingDropdownHandler = () => {
    setSectionSpacingDropdownOpen(!isSectionSpacingDropdownOpen);
    setLineHeightDropdownOpen(false);
  };

  // Handlers for the font size buttons
  const handleDecreaseFontClick = () => {
    if (fontsize > 8) {
        decreaseFontSize();
    }
    setDecreaseFontClicked(true);
  };

  const handleIncreaseFontClick = () => {
    if (fontsize < 12) {
        increaseFontSize();
    }
    setIncreaseFontClicked(true);
  };

  // Reset hover text visibility when the mouse leaves
  const resetDecreaseFontClick = () => setDecreaseFontClicked(false);
  const resetIncreaseFontClick = () => setIncreaseFontClicked(false);

  // Close dropdowns if user clicks outside the TemplateToolBar or on a toolbar section that is not an arrow button
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside the toolbar
      if (
        toolBarRef.current &&
        !toolBarRef.current.contains(event.target) &&
        !event.target.classList.contains('slider') &&
        !event.target.closest('.dropdown-bubble')
      ) {
        setLineHeightDropdownOpen(false);
        setSectionSpacingDropdownOpen(false);
        setSectionOrderDropdownOpen(false);
      }
    };

    // Close dropdowns if click is inside the toolbar but not on arrow down buttons or dropdown bubble
    const handleToolbarClick = (event) => {
      if (
        toolBarRef.current &&
        toolBarRef.current.contains(event.target) &&
        !event.target.classList.contains('toolbar-section-slider') && // Ensure click is not on the arrow down button
        !event.target.closest('.toolbar-section-slider') && // Ensure click is not on any element within the dropdown button
        !event.target.classList.contains('dropdown-bubble') &&
        !event.target.closest('.dropdown-bubble')
      ) {
        setLineHeightDropdownOpen(false);
        setSectionSpacingDropdownOpen(false);
        setSectionOrderDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleToolbarClick);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mousedown', handleToolbarClick);
    };
  }, []);

  useEffect(() => {
    setSections(sectionOrder || []);
  }, [selectedTemplate]);

  return (
    <div className="template-tool-bar" ref={toolBarRef}>
      {/* First Section */}
      <div className="toolbar-section">
        <ZoomOutButton /> {/* Button to zoom out */}
        <Zoom levels={[0.4, 0.8, 1.2, 1.6, 2.0, 2.4]}/>
        <ZoomInButton />  {/* Button to zoom in */}
      </div>
      <div className="vertical-divider"></div>
      <div className="toolbar-section">
        <button
          className="toolbar-button"
          onClick={handleDecreaseFontClick}
          onMouseLeave={resetDecreaseFontClick}
        >
          <img src="img/minus-icon.png" alt="Decrease Font" className="toolbar-icon" />
          {!decreaseFontClicked && (
            <span className="hover-text">Decrease font size</span>
          )}
        </button>
        

        <img src="/img/font-size-icon.png" alt="Font Icon" className="toolbar-icon" />
        <span className="toolbar-span">{fontsize}</span>

        <button
          className="toolbar-button"
          onClick={handleIncreaseFontClick}
          onMouseLeave={resetIncreaseFontClick}
        >
          <img src="img/add-icon.png" alt="Increase Font" className="toolbar-icon" />
          {!increaseFontClicked && (
            <span className="hover-text">Increase font size</span>
          )}
        </button>
        
      </div>

      {/* Vertical Divider */}
      <div className="vertical-divider"></div>

      {/* Second Section with Dropdown Bubble */}
      <div className="toolbar-section-container">
        <div
          className="toolbar-section-slider"
          onClick={lineHeightDropdownHandler}
          ref={lineHeightRef}
          style={{
            cursor: 'pointer',
            backgroundColor: isLineHeightDropdownOpen ? '#E5E5EA' : 'transparent',
          }}
        >
          <img src="img/line-height-icon.png" alt="Line Height Icon" className="toolbar-icon" />
          <span className="toolbar-span">{lineHeightSpacing}</span>
          <img src="img/expand-icon.png" alt="Dropdown Icon" className="toolbar-icon" />
          {!isLineHeightDropdownOpen && (
            <span className="hover-text">Line height</span>
          )}
        </div>

        {/* Dropdown Bubble for Line Height */}
        {isLineHeightDropdownOpen && (
          <div className="dropdown-bubble">
            <div className="slider-container">
              <span className="slider-value">3</span>
              <input
                type="range"
                min="3"
                max="12"
                step="0.5"
                value={lineHeightSpacing}
                onChange={(e) => setLineHeightSpacing(parseFloat(e.target.value))}
                className="slider"
              />
              <span className="slider-value">12</span>
            </div>
          </div>
        )}
      </div>


      {/* Vertical Divider */}
      <div className="vertical-divider"></div>

      {/* Third Section with Dropdown Bubble */}
      <div className="toolbar-section-container">
        <div
          className="toolbar-section-slider"
          onClick={sectionSpacingDropdownHandler}
          ref={sectionSpacingRef}
          style={{
            cursor: 'pointer',
            backgroundColor: isSectionSpacingDropdownOpen ? '#E5E5EA' : 'transparent',
          }}
        >
          <img src="/img/section-spacing-icon.png" alt="Section Spacing Icon" className="toolbar-icon" />
          <span className="toolbar-span">{sectionSpacing}</span>
          <img src="img/expand-icon.png" alt="Dropdown Icon" className="toolbar-icon" />
          {!isSectionSpacingDropdownOpen && (
            <span className="hover-text">Sections spacing</span>
          )}
        </div>

        {/* Dropdown Bubble for Section Spacing */}
        {isSectionSpacingDropdownOpen && (
          <div className="dropdown-bubble">
            <div className="slider-container">
              <span className="slider-value">8</span>
              <input
                type="range"
                min="8"
                max="30"
                step="2"
                value={sectionSpacing}
                onChange={(e) => {
                  const newValue = parseInt(e.target.value, 10);
                  setSectionSpacing(newValue);
                  setSectionBetweenLine(Math.min(1 + (newValue - 8) * 0.5 / 2, 5));
                }}
                className="slider"
              />
              <span className="slider-value">30</span>
            </div>
          </div>
        )}
      </div>

      {/* Vertical Divider */}
      <div className="vertical-divider"></div>

      {/* Fourth Section */}
      <div className="toolbar-section">
        <label className="bold-keywords-checkbox">
          <input
            type="checkbox"
            checked={showBoldKeywords}
            onChange={() => setShowBoldKeywords(!showBoldKeywords)}
          />
          Bold Keywords
        </label>
      </div>
      
      {sectionOrder && (
        <>
          <div className="vertical-divider"></div>
          <div className="toolbar-section-container">
            <div
              className="toolbar-section-slider"
              onClick={sectionOrderDropdownHandler}
              ref={sectionOrderRef}
              style={{
                cursor: 'pointer',
                backgroundColor: isSectionOrderDropdownOpen ? '#E5E5EA' : 'transparent',
              }}
            >
              <img src="/img/tool_bar_section_order.png" alt="Section Order" className="toolbar-icon"/>
              <span style={{fontsize: '16px', fontWeight: '600'}}>Reorder Sections</span>
              {!isSectionOrderDropdownOpen && (
                <span className="hover-text">Section order</span>
              )}
            </div>

            {isSectionOrderDropdownOpen && (
              <div className="dropdown-bubble section-order-dropdown">
                <div className="section-order-list">
                  {sections.map((section, index) => (
                    <DraggableSection key={section} section={section} index={index} moveItem={moveItem} selectedTemplate={selectedTemplate}/>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
