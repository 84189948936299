import React from "react";
import { Navigation } from "../../components/Navigation";
import Footer from "../../components/footer/footer";
import { Helmet } from "react-helmet";
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
//---------------------------------Authentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------Authentication code----------------------------------------
import "./style.css";

const AboutUs = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="about-us">
      {isAuthenticated ? (
        <Navigation_loggedin isCollapsedMenu={false} isShowDiscord={true} className="navigation-2202" />
      ) : (
        <Navigation className="navigation-2202" />
      )}
      <div className="about-us-content">
        <div className="about-us-box1">
          <div className="bg-logo-img">
            <img src="/img/logo-purple.png" alt="logo background keys" />
          </div>
          <div className="about-us-header">
            ResuMaster redefines career success for today's job seekers
          </div>
        </div>
        <div className="about-us-box2">
          <div className="about-us-text">Proud to have been part of:</div>
          <div className="about-us-part-of-logos">
            <img
              className="pap-logo"
              src="/img/plug-and-play.png"
              alt="pap logo background keys"
            />
            <img
              className="gt-logo"
              src="/img/georgia-tech.png"
              alt="gt logo background keys"
            />
          </div>
        </div>
        <div className="about-us-box3">
          <img src="/img/about-1.png" />
          <img src="/img/about-2.png" />
          <img src="/img/about-3.png" />
        </div>
      </div>
      <Footer />
      <div style={{padding: '20px'}}/>
    </div>
  );
};

export default AboutUs;
