import React, { useState, useRef, useEffect } from 'react';
import { Button } from "../../components/Button";
import { jsPDF } from 'jspdf';
import { useAuth0 } from "@auth0/auth0-react";
import './CoverletterModal.css';

const CoverletterModal = ({ isOpen, onClose, applicationID }) => {

    if (!isOpen) return null;

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    
	const dropdownRef = useRef(null);

    const [loading, setLoading] = useState(true); // Track loading state
    const [error, setError] = useState(null); // Track errors

    const [coverletter, setCoverletter] = useState(``);
    const paragraphs = coverletter.split('\n');
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [personal_web, setPersonalWeb] = useState("");
    const [company, setCompany] = useState("");
    const [jobPosition, setJobPosition] = useState("");

    const sanitizeString = (str) => {
        if (typeof str !== 'string') {
          return str; // Return the original value if it's not a string
        }
        
        return str
          .replace(/[\r\n]+/g, ' ')     // Sanitize newlines
          .replace(/\s+/g, ' ')          // Remove excessive spaces
          .replace(/，/g, ',')           // Replace full-width comma with a regular comma
          .replace(/‐|–|—/g, '-')        // Replace en-dash and em-dash with a regular hyphen
          .trim();
    };

	const handleClose = (e) => {
		e.stopPropagation();
		onClose();
	};

    const fetchCoverLetterData = async () => {
        setLoading(true); // Start loading
        setError(null); // Reset any previous errors

        if (!isAuthenticated) {
            alert("Login required: Please login first");
            setLoading(false);
            return;
        }

        try {
            const token = await getAccessTokenSilently();
            const formData = new FormData();
            formData.append('application_id', applicationID);

            const response = await fetch('/resumasterapi/fetch_application_coverletter_json', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                body: formData
            });

            if (!response.ok) {
                if (response.status === 400 || response.status === 500) {
                    throw new Error('Sorry, there is some issue with our server. Please try again later.');
                }
                if (response.status === 401) {
                    throw new Error('Sorry, you must login first.');
                }
            }

            const data = await response.json();
            if (data) {
                const coverletterJSON = JSON.parse(data);
                // Sanitize specific fields
                const sanitizedName = sanitizeString(coverletterJSON.name || '');
                const sanitizedEmail = sanitizeString(coverletterJSON.email || '');
                const sanitizedPhone = sanitizeString(coverletterJSON.phone || '');
                const sanitizedCompany = sanitizeString(coverletterJSON.company || '');
                setCoverletter(coverletterJSON.coverletter || '');
                setName(sanitizedName);
                setEmail(sanitizedEmail);
                setPhone(sanitizedPhone);
                setPersonalWeb(coverletterJSON.personal_web || '');
                setCompany(sanitizedCompany);
                setJobPosition(coverletterJSON.jobPosition || '');
            } else {
                console.log('Cover letter data is null');
            }
        } catch (error) {
            setError(error.message); // Capture any errors
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        if (isOpen) {
            fetchCoverLetterData(); // Fetch the data when the modal opens
        }
    }, [isOpen]); // Only trigger when the modal is opened

    const handleCopyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(coverletter);
            console.log("Text copied to clipboard");
        } catch (err) {
            console.error("Error copying text: ", err);
        }
    }

    const exportStringToPDF = (content) => {
        const doc = new jsPDF({
            format: 'letter',
          });
      
          const marginLeft = 20;
          const marginRight = 20;
          const marginTop = 20;
          const marginBottom = 20;
          const pageWidth = doc.internal.pageSize.width;
          const maxContentWidth = pageWidth - marginLeft - marginRight;
      
          // Add Name section
          doc.setFontSize(18);
          doc.setFont("helvetica", "bold");
          doc.text(name, marginLeft, marginTop);
      
          // Add some space
          let y = marginTop + 7;
      
          // Add Contacts section
          doc.setFontSize(11);
          doc.setFont("helvetica", "normal");
      
          let contactInfo = [];
          if (email) contactInfo.push(`• ${email}`);
          if (phone) contactInfo.push(`• ${phone}`);
          if (personal_web) contactInfo.push(`• ${personal_web}`);
      
          let contactLine = '';
          contactInfo.forEach(info => {
            if (doc.getTextWidth(contactLine + info) > maxContentWidth) {
              doc.text(contactLine.trim(), marginLeft, y);
              y += 6;
              contactLine = info + '   ';
            } else {
              contactLine += info + '   ';
            }
          });
          if (contactLine.trim()) {
            doc.text(contactLine.trim(), marginLeft, y);
            y += 6;
          }
      
          // Add some space
          y += 10;
      
          // Add Company section
          doc.setFontSize(14);
          doc.setFont("helvetica", "bold");
          doc.text(company, marginLeft, y);
          y += 10;
      
          // Add Cover Letter content
          doc.setFontSize(11);
          doc.setFont("helvetica", "normal");
          const lines = doc.splitTextToSize(content, maxContentWidth);
      
          const lineHeight = 5;
          const pageHeight = doc.internal.pageSize.height;
          const maxPageHeight = pageHeight - marginBottom;
      
          lines.forEach(line => {
            if (y + lineHeight > maxPageHeight) {
              doc.addPage();
              y = marginTop;
            }
            doc.text(line, marginLeft, y);
            y += lineHeight * 1.15;
          });
      
          let jobPositionPart = jobPosition ? `_${jobPosition}` : '';
          let companyPart = company ? `_${company}` : '';
      
          doc.save(`${name}${jobPositionPart}${companyPart}_cover_letter.pdf`);
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                onClose(); // Close the modal if clicked outside
            }
        };

        // Add event listener for clicks
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Cleanup the event listener when the component is unmounted
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);


	return (
		<div className="add-application-modal-overlay">
			<div className="add-application-modal-content" ref={dropdownRef} onClick={e => e.stopPropagation()}>
				<div className="add-application-modal-scroll-content">
                    <div className="job-description-modal-header">
                        <h2 className="job-description-modal-title">Cover Letter Preview</h2>
                            <img 
                                src="/img/ic_round-close.png" 
                                alt="Close" 
                                className="close-icon" 
                                onClick={handleClose} // This function will close the modal
                            />
                    </div>
					<div className="cl-popup-main">
                        <div className="resumeContent">
                            <div className="popup-img-box">
                            <img className="cl-popup-copy-img"
                                alt="Copy"
                                src="/img/dashboard_copy.png"
                                onClick={() => handleCopyToClipboard()} // join array items with new lines
                            />
                            <img
                                className="popup-export-button"
                                onClick={() => exportStringToPDF(coverletter)}
                                src='./img/dashboard_download.png'
                            />
                            </div>
                            {/* Conditionally render content */}
                            {loading ? (
                                <p>Loading...</p>
                            ) : error ? (
                                <p>{error}</p>
                            ) : (
                                paragraphs.map((paragraph, index) => (
                                    <p className="paragraph" key={index} style={{marginBottom: "15px"}}>{paragraph}</p>
                                ))
                            )}
                        </div>
                    </div>
				</div>
			</div>
		</div>
	);
};

export default CoverletterModal;