import React, { useEffect, useState, useRef, createRef, useCallback } from 'react';
import { Navigation } from "../../components/Navigation";
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { NavigationLoggedinEditor } from "../../components/Navigation_loggedin_editor/NavigationLoggedinEditor"
import { ModalCloseEditor } from "../../components/ModalCloseEditor/ModalCloseEditor"
import { useAuth0 } from "@auth0/auth0-react";
import Navibar from '../NewRevision/Navibar';
import NaviDetail from '../NewRevision/NaviDetail';
import { ResuBot } from '../Resubot';
import CompanyInputBar from '../../components/CompanyInputBar/CompanyInputBar';
import './NewRevision.css'
import { use } from 'react';

const NewRevision = () => {
    const location = useLocation();
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const navigate = useNavigate();
    const [isNavibarExpanded, setIsNavibarExpanded] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            // If window is resized above breakpoint, collapse the navbar and reset styles
            if (window.innerWidth > 1024) {
                setIsNavibarExpanded(false);
            }
        };
    
        // Initial call to set the width
        handleResize();
        
        window.addEventListener('resize', handleResize);
        
        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [baseMenuItems, setBaseMenuItems] = useState([
        "Personal Details",
        "Education",
        "Experience",
        "Project",
        "Skills",
        "Certificates"
    ]);

    const [revisionItem, setRevisionItem] = useState({
        resume_summary: false,
        cover_letter: false,
        follow_up_builder: false,
        answer_application_questions: false,
    });
    
    const application_id = location.state?.application_id;
    const [data, setData] = useState(location.state?.data);
    const resetExpCopy = location.state?.resetExpCopy;
    const [original_data, setOriginalData] = useState(location.state?.original_data);
    const [executive_summary, setExecutiveSummary] = useState(location.state?.executive_summary);
    const [selectedExperience, setSelectedExperience] = useState(location.state?.selectedExperienceIndex);
    const [coverletterApiResponse, setCoverletterApiResponse] = useState(location.state?.coverLetter);
    const [emailSubject, setEmailSubject] = useState(null);
    const [followUpEmail, setFollowUpEmail] = useState(null);
    // company name and job title use for naming the exported file
    const [jobPosition, setJobPosition] = useState(location.state?.jobPosition);
    const [companyName, setCompanyName] = useState(location.state?.companyName);
    const [companyDomain, setCompanyDomain] = useState(location.state?.companyDomain);
    // values used in Answer Application Questions
    const [question, setQuestion] = useState(null);
    const [length, setLength] = useState(100);
    const [questionAnswer, setQuestionAnswer] = useState(null);
    const [customRequirement, setCustomRequirement] = useState(null);
    const [revisedFeedback, setRevisedFeedback] = useState(null);
    const [pageStatus, setPageStatus] = useState('default');
    // boolean to check whether the skills section is categorized
    const [showCategorySkills, setShowCategorySkills] = useState(location.state?.isSkillsCategorized);
    const [categorizedSkills, setCategorizedSkills] = useState(location.state?.categorizedSkills);
    const [selectedSection, setSelectedSection] = useState(location.state?.currentSection);
    const job_description = location.state?.job_description;
    const company_analysis = location.state?.company_analysis;
    const [isResubotOpen, setIsResubotOpen] = useState(false);
    const [currentSection, setCurrentSection] = useState(null);
    const [navibarMode, setNavibarMode] = useState('editor');
    const [editorScrollPosition, setEditorScrollPosition] = useState(0);
    const [isRestoringFromResubot, setIsRestoringFromResubot] = useState(false);
    const [isPersonalInfoFilled, setIsPersonalInfoFilled] = useState(true);
    const [isEducationFieldsFilled, setIsEducationFieldsFilled] = useState(true);
    const [isExperienceFieldsFilled, setIsExperienceFieldsFilled] = useState(true);
    const [isProjectFieldFilled, setIsProjectFieldFilled] = useState(true);
    //pdf generator states
    const [selectedTemplate, setSelectedTemplate] = useState(1);
    const [showBoldKeywords, setShowBoldKeywords] = useState(false);
    const jd_keywords = location.state?.jd_keywords;
    const [fontsize, setFontSize] = useState(location.state?.fontsize);
    const [name_fontsize, setNameFontSize] = useState(location.state?.name_fontsize);
    const [sectionSpacing, setSectionSpacing] = useState(location.state?.sectionSpacing);
    const [sectionBetweenLine, setSectionBetweenLine] = useState(location.state?.sectionBetweenLine);
    const [lineHeightSpacing, setLineHeightSpacing] = useState(location.state?.lineHeightSpacing);
    // template 1 special toolbar variables
    const [template_1_section_header, setTemplate1SectionHeader] = useState(location.state?.template_1_section_header);
    // template 3 special toolbar variables
    const [name_fontsize_template_3, setNameFontsizeTemplate3] = useState(location.state?.name_fontsize_template_3);
    //save to application states
    const [isModal2Open, setIsModal2Open] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    //leave the editor modal states
    const [isModal1Open, setIsModal1Open] = useState(false);
    const [pendingNavigation, setPendingNavigation] = useState(null);

    const buildInitialSectionOrder = (template) => {
        const sections = [];
        
        // Summary 总是添加在最前面
        if (executive_summary) {
            sections.push('Summary');
        }
        
        // Education
        if (original_data.education && original_data.education.length > 0) {
            sections.push('Education');
        }
        
        if (template === 0) {
            // Experience
            const experiences = [...data.rendering_data.work, ...data.rendering_data.project];
            if (experiences && experiences.length > 0) {
                sections.push('Experience');
            }
        } else if (template === 1) {
            // Work Experience
            if (data.rendering_data.work && data.rendering_data.work.length > 0) {
                sections.push('Work Experience');
            }
            
            // Project Experience
            if (data.rendering_data.project && data.rendering_data.project.length > 0) {
                sections.push('Project Experience');
            }
        }
        
        if (original_data.skills && original_data.skills.length > 0) {
            sections.push('Skills');
        }
    
        if (original_data.certificate && original_data.certificate.length > 0) {
            sections.push('Certificates');
        }
        
        return sections;
    };
      
    const [sectionOrderTemplate1, setSectionOrderTemplate1] = useState(buildInitialSectionOrder(0));
    const [sectionOrderTemplate2, setSectionOrderTemplate2] = useState(buildInitialSectionOrder(1));

    useEffect(() => {
        const buildSectionSet = (template) => {
            const shouldExistSections = new Set();
            
            // 共同的sections
            if (executive_summary) {
                shouldExistSections.add('Summary');
            }
            if (original_data.education?.length > 0) {
                shouldExistSections.add('Education');
            }
            if (original_data.skills?.length > 0) {
                shouldExistSections.add('Skills');
            }
            if (original_data.certificate?.length > 0) {
                shouldExistSections.add('Certificates');
            }
        
            // 根据模板处理工作和项目经验
            if (template === 0) {  // template 1: 合并的Experience
                const experiences = [...(data?.rendering_data?.work || []), ...(data?.rendering_data?.project || [])];
                if (experiences.length > 0) {
                shouldExistSections.add('Experience');
                }
            } else {  // template 2: 分开的Work Experience和Project Experience
                if (data?.rendering_data?.work?.length > 0) {
                shouldExistSections.add('Work Experience');
                }
                if (data?.rendering_data?.project?.length > 0) {
                shouldExistSections.add('Project Experience');
                }
            }
        
            return shouldExistSections;
        };
      
        // 更新 Template1 的 section order
        setSectionOrderTemplate1(prevOrder => {
            const shouldExistSections = buildSectionSet(0);
            const filteredOrder = prevOrder.filter(section => 
                shouldExistSections.has(section)
            );
            const newSections = Array.from(shouldExistSections).filter(
                section => !prevOrder.includes(section)
            );
            return [...newSections, ...filteredOrder];
        });
      
        // 更新 Template2 的 section order
        setSectionOrderTemplate2(prevOrder => {
            const shouldExistSections = buildSectionSet(1);
            const filteredOrder = prevOrder.filter(section => 
                shouldExistSections.has(section)
            );
            const newSections = Array.from(shouldExistSections).filter(
                section => !prevOrder.includes(section)
            );
            return [...newSections, ...filteredOrder];
        });
    }, [data, executive_summary, original_data]);
    
    const cardRefs = useRef({});

    const handleNavibarModeChange = (mode) => {
        setNavibarMode(mode);
    }

    const handleClickStartNew = () => {
        setPageStatus('default');
        setQuestion(null);
        setLength(100);
        setCustomRequirement(null);
        setQuestionAnswer(null);
        setRevisedFeedback(null);
    }

    //variable used to track summary generation
    const [loading, setLoading] = useState(false); // state for loading
    const [apiResponse, setApiResponse] = useState(null);
    const [openCard, setOpenCard] = useState(null);


    const handleCardClick = (card) => {
        if (openCard === card.title) {  // 改为比较 title
            setOpenCard(null);
        } else {
            setOpenCard(null);  
            
            requestAnimationFrame(() => {
                const cardElement = cardRefs.current[card.title]?.current;  // 使用 title 作为 ref 的 key
                if (cardElement) {
                    const cardPosition = cardElement.getBoundingClientRect().top;
                    const scrollContainer = document.querySelector('.navi-detail-outer');
                    const containerTop = scrollContainer.getBoundingClientRect().top;
                    const offset = 20;
                    
                    const scrollPosition = cardPosition - containerTop - offset;
    
                    setTimeout(() => {
                        if (scrollContainer) {
                            scrollContainer.scrollTop = scrollPosition;
                        }
                        
                        setOpenCard(card.title);  // 存储 title
                        if (card.title === "Experience") {
                            setCurrentSection('work');
                        } else if (card.title === "Project") {
                            setCurrentSection('project');
                        }
                    }, 100);
                }
            });
        }
    };

    const handleCardOpen = (card) => {
        if (openCard !== card.title) {  // 改为比较 title
            setOpenCard(card.title);
            if (card.title === "Experience") {
                setCurrentSection('work');
            } else if (card.title === "Project") {
                setCurrentSection('project');
            }
        }
    };

    const handleAddItem = (item) => {
        setRevisionItem(prev => ({
            ...prev,
            [item.toLowerCase().replaceAll(' ', '_').replaceAll('-', '_')]: true
        }));
        if (item === 'Resume Summary') {
            sendToServer();
        }
    };

    const handleDeleteItem = (item) => {
        if (item === 'Resume Summary') {
            setExecutiveSummary(null);
        } else if (item === 'Cover Letter') {
            setCoverletterApiResponse(null);
        } else if (item === 'Follow-Up Builder') {
            setEmailSubject(null);
            setFollowUpEmail(null);
        }
        setRevisionItem(prev => ({
            ...prev,
            [item.toLowerCase().replaceAll(' ', '_').replaceAll('-', '_')]: false
        }));
    };

    useEffect(() => {

        const newItems = [
            "Personal Details",
            "Education",
            "Experience",
            "Project",
            "Skills",
            "Certificates"
        ];
        
        if (revisionItem.resume_summary) {
            newItems.push("Resume Summary");
        }
        if (revisionItem.cover_letter) {
            newItems.push("Cover Letter");
        }
        if (revisionItem.follow_up_builder) {
            newItems.push("Follow-Up Builder")
        }
        if (revisionItem.answer_application_questions) {
            newItems.push("Answer Application Questions")
        }
        setBaseMenuItems(newItems);
    }, [revisionItem]);

    const [activeKeyword, setActiveKeyword] = useState(null);

    const handleKeywordClick = (keyword) => {
        // 如果点击的是已经激活的关键词，则取消高亮
        setActiveKeyword(prev => prev === keyword ? null : keyword);
    };

    const areRequiredFieldsFilled = () => {
        return isPersonalInfoFilled &&
        isEducationFieldsFilled &&
        isExperienceFieldsFilled &&
        isProjectFieldFilled;
    };

    const sendToServer = async () => {
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return;
        }
        // create a FormData object
        const formData = new FormData();
        // append the file and the job description
        formData.append('job_description', job_description);
        formData.append('company_description', company_analysis);

        // loop through resume data
        const resume = [];
        if (data && data.rendering_data && data.rendering_data.work) {
            for (let i = 0; i < data.rendering_data.work.length; i++) {
                    const myObject = {};
                    myObject["title"] = data.rendering_data.work[i].Experience.Title;
                    myObject["duration"] = data.rendering_data.work[i].Experience.Duration;
                    myObject["company"] = data.rendering_data.work[i].Experience.Company;
                    myObject["location"] = data.rendering_data.work[i].Experience.Location;
                    myObject["content"] = data.rendering_data.work[i].Experience.Responsibilities;
                    resume.push(myObject);
                }
        }
        if (data && data.rendering_data && data.rendering_data.project) {
            for (let i = 0; i < data.rendering_data.project.length; i++) {
                    const myObject = {};
                    myObject["title"] = data.rendering_data.project[i].Experience.Title;
                    myObject["duration"] = data.rendering_data.project[i].Experience.Duration;
                    myObject["company"] = data.rendering_data.project[i].Experience.Company;
                    myObject["location"] = data.rendering_data.project[i].Experience.Location;
                    myObject["content"] = data.rendering_data.project[i].Experience.Responsibilities;
                    resume.push(myObject);
                }
        }

        formData.append('resume', JSON.stringify(resume));

        //start loading
        setLoading(true);
        
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/executive_summary', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
            body: formData
        }).then(response => {
            // Check for the 400 status code 
            // console.log('Server Response:', response);
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first to upload your resume')
            }
            //---------------------------------auhentication code----------------------------------------
            return response.json();
        }).then(data => {
            setApiResponse(data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
            alert(error.message);  // Displaying the error message to the user
        });
    }

    // new function for the resume object & coverletter saving
    const sendToServer_db = async (navigateTo) => {
        //---------------------------------auhentication code----------------------------------------
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return;
        }
        //---------------------------------auhentication code----------------------------------------
        const combinedSkills = [...new Set([...(original_data.skills || []), ...(jd_keywords || [])])];
        // create a FormData object
        const formData = new FormData();
        const namingInfo = {
            jobPosition: jobPosition,
            companyName: companyName
        };
        const store_data = {
            originalData: original_data,
            expData: data.rendering_data,
            namingInfo: namingInfo,
            executiveSummary: executive_summary === null ? null : executive_summary,
            templateIndex: selectedTemplate,
            isShowBoldKeywords: showBoldKeywords,
            boldKeywordsList: combinedSkills,
            fontsize: fontsize,
            name_fontsize: name_fontsize,
            sectionSpacing: sectionSpacing,
            sectionBetweenLine: sectionBetweenLine,
            lineHeightSpacing: lineHeightSpacing,
            template_1_section_header: template_1_section_header,
            name_fontsize_template_3: name_fontsize_template_3,
            sectionOrder: selectedTemplate === 1 ? sectionOrderTemplate2 : selectedTemplate === 0 ? sectionOrderTemplate1 : []
        };
        const coverLetterData = {
            coverletter: coverletterApiResponse,
            name: `${original_data.personal_info.first_name} ${original_data.personal_info.last_name}`,
            email: original_data.personal_info.email,
            phone: original_data.personal_info.phone_number,
            personal_web: original_data.personal_info.personal_website_url,
            company: companyName,
            jobPosition: jobPosition
        };
        store_data.originalData.isSkillsCategorized = showCategorySkills;
        store_data.originalData.categorizedSkills = categorizedSkills;
        // append the file and the job description
        formData.append('resume_json', JSON.stringify(store_data));
        formData.append('company', companyName);
        formData.append('job_position', jobPosition);
        formData.append('domain', companyDomain);
        if (coverletterApiResponse) {
            formData.append('coverletter_json', JSON.stringify(coverLetterData));
        }
    
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        if (application_id) {
            formData.append('application_id', application_id)
            fetch('/resumasterapi/update_applications_resume', { //api endpoint
                method: 'POST',
                //---------------------------------auhentication code----------------------------------------
                headers: {
                    Authorization: `Bearer ${token}`
                },
                //---------------------------------auhentication code----------------------------------------
                body: formData
            }).then(response => {
                // Check for the 400 status code 
                // console.log('Server Response:', response);
                if (response.status === 400) {
                    throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
                }
                if (response.status == 500) {
                    throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
                }
                //---------------------------------auhentication code----------------------------------------
                if (response.status == 401) {
                    throw new Error('Sorry, you must login first')
                }
                //---------------------------------auhentication code----------------------------------------
                navigate(navigateTo);
                document.body.style.overflow = ""; // Re-enable scrolling
            }).catch(error => {
                // handle the error
                console.error(error);
                alert(error.message);  // Displaying the error message to the user
            });
        } else {
            formData.append('job_description', job_description);
            formData.append('application_status', "Applied");
            fetch('/resumasterapi/add_applications', { //api endpoint
                method: 'POST',
                //---------------------------------auhentication code----------------------------------------
                headers: {
                    Authorization: `Bearer ${token}`
                },
                //---------------------------------auhentication code----------------------------------------
                body: formData
            }).then(response => {
                // Check for the 400 status code 
                // console.log('Server Response:', response);
                if (response.status === 400) {
                    throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
                }
                if (response.status == 500) {
                    throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
                }
                //---------------------------------auhentication code----------------------------------------
                if (response.status == 401) {
                    throw new Error('Sorry, you must login first')
                }
                //---------------------------------auhentication code----------------------------------------
                navigate(navigateTo);
                document.body.style.overflow = ""; // Re-enable scrolling
            }).catch(error => {
                // handle the error
                console.error(error);
                // If there's an error, ensure loading is turned off
                alert(error.message);  // Displaying the error message to the user
            });
            }
    };


    const closeModal1 = () => {
        setIsModal1Open(false);
        document.body.style.overflow = "";
    };

    const closeModal2 = () => {
        setIsModal2Open(false);
        document.body.style.overflow = "";
    };

    const handleNavigationAttempt = (destination) => {
        setPendingNavigation(destination);
        setIsModal1Open(true);
        document.body.style.overflow = "hidden"; // Disable scrolling when modal is open
    };

    const confirmNavigation = () => {
        setIsModal1Open(false);
        if (pendingNavigation) {
            navigate(pendingNavigation); // Navigate to the stored destination
        }
        document.body.style.overflow = ""; // Re-enable scrolling
    };

    useEffect(() => {
        if (companyName.trim() !== '' && jobPosition.trim() !== '' ) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [companyName, jobPosition]);

    return (
        isAuthenticated ? (
            isResubotOpen && data && selectedExperience !== null && currentSection && location.state?.mode ? (
                <ResuBot 
                    dataObj={data} 
                    selectedExperience={selectedExperience} 
                    setSelectedExperience={setSelectedExperience}
                    currentSection={currentSection} 
                    setCurrentSection={setCurrentSection}
                    writingMode={location.state?.mode}
                    setData={setData}
                    setIsResubotOpen={(value) => {
                        if (!value) { // When closing Resubot
                            setIsRestoringFromResubot(true);
                        }
                        setIsResubotOpen(value);
                    }}
                />
            ) : (
                <div className="new-revision-div" style={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    height: '100vh',
                    overflow: 'hidden'
                }}>
                    <Helmet>
                        {/* <!-- Google tag (gtag.js) --> */}
                        <script async src="https://www.googletagmanager.com/gtag/js?id=G-F812QPTDD9"></script>
                        <script>
                        {`window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
    
                        gtag('config', 'G-F812QPTDD9');
                        `}
                        </script>
                        <title>Revision - ResuMaster</title>
                        <meta property="og:title" content="Revision - ResuMaster" />
                        <body className="result_page" />
                        <script src="//embed.typeform.com/next/embed.js"></script>
                    </Helmet>
    
                    {
                        isAuthenticated ? (
                            <NavigationLoggedinEditor 
                                className="navigation-2202" 
                                onLinkClick={handleNavigationAttempt}
                            />
                        ) : (
                            <Navigation className="navigation-2202" />
                        )
                    }

                    {windowWidth <= 1024 && (
                    <>
                        <div 
                        className={`navibar-toggle ${isNavibarExpanded ? 'expanded' : ''}`}
                        onClick={() => setIsNavibarExpanded(!isNavibarExpanded)}
                        >
                        <img 
                            src="/img/vector-white.png" 
                            alt={isNavibarExpanded ? "Collapse" : "Expand"}
                        />
                        </div>
                        <div 
                        className={`mobile-overlay ${isNavibarExpanded ? 'active' : ''}`}
                        onClick={() => setIsNavibarExpanded(false)}
                        />
                    </>
                    )}

                    <div className={`dimmer-delete ${isModal1Open ? 'active' : ''}`}></div>
                    <ModalCloseEditor 
                        isOpen={isModal1Open} 
                        handleYesModal={confirmNavigation} // Confirm navigation
                        closeModal={closeModal1} // Cancel navigation
                    />
                    
                    <div className={`dimmer-delete ${isModal2Open ? 'active' : ''}`}></div>
                    {isModal2Open && (
                        <div className="save-application-resume-modal-profile">
                            <div className="save-application-modal-content">
                                <div style={{ marginBottom: '30px', fontSize: '20px',fontWeight: '700' }}>Please fill application details before saving</div>
                                <div className="save-application-modal-group">
                                    <label className="add-application-label" htmlFor="position">
                                        Target Company <span style={{ color: '#B00000' }}>*</span>
                                    </label>
                                    <CompanyInputBar
                                        initialCompanyName={companyName} // Pass initial company name
                                        initialCompanyDomain={companyDomain} // Pass initial company domain (logo URL)
                                        onCompanyChange={setCompanyName} // Update company name state
                                        onDomainChange={setCompanyDomain} // Update company domain (logo) state
                                    />
                                </div>
                                <div className="save-application-modal-group">
                                    <label className="add-application-label" htmlFor="position">
                                        Target Position <span style={{ color: '#B00000' }}>*</span>
                                    </label>
                                    <input
                                        value={jobPosition} 
                                        onChange={e => setJobPosition(e.target.value)}
                                        className={`add-application-input ${jobPosition.trim() === '' ? 'input-error' : ''}`}
                                    />
                                </div>
                                <div className="save-application-modal-button-container">
                                    <button className="save-resume-application-button-cancel" onClick={closeModal2}>
                                        Cancel
                                    </button>
                                    <button className="save-resume-application-button-save" onClick={() => sendToServer_db('/Dashboard')} disabled={isButtonDisabled}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
    
                    <div className="new-revision-contents" style={{ 
                        display: 'flex',
                        flex: 1,
                        overflow: 'hidden'  // Important: contains the scroll
                    }}>
                        <Navibar 
                            baseMenuItems={baseMenuItems}
                            isNavibarExpanded={isNavibarExpanded}
                            revisionItem={revisionItem}
                            handleAddItem={handleAddItem}
                            handleDeleteItem={handleDeleteItem}
                            experienceData={data}
                            setExperienceData={setData}
                            skillData={original_data}
                            setSkillData={setOriginalData}
                            jdKeywords={jd_keywords}
                            onKeywordClick={handleKeywordClick}
                            activeKeyword={activeKeyword}
                            openCard={openCard}
                            handleCardClick={handleCardClick}
                            writtingMode={location.state?.mode}
                            handleCardOpen={handleCardOpen}
                            navibarMode={navibarMode}
                            handleNavibarModeChange={handleNavibarModeChange}
                            isEducationFieldsFilled={isEducationFieldsFilled}
                            isExperienceFieldsFilled={isExperienceFieldsFilled}
                            isProjectFieldFilled={isProjectFieldFilled}
                            isPersonalInfoFilled={isPersonalInfoFilled}
                            selectedTemplate={selectedTemplate}
                            setSelectedTemplate={setSelectedTemplate}
                            setIsModal2Open={setIsModal2Open}
                            areRequiredFieldsFilled={areRequiredFieldsFilled}
                            handleClickStartNew={handleClickStartNew}
                        />
                        <NaviDetail 
                            cardRefs={cardRefs}
                            application_id={application_id}
                            menuItems={baseMenuItems} 
                            data={data}
                            setData={setData}
                            resetExpCopy={resetExpCopy}
                            original_data={original_data}
                            setOriginalData={setOriginalData}
                            executive_summary={executive_summary}
                            setExecutiveSummary={setExecutiveSummary}
                            selectedExperience={selectedExperience}
                            setSelectedExperience={setSelectedExperience}
                            coverletterApiResponse={coverletterApiResponse}
                            setCoverletterApiResponse={setCoverletterApiResponse}
                            emailSubject={emailSubject}
                            setEmailSubject={setEmailSubject}
                            followUpEmail={followUpEmail}
                            setFollowUpEmail={setFollowUpEmail}
                            jobPosition={jobPosition}
                            setJobPosition={setJobPosition}
                            companyName={companyName}
                            setCompanyName={setCompanyName}
                            companyDomain={companyDomain}
                            setCompanyDomain={setCompanyDomain}
                            showCategorySkills={showCategorySkills}
                            setShowCategorySkills={setShowCategorySkills}
                            categorizedSkills={categorizedSkills}
                            setCategorizedSkills={setCategorizedSkills}
                            selectedSection={selectedSection}
                            setSelectedSection={setSelectedSection}
                            loading={loading}
                            apiResponse={apiResponse}
                            setApiResponse={setApiResponse}
                            job_description={job_description}
                            company_analysis={company_analysis}
                            handleDeleteItem={handleDeleteItem}
                            activeKeyword={activeKeyword}
                            currentSection={currentSection}
                            setCurrentSection={setCurrentSection}
                            isResubotOpen={isResubotOpen}
                            setIsResubotOpen={setIsResubotOpen}
                            openCard={openCard}
                            handleCardClick={handleCardClick}
                            navibarMode={navibarMode}
                            isEducationFieldsFilled={isEducationFieldsFilled}
                            setIsEducationFieldsFilled={setIsEducationFieldsFilled}
                            isExperienceFieldsFilled={isExperienceFieldsFilled}
                            setIsExperienceFieldsFilled={setIsExperienceFieldsFilled}
                            isProjectFieldFilled={isProjectFieldFilled}
                            setIsProjectFieldFilled={setIsProjectFieldFilled}
                            isPersonalInfoFilled={isPersonalInfoFilled}
                            setIsPersonalInfoFilled={setIsPersonalInfoFilled}
                            selectedTemplate={selectedTemplate}
                            setSelectedTemplate={setSelectedTemplate}
                            showBoldKeywords={showBoldKeywords}
                            setShowBoldKeywords={setShowBoldKeywords}
                            jd_keywords={jd_keywords}
                            fontsize={fontsize}
                            setFontSize={setFontSize}
                            name_fontsize={name_fontsize}
                            setNameFontSize={setNameFontSize}
                            sectionSpacing={sectionSpacing}
                            setSectionSpacing={setSectionSpacing}
                            sectionBetweenLine={sectionBetweenLine}
                            setSectionBetweenLine={setSectionBetweenLine}
                            lineHeightSpacing={lineHeightSpacing}
                            setLineHeightSpacing={setLineHeightSpacing}
                            template_1_section_header={template_1_section_header}
                            setTemplate1SectionHeader={setTemplate1SectionHeader}
                            name_fontsize_template_3={name_fontsize_template_3}
                            setNameFontsizeTemplate3={setNameFontsizeTemplate3}
                            editorScrollPosition={editorScrollPosition}
                            setEditorScrollPosition={setEditorScrollPosition}
                            isRestoringFromResubot={isRestoringFromResubot}
                            setIsRestoringFromResubot={setIsRestoringFromResubot}
                            question={question}
                            setQuestion={setQuestion}
                            length={length}
                            setLength={setLength}
                            questionAnswer={questionAnswer}
                            setQuestionAnswer={setQuestionAnswer}
                            customRequirement={customRequirement}
                            setCustomRequirement={setCustomRequirement}
                            revisedFeedback={revisedFeedback}
                            setRevisedFeedback={setRevisedFeedback}
                            pageStatus={pageStatus}
                            setPageStatus={setPageStatus}
                            handleClickStartNew={handleClickStartNew}
                            sectionOrderTemplate1={sectionOrderTemplate1}
                            setSectionOrderTemplate1={setSectionOrderTemplate1}
                            sectionOrderTemplate2={sectionOrderTemplate2}
                            setSectionOrderTemplate2={setSectionOrderTemplate2}
                        />
                    </div>
                </div>
            )
        ) : (
            <div>Please log in first</div>
        )
    );
};

export default NewRevision;