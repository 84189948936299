import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Loading_partial } from '../Loading/Loading_partial';

const ApplicationSpecificQuestion = ({ data, original_data, isFieldEmpty, jobPosition, 
    companyName, job_description, application_id, handleDeleteItem, handleQuestionChange, handleLengthChange, question, length,
    questionAnswer, setQuestionAnswer, customRequirement, setCustomRequirement, revisedFeedback, setRevisedFeedback, pageStatus,
    setPageStatus, handleClickRegen, handleClickStartNew, handleClickReturn, questionAnswerLoading,
    setQuestionAnswerLoading, showQuestionAnswer, setShowQuestionAnswer}) => {

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    
    const areDefaultButtonDisable = () => {
        return !question || !length;
    };

    const areRegenButtonDisable = () => {
        return !question || !length || !revisedFeedback;
    };

    const extractData = (data) => {
        const workExperience = (data.rendering_data.work || []).map((work) => {
          const { Experience } = work;
          return {
            company: Experience?.Company || 'N/A',
            title: Experience?.Title || 'N/A',
            duration: Experience?.Duration || 'N/A',
            location: Experience?.Location || 'N/A',
            responsibilities: Experience?.Responsibilities || [],
          };
        });
      
        const projectExperience = (data.rendering_data.project || []).map((project) => {
          const { Experience } = project;
          return {
            company: Experience?.Company || 'N/A',
            title: Experience?.Title || 'N/A',
            duration: Experience?.Duration || 'N/A',
            location: Experience?.Location || 'N/A',
            responsibilities: Experience?.Responsibilities || [],
          };
        });
      
        return {
          work_experience: workExperience.length > 0 ? workExperience : null,
          project_experience: projectExperience.length > 0 ? projectExperience : null,
        };
    };

    const sendToServer = async (is_regenerate) => {
        console.log(is_regenerate)
        window.scrollTo({ top: 0, behavior: 'smooth' });
        //---------------------------------auhentication code----------------------------------------
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return
        }
        //---------------------------------auhentication code----------------------------------------
        // create a FormData object
        const formData = new FormData();
        // append the required data into the form
        formData.append('application_id', application_id)
        formData.append('first_name', original_data?.personal_info?.first_name || '');
        formData.append('last_name', original_data?.personal_info?.last_name || '');
        formData.append('email', original_data?.personal_info?.email || '');
        formData.append('phone_number', original_data?.personal_info?.phone_number || '');
        formData.append('personal_website_url', original_data?.personal_info?.personal_website_url || '');
        
        formData.append('is_regenerate', is_regenerate ? 1 : 0);
        formData.append('question', question);
        formData.append('words_limit', length);
        if (is_regenerate) {
            formData.append('customized_require', revisedFeedback);
            formData.append('latest_version', questionAnswer);
        } else {
            formData.append('customized_require', customRequirement);
        }

        formData.append('education_background', JSON.stringify(original_data?.education));

        formData.append('company_name', companyName);
        formData.append('position_title', jobPosition);

        formData.append('job_description', job_description);
        const resumeObj = extractData(data);
        resumeObj.skills = original_data.skills;
        formData.append('resume_obj', JSON.stringify(resumeObj));
    
        //start loading
        setQuestionAnswerLoading(true);
        // send a POST request
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        console.log('Send POST request to API')
        fetch('/resumasterapi/application-question-answer', { //api endpoint
          method: 'POST',
          //---------------------------------auhentication code----------------------------------------
          headers: {
            Authorization: `Bearer ${token}`
          },
          //---------------------------------auhentication code----------------------------------------
          body: formData
        }).then(response => {
          // Check for the 400 status code 
          // console.log('Server Response:', response);
          if (response.status === 400) {
            throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
          }
          if (response.status == 500) {
            throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
          }
          return response.json();
        }).then(data => {
          console.log(data);
          setQuestionAnswer(data.result.answer);
          setQuestionAnswerLoading(false);
          setShowQuestionAnswer(true);
        }).catch(error => {
          // handle the error
          console.error(error);
          // If there's an error, ensure loading is turned off
          setQuestionAnswerLoading(false);
          alert(error.message);  // Displaying the error message to the user
        });
    }

    const handleCopyToClipboard = async () => {
        try {
          await navigator.clipboard.writeText(questionAnswer);
        } catch (err) {
          console.error("Error copying text: ", err);
        }
    };

    return (
        <div className="CL-section-main-section">

            {questionAnswerLoading ? (
                <div style={{ textAlign: 'center', backgroundColor: '#FFFFFF'}}>
                    <Loading_partial />
                    <p style={{ marginTop: '10px', fontSize: '16px', fontWeight: 600, marginBottom: '20px'}}>This process will take about 20 seconds while we generate your cover letter...</p>
                </div>
            ) : showQuestionAnswer && pageStatus == 'result' ? (
                <div className="description">
                    <div className="img-box">
                        <button 
                        onClick={() => {
                            handleDeleteItem('Answer Application Questions');
                            handleClickStartNew();
                        }}
                        className="delete-button">
                        Delete
                        </button>
                    </div>
                    <label className="form-label">Question: {question}</label>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                        <label className="form-label" style={{marginTop: '20px'}}>Answer</label>
                        <img 
                            className="copy-img"
                            alt="Copy"
                            src="/img/coverletter_copy.png"
                            style={{
                                width: '24px',
                                height: '24px',
                                cursor: 'pointer',
                                marginRight: '10px'
                            }}
                            onClick={() => handleCopyToClipboard()}
                        />
                    </div>
                    <textarea 
                        className={`experience-form-input`}
                        value={questionAnswer || ''}
                        onChange={(e) => setQuestionAnswer(e.target.value)} 
                        style={{ height: '400px' }}
                    />

                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}>
                        <button className='coverletter-generate-button' onClick={handleClickRegen} style={{height: '46px'}}>
                            Regenerate
                        </button>
                        <button className='coverletter-generate-button' onClick={handleClickStartNew} style={{height: '46px'}}>
                            Start New
                        </button>
                    </div>
                </div>
            ) : (
                <>
                    {pageStatus == 'default' && (
                        <div className="description">
                            <label className="form-label">Question<span className="starColor">*</span></label>
                            <input 
                                type="text" 
                                className={`experience-form-input`}
                                value={question || ''} 
                                onChange={(e) => handleQuestionChange(e.target.value)} 
                            />
                            {(!question ||isFieldEmpty(question)) && (
                                <div className="hint-text">Hint: this content is required.</div>
                            )}

                            <label className="form-label" style={{marginTop: '20px'}}>Words Limit<span className="starColor">*</span></label>
                            <input 
                                type="text" 
                                className={`experience-form-input`}
                                value={length || ''} 
                                onChange={(e) => handleLengthChange(e.target.value)} 
                            />
                            {(!length ||isFieldEmpty(length)) && (
                                <div className="hint-text">Hint: this content is required.</div>
                            )}

                            <label className="form-label" style={{marginTop: '20px'}}>Custom Requirement</label>
                            <textarea 
                                className={`experience-form-input`}
                                value={customRequirement || ''}
                                onChange={(e) => setCustomRequirement(e.target.value)} 
                                style={{ height: '150px' }}
                            />

                            <button className='coverletter-generate-button' disabled={areDefaultButtonDisable()} 
                                onClick={() => {
                                    setPageStatus('result');
                                    sendToServer(0);
                                }} 
                                style={{marginTop: '20px'}}>
                                <img src="/img/ai-generation-button.png" alt="AI Icon" style={{width: '24px', height: '24px', marginRight: '8px', verticalAlign: 'middle'}}/>
                                Generate
                            </button>
                        </div>
                    )}
                    
                    {pageStatus == 'regenerate' && (
                        <div className="description">
                            <label className="form-label">Question<span className="starColor">*</span></label>
                            <input 
                                type="text" 
                                className={`experience-form-input`}
                                value={question || ''} 
                                onChange={(e) => handleQuestionChange(e.target.value)} 
                            />
                            {(!question ||isFieldEmpty(question)) && (
                                <div className="hint-text">Hint: this content is required.</div>
                            )}

                            <label className="form-label" style={{marginTop: '20px'}}>Length<span className="starColor">*</span></label>
                            <input 
                                type="text" 
                                className={`experience-form-input`}
                                value={length || ''} 
                                onChange={(e) => handleLengthChange(e.target.value)} 
                            />
                            {(!length ||isFieldEmpty(length)) && (
                                <div className="hint-text">Hint: this content is required.</div>
                            )}

                            <label className="form-label" style={{marginTop: '20px'}}>Revised Feedback<span className="starColor">*</span></label>
                            <textarea 
                                className={`experience-form-input`}
                                value={revisedFeedback || ''} 
                                onChange={(e) => setRevisedFeedback(e.target.value)} 
                                style={{ height: '100px' }}
                            />
                            {(!revisedFeedback ||isFieldEmpty(revisedFeedback)) && (
                                <div className="hint-text">Hint: this content is required.</div>
                            )}

                            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}>
                                <button className='coverletter-generate-button' onClick={handleClickReturn} style={{height: '46px'}}>
                                    Return
                                </button>
                                <button className='coverletter-generate-button' disabled={areRegenButtonDisable()} 
                                    onClick={() => {
                                        setPageStatus('result');
                                        sendToServer(1);
                                    }}>
                                    <img src="/img/ai-generation-button.png" alt="AI Icon" style={{width: '24px', height: '24px', marginRight: '8px', verticalAlign: 'middle'}}/>
                                    Regenerate
                                </button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ApplicationSpecificQuestion;