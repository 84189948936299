import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { loadStripe } from '@stripe/stripe-js';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Navigation } from "../../components/Navigation";
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe('pk_live_51PO28sJkrystcxfZxlVoyjBZ92nz228Kyl3OzVSASswwr5J1QhJ4GknfCuGUd6F7OTuWa24EuiZDvbHaU5RVeUnZ00CEvhpcxX');

export const PaymentPage = () => {
  const [clientSecret, setClientSecret] = useState('');
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently, isLoading } = useAuth0();
  const location = useLocation();

  const { planId } = useParams();  // This retrieves the 'planId' from the URL
  const [selectedPlan, setSelectedPlan] = useState({ name: '', price: '' });
  // const [transactionStatus, setTransactionStatus] = useState(null);

  // const handlePaymentFailure = () => {
  //       setTransactionStatus('failure');
  // };

  // const goToHomePage = () => {
  //   console.log("Redirect to Home Page");
  // };



  // list of prices
  const plans = [
    { id: 1, name: 'Monthly Subscription', price: '$17.99', digital_price:'1799' },
    { id: 2, name: '1000 ResuCredits', price: '$7.99', digital_price:'799' },
    { id: 3, name: '500 ResuCredits', price: '$4.99', digital_price:'499' },
    { id: 4, name: '100 ResuCredits', price: '$1.49', digital_price:'149' }
  ];

  const fetchClientSecret = async (amount) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('/resumasterapi/create-payment-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ amount })
      });
      const data = await response.json();
      setClientSecret(data.clientSecret);
    } catch (error) {
      console.error('There was a problem with fetching the client secret:', error);
    }
  };


  const handleCardChange = (plan) => {
    console.log("Plan selected:", plan.name);
    setSelectedPlan(plan);
    fetchClientSecret(plan.digital_price); // Update payment intent when plan changes
  };

  useEffect(() => {
    const plan = plans.find(p => p.id.toString() === planId);
    if (plan) {
      setSelectedPlan(plan);
      // Fetch client secret here if authenticated
      if (isAuthenticated) {
        fetchClientSecret(plan.digital_price);
      }
    }
  }, [planId, isAuthenticated]);

  
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading]);

  if (isLoading) {
    return <div>Authticating...</div>;
  }

  // Optionally, redirect or display a message if not authenticated
  // if (!isAuthenticated) return <Navigate to="/login" />; // or a loader, etc.

  const appearance = {
    theme: 'flat',
    variables: {
      fontSize: '14px',
      colorText: '#000',
    }
  };

  return (
    <div className='payment-container'>
      <Helmet>
      <title>Payment - ResuMaster</title>
      <meta property="og:title" content="Payment - ResuMaster" />
      </Helmet>
      {
      isAuthenticated ? (
        <Navigation_loggedin 
        isCollapsedMenu={false}
        className="navigation-2202" />
        ) : (
          <Navigation className="navigation-2202" />
        )
      }

      <div className="left-container">
        <div className="select-price-container">
          <div>
            <Link to="/plan" className="profile-back-button">
              <img className="back-button-img" src='/img/chevron-left.png' />
              <div className="back-button-text">Back</div>
            </Link>
          </div>
          
          <div className="content-title">
            Review your plan
          </div>
         <div>
          {console.log('Component rendering with selected plan:', selectedPlan)}
         </div>
          {plans.map(plan =>(
              <div className="price-card-container">
                <div key={plan.id} 
                    className={`price-card-unselected ${plan.id === selectedPlan?.id ? 'selected' : ''}`} 
                    onClick={() => handleCardChange(plan)}> 
                  <div className='payment-title'>{plan.name}</div> 
                  <div className="payment-price">{plan.price}</div>
                </div>
              </div>
          )
          )}
           
        </div>
      </div>


      <div className="right-container">
        <div className="payment-details-container">
          
          <div className="payment-content-title">Payment Details</div>
          <div className="payment-text">Order Summary</div>
          <div class="payment-text-small">
            <span>{selectedPlan.name}</span>
            <span class="price">{selectedPlan.price}</span>
          </div>
          <div class="divider"></div>
          <div class="payment-text-medium">
            <span>Order Total</span>
            <span class="price">{selectedPlan.price}</span>
          </div>
          <div>
            {clientSecret ? (
            <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
              <div className="checkout-container">
              <CheckoutForm />
                {/* {transactionStatus === 'failure' && (
                  <div className="error-message">
                    <p>There was an issue with your transaction: Please check your details and try again.</p>
                  </div>
                )}

                <CheckoutForm onPaymentFailure={handlePaymentFailure} />

                {transactionStatus === 'success' && (
                  <div className="modal-overlay">
                      <div className="modal">
                          <h2>Purchase Successful!</h2>
                          <button className="return-to-home" onClick={goToHomePage}>Go to Home Page</button>
                      </div>
                  </div>
                )} */}
              </div>
            </Elements>
            ) : (
            <div>Loading...</div>
            )}
          </div>
        </div>
      </div>

    </div>
      
    
  );
};
