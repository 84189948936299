/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
import { Loading_mainpage } from '../../screens/Loading';
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------

export const NavigationLoggedinEditor = ({ className, onLinkClick, isCollapsedMenu }) => {
    //---------------------------------auhentication code----------------------------------------
    /*============================== temporary comment out for deployment 06/09===================================*/
    useEffect(() => {
      sendToServer2();
    }, []);
    /*============================== temporary comment out for deployment 06/09===================================*/
    //auth0 component
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    //---------------------------------auhentication code----------------------------------------
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // state for loading
    const [credits, setCredits] = useState(""); // Initialize credits state
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024);

    useEffect(() => {
        const handleResize = () => {
          setIsMobileView(window.innerWidth <= 1024);
        };
    
        // Add event listener
        window.addEventListener('resize', handleResize);
    
        // Cleanup function
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (loading) {    
      return <Loading_mainpage />;
    }
    
    const sendToServer2 = async () => {
      //---------------------------------auhentication code----------------------------------------
      if (!isAuthenticated) {
          alert("Login required: Please login first");
          return
      }
      //---------------------------------auhentication code----------------------------------------
      //start loading
      //setLoading(true);
      // send a POST request
      console.log('Send POST request to API')
      //---------------------------------auhentication code----------------------------------------
      //get user's specific token
      const token = await getAccessTokenSilently();
      //---------------------------------auhentication code----------------------------------------
      fetch('/resumasterapi/check_user_status', { //api endpoint
        method: 'POST',
        //---------------------------------auhentication code----------------------------------------
        headers: {
          Authorization: `Bearer ${token}`
        },
        //---------------------------------auhentication code----------------------------------------
      }).then(response => {
        // Check for the 400 status code 
        // console.log('Server Response:', response);
        if (response.status === 400) {
          throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
        }
        if (response.status == 500) {
          throw new Error('Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
        }
        //---------------------------------auhentication code----------------------------------------
        if (response.status == 401) {
          throw new Error('Sorry, you must login first')
        }
        //---------------------------------auhentication code----------------------------------------
        return response.json();
      }).then(data => {
        if (data.is_exist == true) {
          if (data.in_subscription === true){
              setCredits("Unlimited");
          // console.log(data.in_subscription);
              //console.log(credits);
          }
          else { 
              setCredits(data.current_credits);
              //console.log(data.current_credits);
          }
        } else {
          setCredits("Unavailable");
        }
        setLoading(false);
        //history.push("/result");
      }).catch(error => {
        // handle the error
        console.error(error);
        // If there's an error, ensure loading is turned off
        setLoading(false);
        alert(error.message);  // Displaying the error message to the user
      });
    }

    const handleClick = (event, destination) => {
        event.preventDefault(); // Prevent the default navigation
        onLinkClick(destination); // Pass the destination to the parent for confirmation
    };

    return (
        <div className={`navigation ${className}`}>
            {isMobileView ? (
              !isCollapsedMenu ? (
                <Link to="/" onClick={(e) => handleClick(e, '/')}>
                  <img
                    className="logo-nmv"
                    alt="logo navigation mobile view"
                    src="/img/logo-purple.png"
                  />
                </Link>
              ) : (
                <img
                  className="logo-nmv"
                  alt="Futuristic modern"
                  src="/img/Vector.png"
                  onClick={handleSliderVisible}
                />
              )
            ) : (
              <Link to="/" onClick={(e) => handleClick(e, '/')}>
                <img
                  className="futuristic-modern"
                  alt="Futuristic modern"
                  src="/img/nav-logo-new.png"
                />
              </Link>
            )}

            <div className="navigation-buttons">
                <Link className="pricing-tab" to="/plan" onClick={(e) => handleClick(e, '/plan')}>
                    <div className="button-secondary-text">Pricing</div>
                </Link>

                <a className="contact-us" href="/contact" onClick={(e) => handleClick(e, '/contact')}>
                    <div className="button-secondary-text">Contact Us</div>
                </a>

                <a className="credit-info" href="/credits" onClick={(e) => handleClick(e, '/credits')}>
                    <img className="wallet-icon" src="/img/material-symbols_wallet.png"/>
                    <span className="button-secondary-text">ResuCredits: {credits}</span>
                </a>

                <a className="button-discord" href="/profile" onClick={(e) => handleClick(e, '/profile')}>
                    <img className="profile-icon" src="/img/healthicons-ui-user-profile.png" />
                </a>
            </div>
        </div>
    );
    };
