/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { useAuth0 } from "@auth0/auth0-react"; // <-- Import useAuth0 hook

export const Navigation = ({ className }) => {
  //---------------------------------auhentication code----------------------------------------
  const { loginWithRedirect } = useAuth0();
  //---------------------------------auhentication code----------------------------------------
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 480);
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobileView ? (
    <div className={`navigation ${className}`}>
      <Link to="/">
        <img
          className="logo-nmv"
          alt="logo navigation mobile view"
          src="/img/logo-purple.png"
        />
      </Link>
      <div className="navigation-buttons-nmv">
        <button className="login-button-nmv" onClick={loginWithRedirect}>
          <div className="login-button-text-nmv">Login</div>
        </button>
        <a href="/contact">
          <img
            className="logo-nmv"
            alt="logo navigation mobile view"
            src="/img/contact-us.png"
          />
        </a>
      </div>
    </div>
  ) : (
    <div className={`navigation ${className}`}>
      <Link to="/">
        <img
          className="futuristic-modern"
          alt="Futuristic modern"
          src="/img/nav-logo-new.png"
        />
      </Link>
      <div className="navigation-buttons">
        <a className="contact-us" href="/contact">
          <div className="button-secondary-text">Contact Us</div>
        </a>
        <a className="about" href="/aboutus">
          <div className="button-secondary-text">About Us</div>
        </a>
        <a className="button-discord" href="https://discord.gg/YURMHY9JNt">
          <img className="profile-icon" src="/img/ic_baseline-discord.png" alt="Discord Community"/>
        </a>
        <a className="button-discord" href="https://reddit.com/r/ResuMaster">
          <img className="discord-icon" src="/img/reddit_icon_purple.png" />
        </a>
        <button className="button-primary" onClick={loginWithRedirect}>
          <div className="button-primary-text">Login</div>
        </button>
      </div>
    </div>
  );
};
