import React, { useCallback, useState } from 'react'
import { PDFDocument } from 'pdf-lib'
import { useDropzone } from 'react-dropzone'
import { Navigation } from "../../components/Navigation";
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useEffect } from 'react';
import { Button } from '../../components/Button';
import { Helmet } from 'react-helmet'
import { Loading_dashboard } from '../Loading';
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------
import './style_upload.css'

export const UploadNewText = (props) => {
    //---------------------------------auhentication code----------------------------------------
  //auth0 component
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  //---------------------------------auhentication code----------------------------------------
  if (!isAuthenticated) {
        return <div>Please log in first to start revise your resume.</div>;
  }
  const { pathname } = useLocation(); // destructuring the pathname from the useLocation hook
  const navigate = useNavigate();

  if (isAuthenticated && !user.email_verified) {
    return <div>Sorry, you need to verify your email first</div>;  // Display a message or loading animation here
  }

  useEffect(() => {
    window.scrollTo(0, 0); // this will scroll the page to the top every time pathname changes
  }, [pathname]);

  /*============================== temporary comment out for deployment 06/09===================================*/
  useEffect(() => {
    fetchUserBalance();
  }, []);
  /*============================== temporary comment out for deployment 06/09===================================*/

  const [resumeText, setResumeText] = useState(""); // state for uploaded file
  const [loading, setLoading] = useState(false); // state for loading
  // const history = useHistory(); // react-router history hook
  const [apiResponse, setApiResponse] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [enoughBalance, setEnoughBalance] = useState(false);
  
  const fetchUserBalance = async () => {
    // send a POST request
    console.log('Send POST request to API')
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    fetch('/resumasterapi/check_user_status', { //api endpoint
      method: 'POST',
      //---------------------------------auhentication code----------------------------------------
      headers: {
        Authorization: `Bearer ${token}`
      },
    //---------------------------------auhentication code----------------------------------------
    }).then(response => {
      // Check for the 400 status code 
      // console.log('Server Response:', response);
      if (response.status === 400) {
        throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
      }
      if (response.status == 500) {
        throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
      }
      //---------------------------------auhentication code----------------------------------------
      if (response.status == 401) {
        throw new Error('Sorry, you must login first to upload your resume')
      }
      //---------------------------------auhentication code----------------------------------------
      return response.json();
    }).then(data => {
      console.log(data.in_subscription);
      console.log(data.current_credits);
      if (data.in_subscription === true){
        setEnoughBalance(true);
      }
      if (data.current_credits >= 100){ 
        setEnoughBalance(true);
      }
      //setLoading(false);
    }).catch(error => {
      // handle the error
      console.error("fetch user credits failed:", error);
      // If there's an error, ensure loading is turned off
      // Check if the error is due to a JSON parsing issue
      if (error.message.includes('Unexpected token')) {
        console.error('Received non-JSON response:', error);
        // You might want to set a different state here to handle this specific error
    }
      //setLoading(false);
      alert(error.message);  // Displaying the error message to the user
    });
  }

  //this useEffect code serves to enable the upload button after the user upload both resume and text
  useEffect(() => {
    /*============================== temporary comment out for deployment 06/09===================================*/
    if (resumeText.trim() !== '') {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
    /*============================== temporary comment out for deployment 06/09===================================*/
  }, [resumeText]);

  const sendToServer = async () => {
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
        alert("Login required: Please login first");
        return
      }
    //---------------------------------auhentication code----------------------------------------
    //check whether there is the upload file
    if (resumeText.trim() === '') {
      alert('Please paste in your resume before proceeding.');
      return;
    }
    // create a FormData object
    const formData = new FormData();
    // append the file and the job description
    formData.append('resume', resumeText);
    //start loading
    setLoading(true);
    // send a POST request
    console.log('Send POST request to API')
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    fetch('/resumasterapi/upload_resume_dashboard_text', { //api endpoint
      method: 'POST',
      //---------------------------------auhentication code----------------------------------------
      headers: {
        Authorization: `Bearer ${token}`
      },
      //---------------------------------auhentication code----------------------------------------
      body: formData
    }).then(response => {
      // Check for the 400 status code 
      // console.log('Server Response:', response);
      if (response.status === 400) {
        throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
      }
      if (response.status == 500) {
        throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
      }
      return response.json();
    }).then(data => {
      setApiResponse(data);
      setLoading(false);
    }).catch(error => {
      // handle the error
      console.error(error);
      // If there's an error, ensure loading is turned off
      setLoading(false);
      alert(error.message);  // Displaying the error message to the user
    });
  }

  if (loading) {
    return <Loading_dashboard />;
  } else if (apiResponse) {
    let currentResumeNameVar = "My Resume";
    navigate('/dashboardEditor', { state: { data: apiResponse, 
                                            selectedExperienceIndex: 0,
                                            work_exp_length: apiResponse.work_experience ? apiResponse.work_experience.length : 0,
                                            original_work_exp_length: apiResponse.work_experience ? apiResponse.work_experience.length : 0,
                                            currentResumeName: currentResumeNameVar
                                         } });
    console.log(apiResponse);
  }

  const handleCancel = () => {
    navigate('/Dashboard');
  }
    return (

        <div className="upload-container">
            {/* <Navigation className="navigation-2202" /> */}
            <Helmet>
                {/* <!-- Google tag (gtag.js) --> */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-F812QPTDD9"></script>
                <script>
                    {`window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-F812QPTDD9');
                        `}
                </script>
                <title>Upload - ResuMaster</title>
                <meta property="og:title" content="Upload - ResuMaster" />
            </Helmet>
            <header data-role="Header" className="upload-header">
            </header>
            {
                isAuthenticated ? (
                    <Navigation_loggedin 
                    isCollapsedMenu={false}
                    className="navigation-2202" />
                ) : (
                    <Navigation className="navigation-2202" />
                )
            }
            <div className="dashboard-upload">
            <button className='dashboard-return-button' onClick={handleCancel}>
                <img src="/img/chevron-left.png" alt="icon" className="dashboard-button-icon" />
                Cancel
            </button>
            <div className="div-2 upload-box">
                <div className="title-3">Paste Your Resume Here<span style={{ color: '#B00000' }}>*</span></div>
                
                <textarea
                value={resumeText}
                placeholder='For best parsing performance, please include you role, company name and description for each experience'
                onChange={e => setResumeText(e.target.value)}
                className="rectangle"
                ></textarea>

                <div className="switch-container">
                    <Link className="switch-text" to="/uploadResume">OR Upload your resume</Link>
                </div>

            </div>

                    <div className="button-upload-section">
                        <div className="frame-10">
                            <Button
                                className="dashboard-upload-button"
                                onClick={sendToServer}
                                disabled={isButtonDisabled}
                                text="Next"
                            />
                        </div>
                    </div>
                    {/*============================== temporary comment out for deployment 06/09===================================*/}
                    {/* {enoughBalance ? (
                        <div className="sufficient-balance">
                            By clicking Next, 100 ResuCredits will be deducted from your account
                        </div>
                    ) : (
                        <div className="insufficient-balance">
                            <Link to='/plan'>
                                You do not have enough ResuCredits, please purchase here
                            </Link>
                        </div>
                    )
                    } */}
                    {/*============================== temporary comment out for deployment 06/09===================================*/}
            </div>
        </div>

    )
}
