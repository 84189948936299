import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useAuth0 } from "@auth0/auth0-react";  // <-- Import useAuth0 hook

const CustomFilter = ({filterInfo, handleFilterInfo, searchInput, setSubmittedSearch}) => {
    const { getAccessTokenSilently, isAuthenticated, loginWithRedirect, logout, user, isLoading } = useAuth0();
    const [isOpen, setIsOpen] = useState(false);

    const formatDateToYMD = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [tempDateRange, setTempDateRange] = useState(() => {
        const currentDate = new Date();
        currentDate.setHours(23, 59, 59);
        const thirtyDaysAgo = new Date(currentDate);
        thirtyDaysAgo.setDate(currentDate.getDate() - 30);
        return {
            from: thirtyDaysAgo,
            to: currentDate
        };
    });

    const [originalRange, setOriginalRange] = useState({
        from: new Date(),
        to: new Date(),
    });

    const [dateRange, setDateRange] = useState(() => ({
        from: formatDateToYMD(tempDateRange.from),
        to: formatDateToYMD(tempDateRange.to)
    }));

    const [statuses, setStatuses] = useState({
        applied: true,
        screen: true,
        interview: true,
        offer: true,
        rejected: true
    });

    const [dateRangeAfterSave, setDateRangeAfterSave] = useState(() => {
        const currentDate = new Date();
        currentDate.setHours(23, 59, 59);
        const thirtyDaysAgo = new Date(currentDate);
        thirtyDaysAgo.setDate(currentDate.getDate() - 30);
        return {
            from: thirtyDaysAgo,
            to: currentDate
        };
    });

    const [statusesAfterSave, setStatusesAfterSave] = useState({
        applied: true,
        screen: true,
        interview: true,
        offer: true,
        rejected: true
    });

    const fetch_earliest_application_date = async () => {
        //---------------------------------auhentication code----------------------------------------
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return
          }
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/fetch_earliest_application_date', { //api endpoint
            method: 'GET',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then(response => {
            if (response.status !== 200) {
                throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            return response.json();
        }).then(data => {
            console.log(formatDateToYMD(convertUTCToLocalObject(data.date)));
            setTempDateRange(prev => ({ ...prev, from: convertUTCToLocalObject(data.date) }));
            setOriginalRange(prev => ({ ...prev, from: convertUTCToLocalObject(data.date) }));
            setDateRangeAfterSave(prev => ({ ...prev, from: convertUTCToLocalObject(data.date) }));
        }).catch(error => {
            console.error(error);
            alert(error.message);  // Displaying the error message to the user
        });
    }

    useEffect(() => {
        fetch_earliest_application_date();
    }, [isAuthenticated]);
    
    const convertUTCToLocalObject = (utcString) => {
        return new Date(utcString);
    };

    const formatDateTimeToUTC = (date) => {
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    useEffect(() => {
        setDateRange({
            from: formatDateToYMD(tempDateRange.from),
            to: formatDateToYMD(tempDateRange.to)
        });
    }, [tempDateRange]);

    // const handleDateChange = (field, value) => {
    //     const currentDate = new Date(tempDateRange[field]);
    //     const [year, month, day] = value.split('-').map(Number);
    //     currentDate.setFullYear(year);
    //     currentDate.setMonth(month - 1); // JS中的月份是从0开始的，所以要减1
    //     currentDate.setDate(day);
    //     const now = new Date();
    //     currentDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());

    //     setTempDateRange(prev => ({ ...prev, [field]: currentDate }));
    // };
    const handleFromDateChange = (field, value) => {
        const currentDate = new Date(tempDateRange[field]);
        const [year, month, day] = value.split('-').map(Number);
        currentDate.setFullYear(year);
        currentDate.setMonth(month - 1); // JS中的月份是从0开始的，所以要减1
        currentDate.setDate(day);
        currentDate.setHours(0, 0, 0);

        setTempDateRange(prev => ({ ...prev, [field]: currentDate }));
    };

    const handleToDateChange = (field, value) => {
        const currentDate = new Date(tempDateRange[field]);
        const [year, month, day] = value.split('-').map(Number);
        currentDate.setFullYear(year);
        currentDate.setMonth(month - 1); // JS中的月份是从0开始的，所以要减1
        currentDate.setDate(day);
        currentDate.setHours(23, 59, 59);

        setTempDateRange(prev => ({ ...prev, [field]: currentDate }));
    };

    const handleSubmit = () => {
        const activeStatuses = Object.entries(statuses)
            .filter(([_, value]) => value)
            .map(([key, _]) => key);
    
        // 转换为 UTC
        const fromFormatted = formatDateTimeToUTC(tempDateRange.from);
        const toFormatted = formatDateTimeToUTC(tempDateRange.to);
    
        // 更新 filterInfo
        handleFilterInfo('start_date', fromFormatted);
        handleFilterInfo('end_date', toFormatted);
    
        // 更新每个状态
        Object.keys(statuses).forEach(status => {
            handleFilterInfo(status, statuses[status]);
        });

        setDateRangeAfterSave({
            from: tempDateRange.from,
            to: tempDateRange.to
        });
    
        // 保存当前的 statuses 到 statusesAfterSave
        setStatusesAfterSave({...statuses});

        setSubmittedSearch(searchInput.trim());
    
        const message = `Date Range: ${fromFormatted} to ${toFormatted}\nActive Statuses: ${activeStatuses.join(', ')}`;
        // alert(message);
    
        // 关闭过滤器下拉框
        setIsOpen(false);
    };

    const resetFilterValues = useCallback(() => {
        setTempDateRange({
            from: dateRangeAfterSave.from,
            to: dateRangeAfterSave.to
        });
        setStatuses({...statusesAfterSave});
    }, [dateRangeAfterSave, statusesAfterSave]);

    const dropdownRef = useRef(null);

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //             setIsOpen(false);
    //         }
    //     };

    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
                resetFilterValues();
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [resetFilterValues]); // 添加依赖

    const handleStatusChange = (status) => {
        setStatuses(prev => ({ ...prev, [status]: !prev[status] }));
    };

    const handleRestDate = () => {
        setTempDateRange(prev => ({ ...prev, from: originalRange.from }));
        setTempDateRange(prev => ({ ...prev, to: originalRange.to }));
    };

    // styles
    const statusColors = {
        applied: '#6750A4',
        screen: '#D29C27',
        interview: '#497DA1',
        offer: '#53765C',
        rejected: '#AAAAAA'
    };

    const statusBackgroundColors = {
        applied: '#E9E3FB',
        screen: '#F9F3E6',
        interview: '#E3EEFB',
        offer: '#DEECDC',
        rejected: '#E5E5EA'
    }

    const checkboxStyle = {
        position: 'relative',
        cursor: 'pointer',
        fontSize: '14px',
        fontFamily: 'Open Sans',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '12px'
    };

    const hiddenCheckboxStyle = {
        opacity: 0,
        position: 'absolute'
    };

    const customCheckboxStyle = (checked) => ({
        width: '18px',
        height: '18px',
        marginRight: '4px',
        border: '2px solid #6750A4',
        borderRadius: '4px',
        backgroundColor: checked ? '#6750A4' : 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    });

    const checkmarkStyle = {
        color: 'white',
        fontSize: '14px'
    };

    const statusItemStyle = (color) => ({
        display: 'flex',
        alignItems: 'center',
        backgroundColor: `${statusBackgroundColors[color]}`,
        borderRadius: '16px',
        padding: '4px 8px',
        marginLeft: '4px'
    });

    const dotStyle = (color) => ({
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: color,
        marginRight: '6px'
    });

    const submitButtonStyle = {
        width: '100%',
        padding: '8px',
        backgroundColor: '#6750A4',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: '600',
        marginTop: '16px'
    };

    return (
        <div ref={dropdownRef} className="myapplication-custom-filter" style={{
            position: 'relative', display: 'inline-block', fontFamily: 'Arial, sans-serif', fontSize: '14px'}}>
            <div className="myapplication-filter-container" onClick={() => setIsOpen(!isOpen)}>
                <img src="/img/filter.png" alt="filter icon" width="16" height="16"/>
                <span style={{color: "#AAAAAA", fontFamily: 'Open Sans', fontWeight: '600', fontSize: '14px', marginLeft: '6px'}}>
                    Filter
                </span>
            </div>
            {isOpen && (
                <div style={{position: 'absolute', top: '100%', left: '10%', right: 0, marginTop: '4px', background: 'white',
                    border: '1px solid #ccc', borderRadius: '4px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', zIndex: 1000, width: '300px', padding: '16px'}}>
                    <div style={{marginBottom: '8px'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px'}}>
                            <span style={{fontWeight: 'bold', fontFamily: 'Open Sans', fontSize: '14px'}}>Applied Date</span>
                            <button onClick={() => handleRestDate()} style={{color: '#AAAAAA', border: 'none', background: 'none', cursor: 'pointer', fontSize: '12px', fontFamily: 'Open Sans', textDecoration: 'Underline'}}>Reset</button>
                        </div>
                        <div style={{display: 'flex', gap: '8px'}}>
                            <div style={{flex: 1}}>
                                <label style={{display: 'block', marginBottom: '4px', color: '#AAAAAA', fontSize: '12px', fontFamily: 'Open Sans'}}>From:</label>
                                <input
                                    type="date"
                                    value={dateRange.from}
                                    onChange={(e) => handleFromDateChange('from', e.target.value)}
                                    style={{width: '100%', padding: '4px', border: '1px solid #ccc', borderRadius: '4px', cursor: 'pointer'}}
                                    onKeyDown={(e) => e.preventDefault()} // Disable keyboard input
                                    onClick={(e) => e.target.showPicker()} // Show the calendar picker on click
                                />
                            </div>
                            <div style={{flex: 1}}>
                                <label style={{display: 'block', marginBottom: '4px', color: '#AAAAAA', fontSize: '12px', fontFamily: 'Open Sans'}}>To:</label>
                                <input
                                    type="date"
                                    value={dateRange.to}
                                    onChange={(e) => handleToDateChange('to', e.target.value)}
                                    style={{width: '100%', padding: '4px', border: '1px solid #ccc', borderRadius: '4px', cursor: 'pointer'}}
                                    onKeyDown={(e) => e.preventDefault()} // Disable keyboard input
                                    onClick={(e) => e.target.showPicker()} // Show the calendar picker on click
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{borderTop: '1px solid #e0e0e0', marginBottom: '8px'}}></div>
                    <div>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px'}}>
                            <span style={{fontWeight: 'bold', fontFamily: 'Open Sans', fontSize: '14px'}}>Status</span>
                            <button 
                                onClick={() => setStatuses({ applied: true, screen: true, interview: true, offer: true, rejected: true })} 
                                style={{color: '#AAAAAA', border: 'none', background: 'none', cursor: 'pointer', fontSize: '12px', fontFamily: 'Open Sans', textDecoration: 'Underline',}}>
                                Reset
                            </button>
                        </div>
                        
                        {Object.entries(statuses).map(([status, checked]) => (
                            <label key={status} style={checkboxStyle}>
                                <input
                                    type="checkbox"
                                    checked={checked}
                                    onChange={() => handleStatusChange(status)}
                                    style={hiddenCheckboxStyle}
                                />
                                <div style={customCheckboxStyle(checked)}>
                                    {checked && <span style={checkmarkStyle}>✓</span>}
                                </div>
                                <div style={statusItemStyle(status)}>
                                    <div style={dotStyle(statusColors[status])}></div>
                                    <span style={{textTransform: 'capitalize'}}>{status}</span>
                                </div>
                            </label>
                        ))}
                    </div>
                    <div>
                        <button onClick={handleSubmit} style={submitButtonStyle}>Save</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomFilter;