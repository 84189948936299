import React from 'react';
import PropTypes from 'prop-types';

export const ModalCloseEditor = ({ isOpen, handleYesModal, closeModal }) => {
    if (!isOpen) return null; // Do not render the modal if it's not open

    return (
        <div className="save-application-resume-modal-profile">
                    <div className="save-application-modal-content">
                        <div style={{ marginBottom: '30px', fontSize: '20px',fontWeight: '700' }}>Exit Revision Without Saving?</div>
                        <div style={{fontWeight: '400', fontSize: '16px', lineHeight: '21.79px', marginBottom: '10px'}}>To save your current changes, click "Save to Applications & Exit" and then save it to your application.</div>
                        <div className="save-application-modal-button-container">
                            <button className="save-resume-application-button-cancel" onClick={handleYesModal}>
                                Exit
                            </button>
                            <button className="save-resume-application-button-save" onClick={closeModal}>
                                Back to edit
                            </button>
                        </div>
                    </div>
                </div>
    );
};