import DetailInfo from '../NewRevision/DetailInfo';
import React, { useState, useRef, useEffect, useCallback, createRef } from 'react';
import Education from "./Education";
import './NaviDetail.css';
import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Loading_partial } from '../Loading/Loading_partial';
import CoverLetter from "./CoverLetter";
import FollowUpGenerator from "./FollowUpGen";
import ApplicationSpecificQuestion from "./ApplicationSpecificQuestion";
import { FrameWrapper } from "../../components/FrameWrapper";
import { useDrag, useDrop } from 'react-dnd';
import PdfViewer from "./PdfViewer";
import pluralize from 'pluralize';

const DraggableKeyword = ({ 
    keyword, 
    index, 
    moveKeyword, 
    handleDragEnd, 
    onDelete, 
    checkMatch, 
    highlightText, 
    activeKeyword 
}) => {
    const ref = useRef(null);

    const [{ isDragging }, drag] = useDrag({
        type: 'keyword',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (monitor.didDrop()) {
                handleDragEnd();
            }
        }
    });

    const [{ isOver, draggedIndex }, drop] = useDrop({
        accept: 'keyword',
        hover: (item, monitor) => {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Get rectangle on screen
            const hoverBoundingRect = ref.current.getBoundingClientRect();

            // Get horizontal middle
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

            // Get mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the left
            const hoverClientX = clientOffset.x - hoverBoundingRect.left;

            // When dragging right, only move when cursor is beyond 50%
            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            // When dragging left, only move when cursor is before 50%
            if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
                return;
            }

            moveKeyword(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            draggedIndex: monitor.getItem()?.index
        })
    });

    // Only show preview styles when this is the dragged item
    const showPreview = draggedIndex === index;

    drag(drop(ref));

    return (
        <div 
            ref={ref} 
            style={{ 
                opacity: isDragging ? 0.5 : 1,
                cursor: 'move',
                border: showPreview ? '2px dashed #381E72' : 'none',
                borderRadius: '20px',
                background: showPreview ? '#F8F5FF' : 'transparent',
                display: 'inline-block'
            }}
        >
            <FrameWrapper 
                className={`skill-frame ${
                    activeKeyword && checkMatch(keyword, activeKeyword)
                        ? 'highlight-keyword' 
                        : ''
                }`}
                text={activeKeyword ? highlightText(keyword) : keyword}
                is_skills_list={true}
                onDelete={() => onDelete(index)}
            />
        </div>
    );
};

const DraggableCategoryKeyword = ({ 
    keyword, 
    index,
    category,
    moveKeyword, 
    handleDragEnd, 
    onDelete, 
    checkMatch, 
    highlightText, 
    activeKeyword 
}) => {
    const ref = useRef(null);

    const [{ isDragging }, drag] = useDrag({
        type: `keyword-${category}`,
        item: { index, category }, // Add category to the dragged item
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            if (monitor.didDrop()) {
                handleDragEnd();
            }
        }
    });

    const [{ isOver, draggedIndex, draggedCategory }, drop] = useDrop({
        accept: `keyword-${category}`,
        hover: (item, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Get rectangle on screen
            const hoverBoundingRect = ref.current.getBoundingClientRect();

            // Get horizontal middle
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

            // Get mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the left
            const hoverClientX = clientOffset.x - hoverBoundingRect.left;

            // When dragging right, only move when cursor is beyond 50%
            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            // When dragging left, only move when cursor is before 50%
            if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
                return;
            }

            moveKeyword(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            draggedIndex: monitor.getItem()?.index,
            draggedCategory: monitor.getItem()?.category
        })
    });

    // Only show preview styles when this is the dragged item AND from the same category
    const showPreview = draggedIndex === index && draggedCategory === category;

    drag(drop(ref));

    return (
        <div 
            ref={ref} 
            style={{ 
                opacity: isDragging ? 0.5 : 1,
                cursor: 'move',
                border: showPreview ? '2px dashed #381E72' : 'none',
                borderRadius: '20px',
                background: showPreview ? '#F8F5FF' : 'transparent',
                display: 'inline-block'
            }}
        >
            <FrameWrapper 
                className={`skill-frame ${
                    activeKeyword && checkMatch(keyword, activeKeyword)
                        ? 'highlight-keyword' 
                        : ''
                }`}
                text={activeKeyword ? highlightText(keyword) : keyword}
                is_skills_list={true}
                onDelete={() => onDelete(index)}
            />
        </div>
    );
};

const NaviDetail = ({ menuItems, data, setData, resetExpCopy, original_data, setOriginalData, executive_summary, setExecutiveSummary,
    selectedExperience, setSelectedExperience, coverletterApiResponse, setCoverletterApiResponse, emailSubject, setEmailSubject, 
    followUpEmail, setFollowUpEmail, jobPosition, setJobPosition, 
    companyName, setCompanyName, companyDomain, setCompanyDomain, showCategorySkills, setShowCategorySkills, 
    categorizedSkills, setCategorizedSkills, selectedSection, setSelectedSection, loading, apiResponse, setApiResponse,
    job_description, company_analysis, handleDeleteItem, activeKeyword, currentSection, setCurrentSection, 
    isResubotOpen, setIsResubotOpen, openCard, handleCardClick, navibarMode, isEducationFieldsFilled, setIsEducationFieldsFilled,
    isExperienceFieldsFilled, setIsExperienceFieldsFilled, isProjectFieldFilled, setIsProjectFieldFilled, isPersonalInfoFilled,
    setIsPersonalInfoFilled, selectedTemplate, setSelectedTemplate, showBoldKeywords, setShowBoldKeywords, jd_keywords, fontsize,
    setFontSize, name_fontsize, setNameFontSize, sectionSpacing, setSectionSpacing, sectionBetweenLine, setSectionBetweenLine,
    lineHeightSpacing, setLineHeightSpacing, template_1_section_header, setTemplate1SectionHeader, name_fontsize_template_3, 
    setNameFontsizeTemplate3, application_id, cardRefs,editorScrollPosition, setEditorScrollPosition, isRestoringFromResubot,
    setIsRestoringFromResubot, question, setQuestion, length, setLength, questionAnswer, setQuestionAnswer, customRequirement,
    setCustomRequirement, revisedFeedback, setRevisedFeedback, pageStatus, setPageStatus, handleClickStartNew, sectionOrderTemplate1,
    setSectionOrderTemplate1, sectionOrderTemplate2, setSectionOrderTemplate2
}) => {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    //get the data from the suggested_edits page (the data required to render this page)
    const [originalExpData, setOriginalExpData] = useState(null);
    const [selectedProject, setSelectedProject] = useState(0);
    const [coverletterLoading, setCoverletterLoading] = useState(false);
    const [showCoverLetter, setShowCoverLetter] = useState(coverletterApiResponse !== null);
    const [isEditSkills, setIsEditSkills] = useState(false);
    const [isEditCoverLetter, setIsEditCoverLetter] = useState(false);
    const [isHoverCoverLetter, setIsHoverCoverLetter] = useState(false);
    const [localSkills, setLocalSkills] = useState(original_data.skills);
    const [localCategorizedSkills, setLocalCategorizedSkills] = useState({});
    const containerRef = useRef(null);
    const wrapperRef = useRef(null);
    const [lastMode, setLastMode] = useState(navibarMode);
    const resubotRestorationTimeoutRef = useRef(null);
    const [followUpEmailLoading, setFollowUpEmailLoading] = useState(false);
    const [showFollowUpEmail, setShowFollowUpEmail] = useState(followUpEmail !== null);
    const [showQuestionAnswer, setShowQuestionAnswer] = useState(questionAnswer !== null);
    const [questionAnswerLoading, setQuestionAnswerLoading] = useState(false);

     // Modified scroll handling for editor mode
     useEffect(() => {
        const handleScroll = () => {
            if (wrapperRef.current && navibarMode === 'editor' && !isRestoringFromResubot) {
                setEditorScrollPosition(wrapperRef.current.scrollTop);
            }
        };

        const currentWrapper = wrapperRef.current;
        if (currentWrapper) {
            currentWrapper.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentWrapper) {
                currentWrapper.removeEventListener('scroll', handleScroll);
            }
        };
    }, [navibarMode, setEditorScrollPosition, isRestoringFromResubot]);

    // Modified preview/editor mode switch handling
    useEffect(() => {
        if (navibarMode !== lastMode) {
            const applyScroll = () => {
                if (wrapperRef.current) {
                    const position = navibarMode === 'editor' ? editorScrollPosition : 0;
                    wrapperRef.current.scrollTop = position;
                }
            };

            requestAnimationFrame(() => {
                applyScroll();
                setTimeout(applyScroll, 100);
            });

            setLastMode(navibarMode);
        }
    }, [navibarMode, lastMode, editorScrollPosition]);

    // Modified Resubot restoration handling
    useEffect(() => {
        if (isRestoringFromResubot && wrapperRef.current) {
            if (resubotRestorationTimeoutRef.current) {
                clearTimeout(resubotRestorationTimeoutRef.current);
            }

            const attemptScrollRestore = (attemptsLeft) => {
                if (attemptsLeft <= 0) {
                    setIsRestoringFromResubot(false);
                    return;
                }

                wrapperRef.current.scrollTop = editorScrollPosition;

                if (Math.abs(wrapperRef.current.scrollTop - editorScrollPosition) > 1) {
                    resubotRestorationTimeoutRef.current = setTimeout(() => {
                        attemptScrollRestore(attemptsLeft - 1);
                    }, 50);
                } else {
                    setIsRestoringFromResubot(false);
                }
            };

            resubotRestorationTimeoutRef.current = setTimeout(() => {
                attemptScrollRestore(5);
            }, 100);
        }

        return () => {
            if (resubotRestorationTimeoutRef.current) {
                clearTimeout(resubotRestorationTimeoutRef.current);
            }
        };
    }, [isRestoringFromResubot, editorScrollPosition, setIsRestoringFromResubot]);

    // Cleanup remains the same
    useEffect(() => {
        return () => {
            if (resubotRestorationTimeoutRef.current) {
                clearTimeout(resubotRestorationTimeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        setLocalSkills(original_data.skills);
    }, [original_data.skills]);

    useEffect(() => {
        if (categorizedSkills) {
            setLocalCategorizedSkills(categorizedSkills);
        }
    }, [categorizedSkills]);

    const moveKeyword = (dragIndex, hoverIndex) => {
        setLocalSkills(prev => {
            const newSkills = [...prev];
            const [draggedItem] = newSkills.splice(dragIndex, 1);
            newSkills.splice(hoverIndex, 0, draggedItem);
            return newSkills;
        });
    };

    const handleDragEnd = () => {
        setOriginalData(prevState => ({
            ...prevState,
            skills: localSkills
        }));
    };

    // Move function for categorized skills
    const moveCategoryKeyword = (category, dragIndex, hoverIndex) => {
        setLocalCategorizedSkills(prev => {
            const newSkills = { ...prev };
            const categorySkills = [...newSkills[category]];
            const [draggedItem] = categorySkills.splice(dragIndex, 1);
            categorySkills.splice(hoverIndex, 0, draggedItem);
            newSkills[category] = categorySkills;
            return newSkills;
        });
    };

    // Handle drag end for categorized skills
    const handleCategoryDragEnd = (category) => {
        setCategorizedSkills(prevState => ({
            ...prevState,
            [category]: localCategorizedSkills[category]
        }));
    };

    const textareaRefs = useRef({
        general: React.createRef()
    });
    //const store_data = BackendData.data.store_data;
    // Effect to set originalExpData on navigation
    useEffect(() => {
        if (resetExpCopy) {
            setOriginalExpData(JSON.parse(JSON.stringify(resetExpCopy)));
        }
    }, [resetExpCopy]);

    //info locally needed - original_data info section
    const [personalInfo, setPersonalInfo] = useState({
        'First Name': original_data.personal_info.first_name,
        'Last Name': original_data.personal_info.last_name,
        'Email Address': original_data.personal_info.email,
        'Phone Number': original_data.personal_info.phone_number,
        'Location': original_data.personal_info.location,
        'LinkedIn/Personal Website URL':original_data.personal_info.personal_website_url
    });
    
    const handleCompanyChange = (name) => {
        setCompanyName(name);
    };
    const handleDomainChange = (domain) => {
        setCompanyDomain(domain);
    };
    const handleJobPositionChange = (position) => {
        setJobPosition(position);
    };

    const handleQuestionChange = (question) => {
        setQuestion(question);
    };

    const handleLengthChange = (length) => {
        setLength(length);
    };

    const handleClickRegen = () => {
        setPageStatus('regenerate');
        setCustomRequirement(null);
        setRevisedFeedback(null);
    }

    const handleClickReturn = () => {
        setPageStatus('result');
    }
    
    const [categoryEditStates, setCategoryEditStates] = useState({});
    const [showSummary, setShowSummary] = useState(executive_summary !== null);
    const [showExperiences, setShowExperiences] = useState(false); // State to toggle showing experiences in sidebar
    const [showProjects, setShowProjects] = useState(false);

    useEffect(() => {
        setShowSummary(executive_summary !== null);
    }, [executive_summary]);
    
    useEffect(() => {
        setShowCoverLetter(coverletterApiResponse !== null);
    }, [coverletterApiResponse]);

    useEffect(() => {
        setShowFollowUpEmail(followUpEmail !== null);
    }, [followUpEmail]);

    useEffect(() => {
        setShowQuestionAnswer(questionAnswer !== null);
    }, [questionAnswer]);

    //rawInput is for the uncetagorized skills
    const [rawInput, setRawInput] = useState((original_data.skills || []).join(', '));
    const handleSkillsChange = (updatedSkills) => {
        setRawInput(updatedSkills);  // Store the raw input temporarily
    };

    useEffect(() => {
        if (apiResponse) {
            const summary = apiResponse.executive_summary;
            setExecutiveSummary(summary);
            setShowSummary(true);
        }
    }, [apiResponse]);

    // Function to check experience fields
    const checkExperienceFields = (experienceDetails) => {
        const requiredFields = ['Title', 'Company', 'Duration'];
        return requiredFields.every(field => experienceDetails[field]);
    };
    
    const handleExperienceChange = (current_experience, index, currentSection) => {
        setData(prevData => {
            // Create a copy of the rendering_data object to avoid direct mutation
            const newRenderingData = {
                ...prevData.rendering_data,
                [currentSection]: [...prevData.rendering_data[currentSection]]
            };
            // Update the Experience field at the specified index
            newRenderingData[currentSection][index].Experience = current_experience;
    
            // Return the updated state
            return {
                ...prevData,
                rendering_data: newRenderingData
            };
        });
    };

    const handleExperienceDelete = (index) => {
        const updatedExperience = data.rendering_data[currentSection].filter((_, i) => i !== index);

        // Adjust selectedExperience if necessary
        if (currentSection === 'work') {
            if (selectedExperience >= (updatedExperience.length)) {
                setSelectedExperience(Math.max(updatedExperience.length - 1, 0));
            }
            setData((prevState) => ({
                ...prevState,
                rendering_data: {
                    ...prevState.rendering_data,
                    work: updatedExperience // Replace `updatedWork` with the new value you want to set for `work`
                }
            }));
        } else {
            if (selectedProject >= (updatedExperience.length)) {
                setSelectedProject(Math.max(updatedExperience.length - 1, 0));
            }
            setData((prevState) => ({
                ...prevState,
                rendering_data: {
                    ...prevState.rendering_data,
                    project: updatedExperience // Replace `updatedWork` with the new value you want to set for `work`
                }
            }));
        }
    };

    const handleExperienceReset = () => {
        setSelectedExperience(0);
        // Update the resumeInfo and originalData states
        setData((prevState) => ({
            ...prevState,
            rendering_data: {
                ...prevState.rendering_data,
                work: JSON.parse(JSON.stringify(originalExpData.work))
            }
        }));
    };

    const handleProjectReset = () => {
        setSelectedProject(0);
        // Update the resumeInfo and originalData states
        setData((prevState) => ({
            ...prevState,
            rendering_data: {
                ...prevState.rendering_data,
                project: JSON.parse(JSON.stringify(originalExpData.project))
            }
        }));
    };

    const handlePersonalInfoChange = (key, value) => {
        setPersonalInfo((prevState) => ({
            ...prevState,
            [key]: value
        }));
        if (key === "First Name") {
            setOriginalData((prevState) => ({
                ...prevState,
                personal_info: {
                    ...prevState.personal_info,
                    first_name: value
                }
            }));
        } else if (key === "Last Name") {
            setOriginalData((prevState) => ({
                ...prevState,
                personal_info: {
                    ...prevState.personal_info,
                    last_name: value
                }
            }));
        } else if (key === "Email Address") {
            setOriginalData((prevState) => ({
                ...prevState,
                personal_info: {
                    ...prevState.personal_info,
                    email: value
                }
            }));
        } else if (key === "Phone Number") {
            setOriginalData((prevState) => ({
                ...prevState,
                personal_info: {
                    ...prevState.personal_info,
                    phone_number: value
                }
            }));
        } else if (key === "Location") {
            setOriginalData((prevState) => ({
                ...prevState,
                personal_info: {
                    ...prevState.personal_info,
                    location: value
                }
            }));
        } else if (key === "LinkedIn/Personal Website URL") {
            setOriginalData((prevState) => ({
                ...prevState,
                personal_info: {
                    ...prevState.personal_info,
                    personal_website_url: value
                }
            }));
        }
    };

    const handleSummaryChange = (summary) => {
        setExecutiveSummary(summary);
    };

    const handleSummaryDelete = () => {
        setShowSummary(false);
        setExecutiveSummary(null);
    };

    const handleEducationChange = (updatedEducation) => {
        setOriginalData((prevState) => ({
            ...prevState,
            education: updatedEducation,
        }));
    };

    const handleEducationDelete = (index) => {
        const updatedEducation = original_data.education.filter((_, i) => i !== index);

        // Update the resumeInfo and originalData states
        setOriginalData((prevState) => ({
            ...prevState,
            education: updatedEducation,
        }));
    };

    const handleAddEducation = () => {
        const neweducation = [...(original_data.education || []), { GPA: '', degrees: '', graduation_month: '', graduation_year: '', majors: '', university: '', university_start_month: '', university_start_year: '' }];

        // Update the resumeInfo and originalData states
        setOriginalData((prevState) => ({
            ...prevState,
            education: neweducation,
        }));
    };

    const processSkills = () => {
        const new_skills_list = rawInput
            .split(',')
            .map(skill => skill.trim())
            .filter(skill => skill.length > 0);
    
    
        setOriginalData((prevState) => ({
            ...prevState,
            skills: new_skills_list,
        }));

        setIsEditSkills(false);
    };

    const handleDeleteSkill = (index) => {
        const new_skills_list = original_data.skills.filter((_, i) => i !== index);
    
        setRawInput((new_skills_list || []).join(', '));
    
        setOriginalData((prevState) => ({
            ...prevState,
            skills: new_skills_list,
        }));
    };

    const handleAddCertificate = () => {
        const newCertificates = [...(original_data.certificate || []), { name: '', achieved_date: '' }];

        // Update the resumeInfo and originalData states
        setOriginalData((prevState) => ({
            ...prevState,
            certificate: newCertificates,
        }));
    };

    const handleCertificateChange = (index, key, value) => {
        const updatedCertificates = [...original_data.certificate];
        updatedCertificates[index][key] = value;
        setOriginalData((prevState) => ({
            ...prevState,
            certificate: updatedCertificates,
        }));
    };

    const handleDeleteCertificate = (index) => {
        const updatedCertificates = original_data.certificate.filter((_, i) => i !== index);

        // Update the resumeInfo and originalData states
        setOriginalData((prevState) => ({
            ...prevState,
            certificate: updatedCertificates,
        }));
    };
    const isFieldEmpty = (value) => value === '';
    // State to track if each project is filled
    const [areExperiencesFilled, setAreExperiencesFilled] = useState(() => {
        const workData = data?.rendering_data?.work;
        if (workData && Array.isArray(workData) && workData.length > 0) {
            return Array(workData.length).fill(false);
        }
        return [];
    });

    // Function to check if each project in projectData is filled
    const checkIfExperiencesFilled = () => {
        return data.rendering_data.work.map(exp => checkExperienceFields(exp.Experience));  // Returns an array of booleans
    };

    // State to track if each project is filled
    const [areProjectsFilled, setAreProjectsFilled] = useState(() => {
        const projectData = data?.rendering_data?.project;
        if (projectData && Array.isArray(projectData) && projectData.length > 0) {
            return Array(projectData.length).fill(false);
        }
        return [];
    });

    // Function to check if each project in projectData is filled
    const checkIfProjectsFilled = () => {
        return data.rendering_data.project.map(exp => checkExperienceFields(exp.Experience));  // Returns an array of booleans
    };

    // Function to check education fields
    const checkEducationFields = (education) => {
        if (!education || !Array.isArray(education)) {
            return true; // Return false if education is null, undefined, or not an array
        }
        return education.every(edu => edu.university && edu.degrees && edu.university_start_year && edu.graduation_year);
    };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [loadingCategory, setLoadingCategory] = useState(false); // state for loading
    const [apiResponseCategory, setApiResponseCategory] = useState(null);
    const [rawCategorizedInputs, setRawCategorizedInputs] = useState(null);
    const dropdownRef = useRef(null);
    
    const categories = ["Design", "Research", "Development", "Data Analysis", "Tech Skills", "Soft Skills", "Management", "Marketing", "Language"];
    
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setIsOpen(!isOpen);
    };
    
    const handleCategoryChange = (category) => {
        if (selectedCategories.includes(category)) {
            setSelectedCategories(selectedCategories.filter(item => item !== category));
        } else if (selectedCategories.length < 3) {
            setSelectedCategories([...selectedCategories, category]);
        }
    };

    const isSaveDisabled = selectedCategories.length < 2;

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
            setIsOpen(false);
        }
    };

    const send_to_server_keywords_category = async () => {
        //---------------------------------auhentication code----------------------------------------
        if (!isAuthenticated) {
            alert("Login required: Please login first");
            return;
        }
        //---------------------------------auhentication code----------------------------------------
        // create a FormData object
        const formData = new FormData();

        let combined_categorized_skills_send_server = [];
        if (showCategorySkills) {
            combined_categorized_skills_send_server = combine_category_skills();
        } else {
            combined_categorized_skills_send_server = original_data.skills;
        }

        formData.append('category_list', JSON.stringify(selectedCategories));
        formData.append('keywords', JSON.stringify(combined_categorized_skills_send_server));
        setIsDropdownOpen(false);
        setLoadingCategory(true);
        //---------------------------------auhentication code----------------------------------------
        //get user's specific token
        const token = await getAccessTokenSilently();
        //---------------------------------auhentication code----------------------------------------
        fetch('/resumasterapi/categorize_keywords', { //api endpoint
            method: 'POST',
            //---------------------------------auhentication code----------------------------------------
            headers: {
                Authorization: `Bearer ${token}`
            },
            //---------------------------------auhentication code----------------------------------------
            body: formData
        }).then(response => {
            if (response.status === 400) {
                throw new Error('Sorry, there is some issue in the format of uploading files. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!');  // This error will be caught in the .catch() block
            }
            if (response.status == 500) {
                throw new Error('Sorry, there is some issue in our server. Please try again later or use "Paste your resume text" function instead.\nIMPORTANT: To report the error, send your resume and job description to email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!')
            }
            //---------------------------------auhentication code----------------------------------------
            if (response.status == 401) {
                throw new Error('Sorry, you must login first to upload your resume')
            }
            //---------------------------------auhentication code----------------------------------------
            return response.json();
        }).then(data => {
            setApiResponseCategory(data);
            setLoadingCategory(false);
        }).catch(error => {
            // handle the error
            console.error(error);
            // If there's an error, ensure loading is turned off
            setLoadingCategory(false);
            alert(error.message);
        });
    };

    const handleCategorizedSkillsChange = (key, updatedSkills) => {
        setRawCategorizedInputs(prevState => ({
            ...prevState,
            [key]: updatedSkills,
        }));
    };

    const processCategorizedSkills = (key) => {
        const new_skills_list = rawCategorizedInputs[key]
            .split(',')
            .map(skill => skill.trim())
            .filter(skill => skill.length > 0);
    
        setCategorizedSkills(prevState => ({
            ...prevState,
            [key]: new_skills_list,
        }));
    };

    useEffect(() => {
        if (showCategorySkills && categorizedSkills !== null) {
            const combined_categorized_skills = combine_category_skills();
            setOriginalData((prevState) => ({
                ...prevState,
                skills: combined_categorized_skills,
            }));
        }
     }, [categorizedSkills, showCategorySkills]);

    const handleCategoryAddClick = (category) => {
        // Only need to update the edit state now
        setCategoryEditStates(prev => ({
            ...prev,
            [category]: true
        }));
    };

    const handleDeleteCategorySkill = (key, index) => {
        const new_skills_list = categorizedSkills[key].filter((_, i) => i !== index);
    
        setRawCategorizedInputs(prevState => ({
            ...prevState,
            [key]: (new_skills_list || []).join(', '),
        }));

        setCategorizedSkills(prevState => ({
            ...prevState,
            [key]: new_skills_list,
        }));
    };

    const resetCategory = () => {
        setCategorizedSkills(null);
        setApiResponse(null);
        setShowCategorySkills(false);
        setIsOpen(false);
    };

    const combine_category_skills = () => {
        const combined_categorized_skills = Array.from(
            new Set(
                Object.keys(categorizedSkills)
                    .flatMap(key => categorizedSkills[key]) // Flatten the lists into one array
            )
        );
        return combined_categorized_skills;
    }

    useEffect(() => {
        if (apiResponseCategory) {
            const categorized_skills = apiResponseCategory;
            setCategorizedSkills(categorized_skills);
            setShowCategorySkills(true);
            setApiResponseCategory(null);
        }
    }, [apiResponseCategory]); // This effect depends on `apiResponse`

    useEffect(() => {
        if (categorizedSkills) {
            setCategoryEditStates(
                Object.keys(categorizedSkills).reduce((acc, key) => ({
                    ...acc,
                    [key]: false
                }), {})
            );
        }
    }, [categorizedSkills]);

    // Update refs when categorizedSkills changes
    useEffect(() => {
        if (categorizedSkills) {
            textareaRefs.current = {
                // Add refs for categorized skills
                ...Object.keys(categorizedSkills).reduce((acc, key) => ({
                    ...acc,
                    [key]: React.createRef()
                }), {}),
                // Add ref for non-categorized skills
                'general': React.createRef()
            };
        }
    }, [categorizedSkills]);


    useEffect(() => {
        // Handle categorized skills
        Object.keys(categoryEditStates).forEach(category => {
            if (categoryEditStates[category] && textareaRefs.current[category]?.current) {
                const textarea = textareaRefs.current[category].current;
                textarea.focus();
                textarea.setSelectionRange(textarea.value.length, textarea.value.length);
            }
        });

        // Handle non-categorized skills
        if (isEditSkills && textareaRefs.current.general?.current) {
            const textarea = textareaRefs.current.general.current;
            textarea.focus();
            textarea.setSelectionRange(textarea.value.length, textarea.value.length);
        }
    }, [categoryEditStates, isEditSkills]);

    useEffect(() => {
        if (isDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpen]);

    useEffect(() => {
        if (original_data.skills) {
            setRawInput((original_data.skills || []).join(', '));
        }
    }, [original_data.skills]);

    useEffect(() => {
        if (showCategorySkills && categorizedSkills) {
            setRawCategorizedInputs(
                Object.keys(categorizedSkills).reduce((acc, key) => {
                    acc[key] = (categorizedSkills[key] || []).join(', ');
                    return acc;
                }, {})
            );
        } else {
            setRawCategorizedInputs(null);
        }
    }, [categorizedSkills, showCategorySkills]);

    useEffect(() => {
        if (selectedSection === 'experiences') {
          setShowExperiences(true);
        }
        if (selectedSection === 'projects') {
          setShowProjects(true);
        }
      }, [selectedSection]); // The effect will trigger whenever selectedSection changes

      useEffect(() => {
        const filledStatus = checkIfExperiencesFilled();
        setAreExperiencesFilled(filledStatus);
    }, [data.rendering_data]);

      useEffect(() => {
        const filledStatus = checkIfProjectsFilled();
        setAreProjectsFilled(filledStatus);
    }, [data.rendering_data]);

    useEffect(() => {
        // Check education and experience fields when data changes
        const educationFilled = checkEducationFields(original_data.education);
        const allexperienceFilled = data?.rendering_data?.work.every(exp => checkExperienceFields(exp.Experience));
        const allprojectsFilled = data?.rendering_data?.project.every(exp => checkExperienceFields(exp.Experience));

        setIsEducationFieldsFilled(educationFilled);
        setIsExperienceFieldsFilled(allexperienceFilled);
        setIsProjectFieldFilled(allprojectsFilled);
    }, [original_data.education, data, isExperienceFieldsFilled, isProjectFieldFilled]);

    useEffect(() => {
        const isPersonalInfoFilled = (original_data.personal_info.first_name && original_data.personal_info.last_name);
        setIsPersonalInfoFilled(isPersonalInfoFilled);
    },[original_data.personal_info.first_name, original_data.personal_info.last_name]);

    const cards = menuItems.map((title, index) => ({
        id: index + 1,
        title: title
    }));

    const checkExperienceForKeyword = useCallback((experience) => {
        if (!activeKeyword) return false;
    
        // 标准化处理函数
        const normalize = (text) => {
            if (!text) return '';
            return text.toLowerCase()
                      .replace(/[-](?=\w)/g, ' ')       // 将连字符替换为空格（只处理后面跟着字母的情况）
                      .replace(/\.(?=[A-Za-z])/g, ' ')  // 将点号替换为空格（只在后面跟着字母时）
                      .replace(/[,!?\.]$/g, '')         // 移除句尾的标点符号（包括句号）
                      .replace(/[,!?]/g, '')            // 移除其他标点符号
                      .trim();
        };
        
        // 准备关键词
        const normalizedKeyword = normalize(activeKeyword);
        
        const checkText = (text) => {
            const normalizedText = normalize(text);
            
            // 如果是多词组关键词

            if (normalizedKeyword.includes(' ')) {
                // Escape all regex special characters in the entire keyword first
                const escapedKeyword = normalizedKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                
                // Then split by spaces and join with the delimiter pattern
                const regexPattern = escapedKeyword
                    .split(' ')
                    .join('(?:[-. ]|(?= ))');
            
                const keywordRegex = new RegExp(`\\b${regexPattern}\\b`, 'i');
                return keywordRegex.test(normalizedText);
            }
            
            // 单个词的情况
            const textWords = normalizedText.split(/\s+/);
            return textWords.some(word => 
                normalize(word) === normalizedKeyword || 
                normalize(pluralize.singular(word)) === normalize(pluralize.singular(normalizedKeyword))
            );
        };
    
        return experience.Experience.Responsibilities.some(resp => checkText(resp));
    }, [activeKeyword]);

    const checkSkillsForKeyword = useCallback((skills) => {
        if (!activeKeyword) return false;
    
        // 更新标准化处理函数
        const normalize = (text) => {
            if (!text) return '';
            return text.toLowerCase()
                      .replace(/[-](?=\w)/g, ' ')       // 将连字符替换为空格（只处理后面跟着字母的情况）
                      .replace(/\.(?=[A-Za-z])/g, ' ')  // 将点号替换为空格（只在后面跟着字母时）
                      .replace(/[,!?\.]$/g, '')         // 移除句尾的标点符号（包括句号）
                      .replace(/[,!?]/g, '')            // 移除其他标点符号
                      .trim();
        };
        
        // 准备关键词
        const normalizedKeyword = normalize(activeKeyword);
        
        const checkText = (text) => {
            const normalizedText = normalize(text);
            
            // 如果是多词组关键词
            if (normalizedKeyword.includes(' ')) {
                // Escape all regex special characters in the entire keyword first
                const escapedKeyword = normalizedKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                
                // Then split by spaces and join with the delimiter pattern
                const regexPattern = escapedKeyword
                    .split(' ')
                    .join('(?:[-. ]|(?= ))');
            
                const keywordRegex = new RegExp(`\\b${regexPattern}\\b`, 'i');
                return keywordRegex.test(normalizedText);
            }
            
            // 单个词的情况
            const textWords = normalizedText.split(/\s+/);
            return textWords.some(word => 
                normalize(word) === normalizedKeyword || 
                normalize(pluralize.singular(word)) === normalize(pluralize.singular(normalizedKeyword))
            );
        };
    
        return skills.some(skill => checkText(skill));
    }, [activeKeyword]);


    const highlightText = (text) => {
        if (!activeKeyword || !text) return text;
    
        // 标准化处理函数
        const normalize = (text) => {
            if (!text) return '';
            return text.toLowerCase()
                      .replace(/[-](?=\w)/g, ' ')       // 将连字符替换为空格（只处理后面跟着字母的情况）
                      .replace(/\.(?=[A-Za-z])/g, ' ')  // 将点号替换为空格（只在后面跟着字母时）
                      .replace(/[,!?\.]$/g, '')         // 移除句尾的标点符号（包括句号）
                      .replace(/[,!?]/g, '')            // 移除其他标点符号
                      .trim();
        };
        
        // 准备关键词
        const normalizedKeyword = normalize(activeKeyword);
        
        const checkText = (text) => {
            const normalizedText = normalize(text);
            
            // 如果是多词组关键词
            if (normalizedKeyword.includes(' ')) {
                // Escape all regex special characters in the entire keyword first
                const escapedKeyword = normalizedKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                
                // Then split by spaces and join with the delimiter pattern
                const regexPattern = escapedKeyword
                    .split(' ')
                    .join('(?:[-. ]|(?= ))');
            
                const keywordRegex = new RegExp(`\\b${regexPattern}\\b`, 'i');
                return keywordRegex.test(normalizedText);
            }
            
            // 单个词的情况
            const textWords = normalizedText.split(/\s+/);
            return textWords.some(word => 
                normalize(word) === normalizedKeyword || 
                normalize(pluralize.singular(word)) === normalize(pluralize.singular(normalizedKeyword))
            );
        };
    
        // 开始处理高亮逻辑
        const parts = [];
        // 如果是多词组关键词
        if (normalizedKeyword.includes(' ')) {
            const escapedKeyword = normalizedKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const regexPattern = escapedKeyword
                .split(' ')
                .join('(?:[-. ]|(?= ))');
            
            const keywordRegex = new RegExp(`(\\b${regexPattern}\\b)`, 'gi');
            const splitParts = text.split(keywordRegex);
            
            return (
                <span>
                    {splitParts.map((part, i) => {
                        if (checkText(part)) {
                            return <span key={i} className="highlight-keyword">{part}</span>;
                        }
                        return part;
                    })}
                </span>
            );
        } else {
            // 单个词的情况
            // const words = text.split(/(\s+)/); // 保留分隔符
            const words = text.split(/(\s+)|([,!?])/);
            return (
                <span>
                    {words.filter(Boolean).map((word, i) => {
                        // 检查单词或其单数形式是否匹配
                        if (
                            normalize(word) === normalizedKeyword || 
                            normalize(pluralize.singular(word)) === normalize(pluralize.singular(normalizedKeyword))
                        ) {
                            return <span key={i} className="highlight-keyword">{word}</span>;
                        }
                        return word;
                    })}
                </span>
            );
        }
    };


    const checkMatch = (text, keyword) => {
        if (!keyword) return false;
    
        // 标准化处理函数
        const normalize = (text) => {
            if (!text) return '';
            return text.toLowerCase()
                      .replace(/[-](?=\w)/g, ' ')       // 将连字符替换为空格（只处理后面跟着字母的情况）
                      .replace(/\.(?=[A-Za-z])/g, ' ')  // 将点号替换为空格（只在后面跟着字母时）
                      .replace(/[,!?\.]$/g, '')         // 移除句尾的标点符号（包括句号）
                      .replace(/[,!?]/g, '')            // 移除其他标点符号
                      .trim();
        };
        
        // 准备关键词
        const normalizedKeyword = normalize(keyword);
        
        const checkText = (text) => {
            const normalizedText = normalize(text);
            
            // 如果是多词组关键词
            if (normalizedKeyword.includes(' ')) {
                // Escape all regex special characters in the entire keyword first
                const escapedKeyword = normalizedKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                
                // Then split by spaces and join with the delimiter pattern
                const regexPattern = escapedKeyword
                    .split(' ')
                    .join('(?:[-. ]|(?= ))');
            
                const keywordRegex = new RegExp(`\\b${regexPattern}\\b`, 'i');
                return keywordRegex.test(normalizedText);
            }
            
            // 单个词的情况
            const textWords = normalizedText.split(/\s+/);
            return textWords.some(word => 
                normalize(word) === normalizedKeyword || 
                normalize(pluralize.singular(word)) === normalize(pluralize.singular(normalizedKeyword))
            );
        };
    
        return checkText(text);
    };

    return (
        <div 
            className="navi-detail-outer" 
            ref={containerRef}
            style={{ height: '100vh', overflowY: 'auto' }}
        >
            <div className="navi-detail-content-wrapper" ref={wrapperRef}>
            {navibarMode === 'editor' && <div>
                {cards.map(card => {
                    const card_name = card.title.toLowerCase().replaceAll(' ', '_').replaceAll('-', '_');
                    const card_src = `/img/navi_detail_${card_name}.png`;
                    cardRefs.current[card.title] = cardRefs.current[card.title] || createRef();

                    const hasKeywordMatch = (card.title === "Experience" || card.title === "Project" || card.title === "Skills") && 
                        ((card.title === "Experience" && 
                            data?.rendering_data?.work?.some(workItem => 
                                checkExperienceForKeyword(workItem)
                            )) ||
                        (card.title === "Project" && 
                            data?.rendering_data?.project?.some(projectItem => 
                                checkExperienceForKeyword(projectItem)
                            )) ||
                        (card.title === "Skills" && 
                            checkSkillsForKeyword(original_data.skills)
                        ));

                    return (
                        <div 
                            key={card.title}  // 改用 title 作为 key
                            ref={cardRefs.current[card.title]}
                            className={`navi-detail-card ${openCard === card.title ? 'navi-detail-expanded' : ''}`}  // 改用 title 比较
                        >
                            {/* 将 onClick 移到 header 上 */}
                            <div className="navi-detail-card-header" onClick={() => handleCardClick(card)}>
                                <div className="navi-detail-card-title">
                                    <img src={card_src} alt="editor icon" width="16" height="16" style={{ marginRight: '8px' }} />
                                    <span>{card.title}</span>
                                    {hasKeywordMatch && openCard !== card.title && (
                                        <span className="keyword-match-badge">
                                            Matching keyword
                                        </span>
                                    )}
                                    {!isPersonalInfoFilled && openCard !== card.title && card.title === 'Personal Details' && (
                                        <span className="miss-info-badge">
                                            Missing data
                                        </span>
                                    )}
                                    {!isEducationFieldsFilled && openCard !== card.title && card.title === 'Education' && (
                                        <span className="miss-info-badge">
                                            Missing data
                                        </span>
                                    )}
                                    {!isExperienceFieldsFilled && openCard !== card.title && card.title === 'Experience' && (
                                        <span className="miss-info-badge">
                                            Missing data
                                        </span>
                                    )}
                                    {!isProjectFieldFilled && openCard !== card.title && card.title === 'Project' && (
                                        <span className="miss-info-badge">
                                            Missing data
                                        </span>
                                    )}
                                </div>

                                {openCard === card.title ? 
                                    <img src="/img/navi_detail_up.png" alt="editor icon" width="16" height="16" style={{ marginRight: '8px' }} /> : 
                                    <img src="/img/navi_detail_down.png" alt="editor icon" width="16" height="16" style={{ marginRight: '8px' }} />}
                            </div>
                            {openCard === card.title && (
                                <div className="navi-detail-card-content" onClick={(e) => e.stopPropagation()}>
                                    {card.title ==="Personal Details" && 
                                        <div className="personal-info-content">
                                            {personalInfo && Object.entries(personalInfo).reduce((acc, [key, value], index) => {
                                                // Group every two items together
                                                if (index % 2 === 0) acc.push([]);
                                                acc[acc.length - 1].push([key, value]);
                                                return acc;
                                            }, []).map((pair, rowIndex) => (
                                                <div key={rowIndex} className="personal-row">
                                                    {pair.map(([key, value]) => (
                                                        <div key={key} className="personal-item">
                                                            <label className="form-label">
                                                                {key}
                                                                {(key === "First Name" || key === "Last Name") && <span className="starColor">*</span>}
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-input"
                                                                value={value || ''}
                                                                onChange={(e) => handlePersonalInfoChange(key, e.target.value)}
                                                            />
                                                            {((key === "First Name" || key === "Last Name") && !value) && (
                                                                <div className="hint-text">Hint: this content is required.</div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {card.title === "Education" &&
                                        <Education 
                                        education={original_data.education}
                                        onEducationChange={handleEducationChange} 
                                        handleEducationDelete={handleEducationDelete}
                                        handleAddEducation={handleAddEducation}
                                        isFieldEmpty={isFieldEmpty} 
                                        checkEducationFields={checkEducationFields}
                                        setIsEducationFieldsFilled={setIsEducationFieldsFilled}
                                        />
                                    }
                                    {card.title === "Experience" && (
                                        data?.rendering_data?.work?.length > 0 ? (
                                            data.rendering_data.work.map((workItem, index) => (
                                                <DetailInfo 
                                                    key={index} 
                                                    expIndex={index} 
                                                    data={workItem} 
                                                    handleExperienceChange={handleExperienceChange} 
                                                    activeKeyword={activeKeyword}
                                                    containsKeyword={checkExperienceForKeyword(workItem)} 
                                                    setSelectedExperience={setSelectedExperience} 
                                                    setIsResubotOpen={setIsResubotOpen}
                                                    handleExperienceDelete={handleExperienceDelete} 
                                                    isFieldEmpty={isFieldEmpty} 
                                                    isMissingInfo={!areExperiencesFilled[index]}
                                                />
                                            ))
                                        ) : (
                                            <div>No Experiences Available</div>
                                        )
                                    )}
                                    {card.title === "Project" && (
                                        data?.rendering_data?.project?.length > 0 ? (
                                            data.rendering_data.project.map((projectItem, index) => (
                                                <DetailInfo 
                                                    key={index} 
                                                    expIndex={index} 
                                                    data={projectItem} 
                                                    handleExperienceChange={handleExperienceChange} 
                                                    activeKeyword={activeKeyword}
                                                    containsKeyword={checkExperienceForKeyword(projectItem)} 
                                                    setSelectedExperience={setSelectedExperience} 
                                                    setIsResubotOpen={setIsResubotOpen}
                                                    handleExperienceDelete={handleExperienceDelete} 
                                                    isFieldEmpty={isFieldEmpty} 
                                                    isMissingInfo={!areProjectsFilled[index]}
                                                />
                                            ))
                                        ) : (
                                            <div>No Projects Available</div>
                                        )
                                    )}
                                    {card.title === 'Skills' &&
                                        <div className="skills-section-container">
                                            <div className="skills-category-container" ref={dropdownRef}>
                                                <div className="skills-category-button-container">
                                                    <div className={`skills-category-button ${isOpen ? 'active' : ''}`} onClick={toggleDropdown}>
                                                        <img src="/img/category-sort.png" alt="icon" className="sort-icon" />
                                                        Group Skills By Category
                                                    </div>
                                                    {showCategorySkills && (
                                                        <div className="reset-category-button" onClick={resetCategory}>
                                                            Reset Category
                                                        </div>
                                                    )}
                                                </div>
                                                    {isDropdownOpen && (
                                                        <div className="dropdown-list">
                                                            <div className="dropdown-list-header">Categories</div>
                                                            <div className="dropdown-list-guide">
                                                                You can assign up to 3 categories to your skills. Once selected, our system will automatically sort your skills into the chosen categories.
                                                            </div>
                                                            {categories.map((category) => (
                                                                <label key={category} className="category-dropdown-item">
                                                                    <input
                                                                        type="checkbox"
                                                                        value={category}
                                                                        checked={selectedCategories.includes(category)}
                                                                        onChange={() => handleCategoryChange(category)}
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                    {category}
                                                                </label>
                                                            ))}
                                                            <button
                                                                className="save-category-button"
                                                                onClick={send_to_server_keywords_category}
                                                                disabled={isSaveDisabled}
                                                            >
                                                                Save Category
                                                            </button>
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="detail-item-skills">
                                                {loadingCategory ? (
                                                    <div style={{ textAlign: 'center' }}>
                                                        <Loading_partial />
                                                        <p style={{ marginTop: '10px', fontSize: '16px', fontWeight: 600 }}>Loading...</p>
                                                    </div>
                                                    ) : showCategorySkills && rawCategorizedInputs ? (
                                                        <div>
                                                            {categorizedSkills && Object.keys(categorizedSkills).map(key => (
                                                                <div className="selected-category-skill" key={key}>
                                                                    <label className="form-label">{key}</label>
                                                                    {categoryEditStates[key] ? (
                                                                        <textarea
                                                                            ref={textareaRefs.current[key]}
                                                                            className="form-input"
                                                                            value={rawCategorizedInputs ? (rawCategorizedInputs[key] || '') : ''}
                                                                            onChange={(e) => handleCategorizedSkillsChange(key, e.target.value)}
                                                                            onBlur={() => processCategorizedSkills(key)}
                                                                            rows={3}
                                                                        />
                                                                    ) : (
                                                                        <div className="jd-keywords-frame" style={{border:'1px solid #E5E5EA', padding:'10px', borderRadius: '8px', marginBottom: '10px'}}>
                                                                            {localCategorizedSkills[key]?.map((skill, index) => (
                                                                                <DraggableCategoryKeyword
                                                                                    key={index}
                                                                                    keyword={skill}
                                                                                    index={index}
                                                                                    category={key}
                                                                                    moveKeyword={(dragIndex, hoverIndex) => moveCategoryKeyword(key, dragIndex, hoverIndex)}
                                                                                    handleDragEnd={() => handleCategoryDragEnd(key)}
                                                                                    onDelete={() => handleDeleteCategorySkill(key, index)}
                                                                                    checkMatch={checkMatch}
                                                                                    highlightText={highlightText}
                                                                                    activeKeyword={activeKeyword}
                                                                                />
                                                                            ))}
                                                                            {/* Add button remains the same */}
                                                                            <div 
                                                                                className="navibar-keyword-status"
                                                                                onClick={() => handleCategoryAddClick(key)}
                                                                                onMouseEnter={(e) => {
                                                                                    e.currentTarget.querySelector('.normal-img').style.opacity = '0';
                                                                                    e.currentTarget.querySelector('.hover-img').style.opacity = '1';
                                                                                    e.currentTarget.querySelector('.navibar-keyword-add-text').style.color = '#6750a4';
                                                                                }}
                                                                                onMouseLeave={(e) => {
                                                                                    e.currentTarget.querySelector('.normal-img').style.opacity = '1';
                                                                                    e.currentTarget.querySelector('.hover-img').style.opacity = '0';
                                                                                    e.currentTarget.querySelector('.navibar-keyword-add-text').style.color = '#333';
                                                                                }}
                                                                            >
                                                                                <div style={{ position: 'relative', width: '16px', height: '16px' }}>
                                                                                    <img 
                                                                                        src="/img/navibar-add-gray.png" 
                                                                                        alt="normal" 
                                                                                        className="normal-img" 
                                                                                        width="16" 
                                                                                        height="16" 
                                                                                        style={{ position: 'absolute', opacity: 1, transition: 'opacity 0.2s'}} 
                                                                                    />
                                                                                    <img 
                                                                                        src="/img/navibar-add-purple.png" 
                                                                                        alt="hover" 
                                                                                        className="hover-img" 
                                                                                        width="16" 
                                                                                        height="16" 
                                                                                        style={{ position: 'absolute', opacity: 0, transition: 'opacity 0.2s'}} 
                                                                                    />
                                                                                </div>
                                                                                <span className="navibar-keyword-add-text" style={{ fontWeight: '600'}}>Add</span>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : isEditSkills ? (
                                                        <>
                                                            <label className="form-label">Skills (edit in a common-separated format)</label>
                                                            <textarea
                                                                ref={textareaRefs.current.general}
                                                                className="form-input"
                                                                value={rawInput}
                                                                onChange={(e) => handleSkillsChange(e.target.value)}
                                                                onBlur={processSkills}  // Process skills when the textarea loses focus
                                                                rows={5}
                                                            />
                                                        </>
                                                    ) : (
                                                        <div className="jd-keywords-frame">
                                                            {localSkills.map((keyword, index) => (
                                                                <DraggableKeyword
                                                                    key={index}
                                                                    keyword={keyword}
                                                                    index={index}
                                                                    moveKeyword={moveKeyword}
                                                                    handleDragEnd={handleDragEnd}
                                                                    onDelete={handleDeleteSkill}
                                                                    checkMatch={checkMatch}
                                                                    highlightText={highlightText}
                                                                    activeKeyword={activeKeyword}
                                                                />
                                                            ))}
                                                            <div 
                                                                className="navibar-keyword-status"
                                                                style={{padding: '2px 10px', height: 'fit-content', verticalAlign: 'middle'}}
                                                                onClick={() => setIsEditSkills(true)}
                                                                onMouseEnter={(e) => {
                                                                    e.currentTarget.querySelector('.normal-img').style.opacity = '0';
                                                                    e.currentTarget.querySelector('.hover-img').style.opacity = '1';
                                                                    e.currentTarget.querySelector('.navibar-keyword-add-text').style.color = '#6750a4';
                                                                }}
                                                                onMouseLeave={(e) => {
                                                                    e.currentTarget.querySelector('.normal-img').style.opacity = '1';
                                                                    e.currentTarget.querySelector('.hover-img').style.opacity = '0';
                                                                    e.currentTarget.querySelector('.navibar-keyword-add-text').style.color = '#333';
                                                                }}
                                                            >
                                                                <div style={{ position: 'relative', width: '16px', height: '16px' }}>
                                                                    <img 
                                                                        src="/img/navibar-add-gray.png" 
                                                                        alt="normal" 
                                                                        className="normal-img" 
                                                                        width="16" 
                                                                        height="16" 
                                                                        style={{ position: 'absolute', opacity: 1, transition: 'opacity 0.2s'}} 
                                                                    />
                                                                    <img 
                                                                        src="/img/navibar-add-purple.png" 
                                                                        alt="hover" 
                                                                        className="hover-img" 
                                                                        width="16" 
                                                                        height="16" 
                                                                        style={{ position: 'absolute', opacity: 0, transition: 'opacity 0.2s'}} 
                                                                    />
                                                                </div>
                                                                <span className="navibar-keyword-add-text" style={{ fontWeight: '600'}}>Add</span>
                                                            </div>
                                                        </div>
                                                )}
                                            </div>
                                        </div>
                                    }
                                    {card.title === "Certificates" && 
                                        <>
                                            {original_data.certificate && original_data.certificate.length > 0 && original_data.certificate.map((certificate_element, index) => (
                                                <>
                                                    <div className="certificate-section-container" key={index}>
                                                        <div className="certificate-section-header">
                                                            <div className="certificate-title">
                                                                Certificate {index + 1}
                                                            </div>
                                                            <button onClick={() => handleDeleteCertificate(index)} className="delete-button">
                                                                Delete
                                                            </button>
                                                        </div>
                                                        <label className="form-label">Name</label>
                                                        <input
                                                            type="text"
                                                            className="experience-form-input"
                                                            value={certificate_element.name || ''}
                                                            onChange={(e) => handleCertificateChange(index, 'name', e.target.value)}
                                                            maxLength={80}
                                                        />
                                                        <label className="form-label">Date</label>
                                                        <input
                                                            type="text"
                                                            className="experience-form-input"
                                                            value={certificate_element.achieved_date || ''}
                                                            onChange={(e) => handleCertificateChange(index, 'achieved_date', e.target.value)}
                                                            maxLength={20}
                                                        />
                                                    </div>
                                                </>
                                            ))}
                
                                            <div className="button-container">
                                                <button className="add-new-button" onClick={handleAddCertificate}>
                                                    Add Certificate
                                                </button>
                                            </div>
                                        </>
                                    }
                                    {card.title === "Resume Summary" && 
                                        <div className="summary-section-container">
                                            <div className="inner-container">
                                                {loading ? (
                                                    <div style={{ textAlign: 'center' }}>
                                                        <Loading_partial />
                                                        <p style={{ marginTop: '10px', fontSize: '16px', fontWeight: 600 }}>Loading...</p>
                                                    </div>
                                                ) : showSummary ? (
                                                    <div className="detail-info-description-text-block-container">
                                                        <div className="img-box">
                                                            {/* <img    className="popup-export-button"
                                                                    onClick={() => handleDeleteItem('Summary')}
                                                                    style={{width: '24px', height: '24px', cursor: 'pointer'}}
                                                                    src='/img/detail_info_description_delete_purple.png'
                                                            /> */}
                                                            <button onClick={() => handleDeleteItem('Resume Summary')} className="delete-button">
                                                                Delete
                                                            </button>
                                                        </div>
                                                        <div className="summary-content ">
                                                            <textarea
                                                                className="summary-textarea"
                                                                value={executive_summary}
                                                                onChange={(e) => handleSummaryChange(e.target.value)}
                                                                rows={6}
                                                                autoFocus
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>Here is an error when generating summary, please try again</div>
                                                )}
                                            </div>
                                        </div>
                                    }
                                    {card.title === "Cover Letter" &&
                                        <CoverLetter
                                        data={data}
                                        job_description = {job_description}
                                        company_analysis = {company_analysis}
                                        original_data = {original_data}
                                        isFieldEmpty={isFieldEmpty}
                                        jobPosition = {jobPosition}
                                        companyName = {companyName}
                                        companyDomain = {companyDomain}
                                        handleCompanyChange = {handleCompanyChange}
                                        handleDomainChange={handleDomainChange}
                                        handleJobPositionChange={handleJobPositionChange}
                                        coverletterApiResponse = {coverletterApiResponse}
                                        setCoverletterApiResponse = {setCoverletterApiResponse}
                                        application_id = {application_id}
                                        coverletterLoading={coverletterLoading}
                                        setCoverletterLoading={setCoverletterLoading}
                                        showCoverLetter={showCoverLetter}
                                        setShowCoverLetter={setShowCoverLetter}
                                        isEditCoverLetter={isEditCoverLetter}
                                        setIsEditCoverLetter={setIsEditCoverLetter}
                                        isHoverCoverLetter={isHoverCoverLetter}
                                        setIsHoverCoverLetter={setIsHoverCoverLetter}
                                        handleDeleteItem={handleDeleteItem}
                                        />
                                    }
                                    {card.title === "Follow-Up Builder" &&
                                        <FollowUpGenerator
                                        data={data}
                                        job_description = {job_description}
                                        company_analysis = {company_analysis}
                                        original_data = {original_data}
                                        isFieldEmpty={isFieldEmpty}
                                        jobPosition = {jobPosition}
                                        companyName = {companyName}
                                        companyDomain = {companyDomain}
                                        handleCompanyChange = {handleCompanyChange}
                                        handleDomainChange={handleDomainChange}
                                        handleJobPositionChange={handleJobPositionChange}
                                        followUpEmail = {followUpEmail}
                                        setFollowUpEmail = {setFollowUpEmail}
                                        emailSubject = {emailSubject}
                                        setEmailSubject = {setEmailSubject}
                                        application_id = {application_id}
                                        followUpEmailLoading={followUpEmailLoading}
                                        setFollowUpEmailLoading={setFollowUpEmailLoading}
                                        showFollowUpEmail={showFollowUpEmail}
                                        setShowFollowUpEmail={setShowFollowUpEmail}
                                        handleDeleteItem={handleDeleteItem}
                                        />
                                    }
                                    {card.title === "Answer Application Questions" &&
                                        <ApplicationSpecificQuestion
                                        data={data}
                                        original_data = {original_data}
                                        isFieldEmpty={isFieldEmpty}
                                        jobPosition = {jobPosition}
                                        companyName = {companyName}
                                        job_description = {job_description}
                                        application_id = {application_id}
                                        handleDeleteItem={handleDeleteItem}
                                        handleQuestionChange={handleQuestionChange}
                                        handleLengthChange={handleLengthChange}
                                        question={question}
                                        length={length}
                                        questionAnswer={questionAnswer}
                                        setQuestionAnswer={setQuestionAnswer}
                                        customRequirement={customRequirement}
                                        setCustomRequirement={setCustomRequirement}
                                        revisedFeedback={revisedFeedback}
                                        setRevisedFeedback={setRevisedFeedback}
                                        pageStatus={pageStatus}
                                        setPageStatus={setPageStatus}
                                        handleClickRegen={handleClickRegen}
                                        handleClickStartNew={handleClickStartNew}
                                        handleClickReturn={handleClickReturn}
                                        questionAnswerLoading={questionAnswerLoading}
                                        setQuestionAnswerLoading={setQuestionAnswerLoading}
                                        showQuestionAnswer={showQuestionAnswer}
                                        setShowQuestionAnswer={setShowQuestionAnswer}
                                        />
                                    }
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>}

            {navibarMode === 'preview' && <div>
                {/* put things here */}
                <PdfViewer
                exp_data={data.rendering_data}
                original_data={original_data}
                jd_keywords={jd_keywords}
                executive_summary={executive_summary}
                isSkillsCategorized={showCategorySkills}
                categorizedSkills={categorizedSkills}
                jobPosition={jobPosition}
                companyName={companyName}
                selectedTemplate={selectedTemplate}
                showBoldKeywords={showBoldKeywords}
                setShowBoldKeywords={setShowBoldKeywords}
                fontsize={fontsize}
                setFontSize={setFontSize}
                name_fontsize={name_fontsize}
                setNameFontSize={setNameFontSize}
                sectionSpacing={sectionSpacing}
                setSectionSpacing={setSectionSpacing}
                sectionBetweenLine={sectionBetweenLine}
                setSectionBetweenLine={setSectionBetweenLine}
                lineHeightSpacing={lineHeightSpacing}
                setLineHeightSpacing={setLineHeightSpacing}
                template_1_section_header={template_1_section_header}
                setTemplate1SectionHeader={setTemplate1SectionHeader}
                name_fontsize_template_3={name_fontsize_template_3}
                setNameFontsizeTemplate3={setNameFontsizeTemplate3}
                sectionOrderTemplate1={sectionOrderTemplate1}
                setSectionOrderTemplate1={setSectionOrderTemplate1}
                sectionOrderTemplate2={sectionOrderTemplate2}
                setSectionOrderTemplate2={setSectionOrderTemplate2}
                />
            </div>}
            </div>
        </div>
    );
};

export default NaviDetail;