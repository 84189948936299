import React, { useState, useEffect } from "react";
import { Navigation } from "../../components/Navigation";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------
import "./style.css";

export const Loading_CL_revise = () => {
    //---------------------------------auhentication code----------------------------------------
    const { isAuthenticated } = useAuth0();
    //---------------------------------auhentication code----------------------------------------
    const messages = [
        'Identifying key experiences to feature...',
        'Optimizing keywords...'
      ];

      const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [displayText, setDisplayText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [typingSpeed, setTypingSpeed] = useState(50);

  useEffect(() => {
    const handleTyping = () => {
      const currentMessage = messages[currentMessageIndex];
      if (isDeleting) {
        setDisplayText(currentMessage.substring(0, displayText.length - 1));
        if (displayText.length === 0) {
          setIsDeleting(false);
          setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }
      } else {
        setDisplayText(currentMessage.substring(0, displayText.length + 1));
        if (displayText.length === currentMessage.length) {
          if (currentMessageIndex === messages.length - 1) {
            return; // Stop here on the fifth message
          }
          setTimeout(() => setIsDeleting(true), 1000); // Pause at the end of the message
        }
      }
    };

    const typingTimeout = setTimeout(handleTyping, typingSpeed);

    return () => clearTimeout(typingTimeout);
  }, [displayText, isDeleting, messages, typingSpeed, currentMessageIndex]);

  useEffect(() => {
    if (isDeleting) {
      setTypingSpeed(50);
    } else {
      setTypingSpeed(50);
    }
  }, [isDeleting]);


  return (
    <div className="loading">
      <Helmet>
        <title>Loading - ResuMaster</title>
        <meta property="og:title" content="Loading - ResuMaster" />
      </Helmet>
      {
        isAuthenticated ? (
            <Navigation_loggedin 
            isCollapsedMenu={false}
            className="navigation-2202" />
          ) : (
            <Navigation className="navigation-2202" />
          )
      }
      <div className="frame-10">
        <div className="spinner-container">
          <div className="loading-spinner">
          </div>
        </div>
        <div className="frame-11">
          <p className="title-5">Please do not navigate away. This process will take about 30 seconds while we generate your cover letter...</p>
          <div className="loading-message-dynamic">{displayText}</div>
        </div>
      </div>
    </div>
  );
};
