/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Navigation_loggedin } from "../../components/Navigation_loggedin";
import { Loading_mainpage } from "../../screens/Loading";
import "./style.css";
//---------------------------------auhentication code----------------------------------------
import { useAuth0 } from "@auth0/auth0-react";
//---------------------------------auhentication code----------------------------------------

export const Navigation_loggedin = ({
  className,
  handleSliderVisible,
  isCollapsedMenu,
  isShowDiscord,
}) => {
  //---------------------------------auhentication code----------------------------------------
  /*============================== temporary comment out for deployment 06/09===================================*/
  useEffect(() => {
    sendToServer2();
  }, []);
  /*============================== temporary comment out for deployment 06/09===================================*/
  //auth0 component
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  //---------------------------------auhentication code----------------------------------------
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // state for loading
  const [credits, setCredits] = useState(""); // Initialize credits state
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1024);
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const return_to_billing = async () => {
    navigate('/Dashboard', {
      replace: true,
      state: {
        current_selection: 'Billing History'
      }
    });
  };

  const return_to_applications = async () => {
    navigate('/Dashboard', {
      replace: true,
      state: {
        current_selection: 'Revision History'
      }
    });
  };

  if (loading) {
    return <Loading_mainpage />;
  }

  const sendToServer2 = async () => {
    //---------------------------------auhentication code----------------------------------------
    if (!isAuthenticated) {
      alert("Login required: Please login first");
      return;
    }
    //---------------------------------auhentication code----------------------------------------
    //start loading
    //setLoading(true);
    // send a POST request
    console.log("Send POST request to API");
    //---------------------------------auhentication code----------------------------------------
    //get user's specific token
    const token = await getAccessTokenSilently();
    //---------------------------------auhentication code----------------------------------------
    fetch("/resumasterapi/check_user_status", {
      //api endpoint
      method: "POST",
      //---------------------------------auhentication code----------------------------------------
      headers: {
        Authorization: `Bearer ${token}`,
      },
      //---------------------------------auhentication code----------------------------------------
    })
      .then((response) => {
        // Check for the 400 status code
        // console.log('Server Response:', response);
        if (response.status === 400) {
          throw new Error(
            "Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!"
          ); // This error will be caught in the .catch() block
        }
        if (response.status == 500) {
          throw new Error(
            "Sorry, there is some issue in our server. Please try again later. \nIMPORTANT: To report the error, email: admin@resumaster.ai, and we will solve the issue and provide you the feedback as soon as possible!"
          );
        }
        //---------------------------------auhentication code----------------------------------------
        if (response.status == 401) {
          throw new Error("Sorry, you must login first");
        }
        //---------------------------------auhentication code----------------------------------------
        return response.json();
      })
      .then((data) => {
        if (data.is_exist == true) {
          if (data.in_subscription === true) {
            setCredits("Unlimited");
            // console.log(data.in_subscription);
            //console.log(credits);
          } else {
            setCredits(data.current_credits);
            //console.log(data.current_credits);
          }
        } else {
          setCredits("Unavailable");
        }
        setLoading(false);
        //history.push("/result");
      })
      .catch((error) => {
        // handle the error
        console.error(error);
        // If there's an error, ensure loading is turned off
        setLoading(false);
        alert(error.message); // Displaying the error message to the user
      });
  };

  return (
    <div className={`navigation ${className}`}>
      {isMobileView ? (
        !isCollapsedMenu ? (
          <Link to="/">
            <img
              className="logo-nmv"
              alt="logo navigation mobile view"
              src="/img/logo-purple.png"
            />
          </Link>
        ) : (
          <img
            className="logo-nmv"
            alt="Futuristic modern"
            src="/img/Vector.png"
            onClick={handleSliderVisible}
          />
        )
      ) : (
        <Link to="/">
          <img
            className="futuristic-modern"
            alt="Futuristic modern"
            src="/img/nav-logo-new.png"
          />
        </Link>
      )}

      <div className="navigation-buttons">
        {isShowDiscord && (
          <a className="button-discord" href="https://discord.gg/YURMHY9JNt">
              <img
                className="profile-icon"
                src="/img/ic_baseline-discord.png"
                alt="Discord Community"
              />
          </a>
        )}

        <Link className="pricing-tab" to="/plan">
          <div className="button-secondary-text">Pricing</div>
        </Link>

        <a className="contact-us" href="/contact">
          <div className="button-secondary-text">Contact Us</div>
        </a>

        <a className="about" href="/aboutus">
          <div className="button-secondary-text">About Us</div>
        </a>

        <a className="credit-info" onClick={return_to_billing}>
          <img className="wallet-icon" src="/img/material-symbols_wallet.png" />
          <span className="button-secondary-text">ResuCredits: {credits}</span>
        </a>

        {!isCollapsedMenu && (
          <a className="button-discord" onClick={() => return_to_applications()}>
            <img
              className="profile-icon"
              src="/img/healthicons-ui-user-profile.png"
              alt="Profile"
            />
          </a>
        )}
      </div>
    </div>
  );
};
